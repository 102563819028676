import BaseTrackEvent from "@hornet-web-react/core/models/track-event"
import { HornetProfileId, PremiumPaywall } from "@hornet-web-react/core/types"

class HornetAnalyticsEvent extends BaseTrackEvent {
  static premiumOpened(paywall: PremiumPaywall) {
    return new HornetAnalyticsEvent("Premium_opened", [
      { key: "paywall", string_value: paywall },
    ])
  }

  static premiumTapOnCancel() {
    return new HornetAnalyticsEvent("Premium_tapOnCancel")
  }

  static navigationTapOnChats() {
    return new HornetAnalyticsEvent("Navigation_tapOnChats")
  }

  static guyTapOnShare() {
    return new HornetAnalyticsEvent("Guy_tapOnShare")
  }

  static guyTapOnReport() {
    return new HornetAnalyticsEvent("Guy_tapOnReport")
  }

  static awardListTapOnLike() {
    return new HornetAnalyticsEvent("AwardList_tapOnLike", [
      { key: "previous_screen", string_value: "profile" },
    ])
  }

  static awardListTapOnGuy() {
    return new HornetAnalyticsEvent("AwardList_tapOnGuy", [
      { key: "previous_screen", string_value: "profile" },
    ])
  }

  static awardListTapOnChat() {
    return new HornetAnalyticsEvent("AwardList_tapOnChat", [
      { key: "previous_screen", string_value: "profile" },
    ])
  }

  static navigationTapOnGuys() {
    return new HornetAnalyticsEvent("Navigation_tapOnGuys")
  }

  static navigationTapOnShop() {
    return new HornetAnalyticsEvent("Navigation_tapOnShop")
  }

  static navigationTapOnFeed() {
    return new HornetAnalyticsEvent("Navigation_tapOnFeed")
  }

  static webTapOnLeaveBeta() {
    return new HornetAnalyticsEvent("Web_tapOnLeaveBeta")
  }

  static webAccountCancelled(reasonId: string) {
    return new HornetAnalyticsEvent("Web_accountCancelled", [
      { key: "reason", string_value: reasonId || "no_reason" },
    ])
  }

  static navigationTapOnMyProfile() {
    return new HornetAnalyticsEvent("Navigation_tapOnMyProfile", [])
  }

  static guyTapOnChat(profileId: HornetProfileId) {
    return new HornetAnalyticsEvent("Guy_tapOnChat", [
      { key: "profileId", string_value: profileId },
    ])
  }

  static honeyOpened() {
    return new HornetAnalyticsEvent("Honey_opened", [])
  }

  static honeyTapOnPurchase(productId: string) {
    return new HornetAnalyticsEvent("Honey_tapOnPurchase", [
      { key: "product_id", string_value: productId },
    ])
  }

  static guyTapOnPhoto(profileId: HornetProfileId) {
    return new HornetAnalyticsEvent("Guy_tapOnPhoto", [
      { key: "profileId", string_value: profileId },
    ])
  }

  static profileTapOnAwardList() {
    return new HornetAnalyticsEvent("Profile_tapOnAwardList")
  }

  static profileTapOnFollowers(
    isCurrentUser: boolean,
    profileId: HornetProfileId
  ) {
    return new HornetAnalyticsEvent(
      isCurrentUser ? "MyProfile_tapOnFollowers" : "Guy_tapOnFollowers",
      [{ key: "profile_id", string_value: profileId }]
    )
  }

  static profileTapOnFollowing(
    isCurrentUser: boolean,
    profileId: HornetProfileId
  ) {
    return new HornetAnalyticsEvent(
      isCurrentUser ? "MyProfile_tapOnFollowing" : "Guy_tapOnFollowing",
      [{ key: "profile_id", string_value: profileId }]
    )
  }

  static myProfileTapOnEdit() {
    return new HornetAnalyticsEvent("MyProfile_tapOnEdit")
  }

  static guys_TapOnSearchPopularHashtags(hashtagWithPound: string) {
    return new HornetAnalyticsEvent("Guys_tapOnSearchPopularHashtags", [
      { key: "hashtag", string_value: hashtagWithPound },
    ])
  }

  static guysTapOnSearchFreeText(usernameWithAt: string) {
    return new HornetAnalyticsEvent("Guys_tapOnSearchFreeText", [
      { key: "username", string_value: usernameWithAt },
    ])
  }

  static myProfileTapOnSaveChanges() {
    return new HornetAnalyticsEvent("MyProfile_tapOnSaveChanges")
  }

  static myProfileTapOnAddPhoto(type: "public" | "private") {
    return new HornetAnalyticsEvent("MyProfile_tapOnAddPhoto", [
      { key: "type", string_value: type },
    ])
  }

  static myProfileTapOnDeletePhoto() {
    return new HornetAnalyticsEvent("MyProfile_tapOnDeletePhoto")
  }

  static guyTapOnHeart(profileId: HornetProfileId) {
    return new HornetAnalyticsEvent("Guy_tapOnHeart", [
      { key: "profileId", string_value: profileId },
    ])
  }
}

export default HornetAnalyticsEvent
