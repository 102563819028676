const ShoppingCartIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 21 19"}
    width={21}
    height={19}
    {...props}
  >
    <g fill="#58595A" fillRule="nonzero" stroke="#58595A" strokeWidth={0.3}>
      <path d="M19.485 4.701a1.905 1.905 0 0 0-1.484-.732H5.948l-.376-1.465a1.903 1.903 0 0 0-1.84-1.445h-2.02a.617.617 0 0 0-.613.614c0 .336.277.613.613.613h2.02c.296 0 .553.198.633.495L6.779 12.4c.218.851.97 1.445 1.84 1.445h7.898c.87 0 1.642-.594 1.84-1.445l1.485-6.096a1.814 1.814 0 0 0-.357-1.603Zm-.85 1.326-1.485 6.096a.654.654 0 0 1-.633.495H8.62a.654.654 0 0 1-.634-.495L6.265 5.216H18c.198 0 .396.099.515.257a.621.621 0 0 1 .118.554ZM9.035 14.636a2.07 2.07 0 0 0-2.058 2.059 2.07 2.07 0 0 0 2.058 2.058 2.07 2.07 0 0 0 2.059-2.058 2.07 2.07 0 0 0-2.059-2.059Zm0 2.87a.803.803 0 0 1-.811-.811c0-.455.356-.812.811-.812.456 0 .812.357.812.812a.828.828 0 0 1-.812.811ZM15.844 14.636a2.07 2.07 0 0 0-2.059 2.059 2.07 2.07 0 0 0 2.059 2.058 2.07 2.07 0 0 0 2.058-2.058c-.02-1.128-.93-2.059-2.058-2.059Zm0 2.87a.803.803 0 0 1-.812-.811c0-.455.357-.812.812-.812.455 0 .811.357.811.812a.828.828 0 0 1-.811.811Z" />
    </g>
  </svg>
)
export default ShoppingCartIcon
