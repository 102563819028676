import { identity } from "ramda"
import useSWRInfinite from "swr/infinite"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useCallback, useMemo } from "react"
import NotificationModel, {
  NotificationApiPayload,
  NotificationsApiPayload,
} from "../models/notification.model"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LocalStorageService, {
  StorageKey,
} from "@hornet-web-react/core/services/LocalStorageService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useSWRConfig } from "swr"
import useSWRImmutable from "swr/immutable"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"

type UseNotificationsResult = {
  data: NotificationModel[]
  notificationsHead: string
  loadMore: () => void
  isLoading: boolean
  hasMoreRecords: boolean
  reloadModel: () => void
  error: Error | undefined
  toggleNotificationMute: (notificationId: string, state: boolean) => void
  deleteNotification: (notificationId: string) => void
  updateNotificationsHead: (notificationsHead: string) => void
}

const PER_PAGE = 20

const getKey = () => {
  return (
    pageIndex: number,
    previousPageData: NotificationsApiPayload | null
  ) => {
    // reached the end
    if (
      previousPageData &&
      (previousPageData.activities.length < PER_PAGE ||
        !previousPageData.pagination.next)
    ) {
      return null
    }

    return ApiService.getEndpoint(ApiServiceEndpoint.FeedsNotificationsGet, [
      previousPageData?.pagination?.next || "",
      String(PER_PAGE),
    ])
  }
}

export function useNotifications(
  loadNotifications = false
): UseNotificationsResult {
  // for notification actions
  const { makeApiRequest } = useApi()
  const { showOops } = useFlashMessage()
  const { isAuthenticated } = useSessionUser()

  // for notifications head
  const notificationsHeadSwrKey = "/_services/local_storage/notification_head"
  const localStorageService = useCoreService<LocalStorageService>(
    CORE_TYPES.LocalStorageService
  )

  const localStorageFetcher = async () => {
    return localStorageService.getItem<string>(StorageKey.notificationsHead)
  }

  const { mutate: globalMutate } = useSWRConfig()
  const { data: notificationsHead } = useSWRImmutable(
    notificationsHeadSwrKey,
    localStorageFetcher
  )

  // `data` is an array of each page's API response.
  const { data, error, size, setSize, isValidating, mutate } =
    useSWRInfinite<NotificationsApiPayload>(
      isAuthenticated && loadNotifications ? getKey() : () => null,
      null,
      {
        revalidateFirstPage: false, // this does not revalidate on page transition
        revalidateAll: false, // this is default
        // pick you poison:
        // `revalidateIfStale: false` => no revalidation on first load, data comes from fallback (server-side call)
        //    but cache is not populated, so `mutate` does not work with callback (mutate(data => typeof data === "undefined")) // true
        //    so need to mutate the data differently (via our own cached data)
        // `revalidateIfStale: true` => revalidation on first load, populates cache but every time you mutate locally
        //    without revalidation, next action will revalidate and user gets constant little loading spinners - poor UX
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
      }
    )

  const model = useMemo(() => {
    let notifications: NotificationModel[] = []
    if (data && data.filter(identity).length > 0) {
      notifications = data.reduce((acc, page) => {
        return [
          ...acc,
          ...(page.activities
            .map((apiPayload) => {
              const notification = NotificationApiPayload.safeParse(apiPayload)

              return notification.success
                ? new NotificationModel(notification.data)
                : undefined
            })
            .filter(identity) as NotificationModel[]),
        ]
      }, [] as NotificationModel[])
    }

    return notifications.sort(
      (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
    )
  }, [data])

  return {
    data: model,
    notificationsHead: notificationsHead || "",
    isLoading: isValidating,
    hasMoreRecords:
      isValidating || !!(model && model.length >= PER_PAGE * size),
    error,
    loadMore: useCallback(() => setSize(size + 1), [size, setSize]),
    reloadModel: mutate,

    toggleNotificationMute: useCallback(
      async (notificationId: string, state: boolean) => {
        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(
            state
              ? ApiServiceEndpoint.NotificationMutePut
              : ApiServiceEndpoint.NotificationUnmutePut,
            [notificationId]
          )
        )

        if (isRight(apiResult)) {
          const newData = (data || []).map(
            (payload: NotificationsApiPayload) => {
              return {
                ...payload,
                activities: payload.activities.map((activity) => {
                  if (String(activity.activity.id) === notificationId) {
                    return {
                      activity: {
                        ...activity.activity,
                        is_muted: state,
                      },
                    }
                  }

                  return activity
                }),
              }
            }
          )

          mutate(newData, { revalidate: false })
          return
        }

        void showOops(unwrapEither(apiResult))
      },
      [mutate, data, showOops, makeApiRequest]
    ),

    deleteNotification: useCallback(
      async (notificationId: string) => {
        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(ApiServiceEndpoint.NotificationDelete, [
            notificationId,
          ])
        )

        if (isRight(apiResult)) {
          const newData = (data || []).map(
            (payload: NotificationsApiPayload) => {
              return {
                ...payload,
                activities: payload.activities.filter((activity) => {
                  return String(activity.activity.id) !== notificationId
                }),
              }
            }
          )

          mutate(newData, { revalidate: false })
          return
        }

        void showOops(unwrapEither(apiResult))
      },
      [mutate, data, showOops, makeApiRequest]
    ),

    updateNotificationsHead: useCallback(
      (notificationsHead: string) => {
        localStorageService.setItem(
          StorageKey.notificationsHead,
          notificationsHead
        )
        void globalMutate(notificationsHeadSwrKey)
      },
      [localStorageService, globalMutate]
    ),
  }
}
