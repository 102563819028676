import { useCallback } from "react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LocalStorageService, {
  StorageKey,
} from "@hornet-web-react/core/services/LocalStorageService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useSWRConfig } from "swr"
import useSWRImmutable from "swr/immutable"
import GoogleAnalyticsService from "@hornet-web-react/core/services/GoogleAnalyticsService"

type CookieConsentState = {
  ads: boolean
  traffic: boolean
  is_set: boolean
}

const SWR_KEY = "/_services/local_storage/cookie_consent"

export function useCookieConsent() {
  const localStorageService = useCoreService<LocalStorageService>(
    CORE_TYPES.LocalStorageService
  )
  const googleAnalyticsService = useCoreService<GoogleAnalyticsService>(
    CORE_TYPES.GoogleAnalyticsService
  )

  const localStorageFetcher = async () => {
    return localStorageService.getItem<CookieConsentState>(StorageKey.consent)
  }

  const { mutate } = useSWRConfig()
  const { data, error } = useSWRImmutable(SWR_KEY, localStorageFetcher)

  return {
    enablePersonalizedAds: data?.ads ?? false,
    enableTrafficMetrics: data?.traffic ?? false,
    isSet: data?.is_set ?? false,
    isLoading: !error && !data,

    updateConsent: useCallback(
      (enablePersonalizedAds: boolean, enableTrafficMetrics: boolean) => {
        localStorageService.setItem(StorageKey.consent, {
          ads: enablePersonalizedAds,
          traffic: enableTrafficMetrics,
          is_set: true,
        })
        void mutate(SWR_KEY)

        // also in GA4
        googleAnalyticsService.setConsent(
          enablePersonalizedAds,
          enableTrafficMetrics
        )
      },
      [localStorageService, mutate, googleAnalyticsService]
    ),
  }
}
