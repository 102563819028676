import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
// import AppStoreBadges from "../UI/AppStoreBadges"
import styled from "styled-components"
import Container from "./Container"
// import { CAMPAIGNS } from "@hornet-web-react/core/services/AppStore"
import HeaderLogo from "./HeaderLogo"
import { useRef, useState } from "react"
import Burger from "./Burger"
import { useClickAway } from "react-use"
import {
  useSessionDevice,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import MenuDrawer from "./MenuDrawer"
import { CONTAINER_WULL_WIDTH } from "../../styles/theme"
import NavBar from "./NavBar/NavBar"
import NotificationsButton from "./NavBar/NotificationsButton"
import UnstyledButton from "@hornet-web-react/core/components/UI/UnstyledButton"
import NiceModal from "@ebay/nice-modal-react"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

function TopNavBar({ hideNavBarMenu = false }) {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { isAuthenticated } = useSessionUser()
  const { localeRoute } = useSessionDevice()
  const { get: getBetaInfoModal } = useLazyLoaded(
    () => import("../Modals/BetaInfoModal")
  )

  const logoLink = `${localeRoute}` + (isAuthenticated ? "/grids/nearby" : "")

  const ref = useRef<HTMLDivElement | null>(null)
  useClickAway(ref, () => {
    setIsMenuOpen(false)
  })

  const hasNavBar = isAuthenticated && !hideNavBarMenu

  const handleBetaOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()

    void getBetaInfoModal((modal) => NiceModal.show(modal, {}))
  }

  return (
    <div ref={ref}>
      <Wrapper>
        <TopNavBarContainer isAuthenticated={isAuthenticated}>
          <BurgerButton
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
            isAuthenticated={isAuthenticated} // unauthenticated user sees pages without main menu on the left
          />

          <Content hasNavBar={hasNavBar}>
            <Logo href={logoLink}>
              <HeaderLogo
                alt={t("hornet:alt.hornet_logo")}
                width={200}
                height={36}
                data-test={"hornetLogo"}
              />
              <BetaBadge onClick={handleBetaOnClick}>BETA</BetaBadge>
            </Logo>
            {hasNavBar ? <StretchedNavBar /> : null}
          </Content>

          {isAuthenticated ? <TopNavBarNotificationsButton /> : null}
        </TopNavBarContainer>
      </Wrapper>
      <NavMenu isOpen={isMenuOpen} onFinish={() => setIsMenuOpen(false)} />
      <BarWithShadow />
    </div>
  )
}

export default TopNavBar

const StretchedNavBar = styled(NavBar)`
  min-width: 220px;
  display: none;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: flex;
  }
`

const TopNavBarNotificationsButton = styled(NotificationsButton)`
  position: absolute;
  top: 9px;
  right: ${({ theme }) => theme.spacing.regular};
  z-index: 400;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.height.topNavBar};
  background: ${({ theme }) => theme.color.bg.topNavBar};
  color: ${({ theme }) => theme.color.bg.light01};
  z-index: 300;
  position: fixed;
`

const BarWithShadow = styled(Wrapper)`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  z-index: 100;
`

const NavMenu = styled(MenuDrawer)`
  z-index: 200;
`

const BurgerButton = styled(Burger)<{
  isAuthenticated: boolean
}>`
  position: absolute;
  top: 9px;
  left: ${({ theme }) => theme.spacing.regular};
  z-index: 400;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: ${({ isAuthenticated }) => (isAuthenticated ? "none" : "flex")};
  }
`

const TopNavBarContainer = styled(Container)<{
  isAuthenticated: boolean
}>`
  height: ${({ theme }) => theme.height.topNavBar};
  position: relative;
  z-index: 200;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    max-width: ${({ theme, isAuthenticated }) =>
      isAuthenticated
        ? theme.breakpoints.size.sm
        : theme.width.containerMaxWidthMobile}px;
    padding: 0;
  }

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleRightPanel} {
    max-width: ${({ theme, isAuthenticated }) =>
      isAuthenticated
        ? CONTAINER_WULL_WIDTH + 32
        : theme.width
            .containerMaxWidthMobile}px; // some extra space so the logo looks better above the menu
  }
`

const Content = styled.div<{
  hasNavBar: boolean
}>`
  height: 100%;
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.bg.light01};
  position: relative;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    justify-content: ${({ hasNavBar }) =>
      hasNavBar ? "space-between" : "center"};
  }
`

const Logo = styled(Link)`
  display: block;
  height: 36px;
  width: 200px;
  position: relative;
`

const BetaBadge = styled(UnstyledButton)`
  position: absolute;
  top: -1px;
  right: -24px;
  padding: 3px 6px;
  z-index: 1;
  background: ${({ theme }) => theme.color.tint.default};
  color: #ffffff;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: ${({ theme }) => theme.font.family};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.408px;
  border: 2px solid white;
`
