import {
  DeviceLocation,
  DeviceLocationLatLng,
} from "@hornet-web-react/core/types/session"

export function getLatLngFromDeviceLocation(
  deviceLocation: DeviceLocation
): DeviceLocationLatLng | undefined {
  if (!deviceLocation) {
    return undefined
  }

  const splitByComma = deviceLocation.split(",")

  return DeviceLocationLatLng.parse({
    lat: Number(splitByComma[0]),
    lng: Number(splitByComma[1]),
  })
}

export function getDeviceLocationFromLatLng(latLng: DeviceLocationLatLng) {
  return DeviceLocation.parse(`${latLng.lat},${latLng.lng}`)
}
