export const obfs = function (str, key, n = 126) {
  // return String itself if the given parameters are invalid
  if (!(typeof key === "number" && key % 1 === 0)) {
    return str.toString()
  }

  var chars = str.toString().split("")

  for (var i = 0; i < chars.length; i++) {
    var c = chars[i].charCodeAt(0)

    if (c <= n) {
      chars[i] = String.fromCharCode((chars[i].charCodeAt(0) + key) % n)
    }
  }

  return chars.join("")
}

export const defs = function (str, key, n = 126) {
  // return String itself if the given parameters are invalid
  if (!(typeof key === "number" && key % 1 === 0)) {
    return str.toString()
  }

  return obfs(str.toString(), n - key)
}

export const getRt = function (r1, r2, v) {
  return obfs(
    [r1, r2].join(":" + parseInt(v.replace(/\./g, ""), 10) + ":"),
    3 + (parseInt(v.replace(/\./g, ""), 10) % 10)
  )
}

export const getReq = function (v, rt) {
  if (!rt) {
    // always present on server-side
    if (
      typeof window === "undefined" &&
      process.env["REQ1"] &&
      process.env["REQ2"]
    ) {
      return [process.env["REQ1"], process.env["REQ2"]]
    }

    return [undefined, undefined]
  }

  const reqtag = defs(rt, 3 + (parseInt(v.replace(/\./g, ""), 10) % 10))

  const reqex = new RegExp(
    "([a-zA-Z0-9_-]+):" +
      parseInt(v.replace(/\./g, ""), 10) +
      ":([a-zA-Z0-9-_]*)$"
  )
  let rf = reqtag.match(reqex)

  return [rf[1], rf[2]]
}
