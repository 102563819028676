import Image from "next/legacy/image"
import ImageAppStoreBadge from "../../public/assets/images/app-store-badge.png"
import ImagePlayStoreBadge from "../../public/assets/images/play-store-badge.png"
import styled from "styled-components"
import { FC, useEffect, useState } from "react"
import { Platform } from "@hornet-web-react/core/utils/constants"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import AppStoreService from "@hornet-web-react/core/services/AppStoreService"

const Wrapper = styled.div<BadgeProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;

  height: ${(props) => (props.small ? "38.25px" : "49px")}px;
`

const Badge = styled.a<BadgeProps>`
  display: block;
  width: ${(props) => (props.small ? 148 * 0.75 : 148)}px;
  height: ${(props) => (props.small ? 49 * 0.75 : 49)}px;
  margin: 0 10px 10px;
`

const PlayStoreBadge = styled(Badge)`
  width: ${(props) => (props.small ? 159 * 0.75 : 159)}px;
`

interface BadgeProps {
  small: boolean
}

interface AppStoreBadgesProps {
  utmSource: string
  utmCampaign: string | null
  small?: boolean
  className?: string
}

const AppStoreBadges: FC<AppStoreBadgesProps> = ({
  utmSource,
  utmCampaign,
  small = false,
  className,
}) => {
  const { deviceId } = useSessionDevice()
  const appStoreService = useCoreService<AppStoreService>(
    CORE_TYPES.AppStoreService
  )

  const [appStoreUrl, setAppStoreUrl] = useState(
    appStoreService.getStoreUrl(Platform.ios, utmSource, utmCampaign, false)
  )

  const [playStoreUrl, setPlayStoreUrl] = useState(
    appStoreService.getStoreUrl(Platform.android, utmSource, utmCampaign, false)
  )

  // on client this might change (with the check for desktop)
  useEffect(() => {
    setAppStoreUrl(
      appStoreService.getStoreUrl(Platform.ios, utmSource, utmCampaign)
    )
    setPlayStoreUrl(
      appStoreService.getStoreUrl(Platform.android, utmSource, utmCampaign)
    )
  }, [appStoreService, utmSource, utmCampaign, deviceId])

  const logBadgeClick = (platform: Platform, event) => {
    event.stopPropagation()
    event.preventDefault()

    appStoreService.openAppStore(platform, utmSource)
  }

  return (
    <Wrapper small={small} className={className}>
      <Badge
        small={small}
        href={appStoreUrl}
        rel="noopener noreferrer"
        target="_blank"
        onClick={(e) => logBadgeClick(Platform.ios, e)}
        data-test={"appStoreBadgeLink"}
      >
        <Image
          src={ImageAppStoreBadge}
          alt="AppStore Logo"
          layout="responsive"
          width={148}
          height={49}
          unoptimized={true}
        />
      </Badge>
      <PlayStoreBadge
        small={small}
        href={playStoreUrl}
        rel="noopener noreferrer"
        target="_blank"
        onClick={(e) => logBadgeClick(Platform.android, e)}
        data-test={"appStoreBadgeLink"}
      >
        <Image
          src={ImagePlayStoreBadge}
          alt="PlayStore Logo"
          layout="responsive"
          width={159}
          height={49}
          unoptimized={true}
        />
      </PlayStoreBadge>
    </Wrapper>
  )
}

export default AppStoreBadges
