import styled, { css } from "styled-components"

interface LegendProps {
  isRequired?: boolean
}

const isRequiredStyle = css`
  &:after {
    content: " *";
    color: red;
  }
`

const Legend = styled.legend<LegendProps>`
  ${({ theme }) => theme.font.regular.footnote};
  color: ${({ theme }) => theme.color.text.tertiary};
  margin-top: ${({ theme }) => theme.spacing.half};
  margin-bottom: ${({ theme }) => theme.spacing.half};
  text-transform: uppercase;

  ${(props) => props.isRequired && isRequiredStyle}
`

export default Legend
