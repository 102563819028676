import { debug, runOnClientOnly } from "@hornet-web-react/core/utils"
import { NextRouter } from "next/router"
import { GoogleAnalyticsEventInterface } from "@hornet-web-react/core/models/google-analytics-event"
import { type AppConfig } from "./AppConfig"
import { UNKNOWN_DEVICE_ID } from "@hornet-web-react/core/utils/constants"
import LocalStorageService, { StorageKey } from "./LocalStorageService"
import { DeviceId } from "@hornet-web-react/core/types/session"

export interface GA_DIMENSIONS {
  IS_PREMIUM: string
  OPTIMIZE_EXPERIMENTS: string
  USER_PROFILE_ID: string
  IS_REGISTERED: string
  HAS_LOCATION: string
  USER_COHORT: string
  DAYS_SINCE_SIGNUP: string
  TRACKING_VERSION: string
  IS_WEBVIEW: string
  MOBILE_APP_VERSION: string
  COUNTRY_CODE: string
  DISCOUNT_CODE: string
  FINGERPRINT: string
  ADS_TARGETING_PARAMS: string
}

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

interface GA4Config {
  user_id?: string
  client_id?: string
  send_page_view: boolean
  web_version: "v2"
  in_app: "yes" | "no"
}

interface GoogleAnalyticsServiceContext {
  deviceId: DeviceId
  profileId: string | null
  isInApp: boolean
}

class GoogleAnalyticsService {
  private readonly _appConfig: AppConfig
  private readonly _localStorageService: LocalStorageService
  private _context: GoogleAnalyticsServiceContext
  private _isReady = false
  private _isNextRouterSet = false

  dimensions = {}
  buffer: {
    (): void
  }[] = []
  ga4:
    | {
        (...args: any[]): void
      }
    | undefined

  constructor(
    appConfig: AppConfig,
    localStorageService: LocalStorageService,
    context: GoogleAnalyticsServiceContext
  ) {
    debug(`GoogleAnalyticsService: constructor`)

    this._appConfig = appConfig
    this._localStorageService = localStorageService
    this._context = context

    runOnClientOnly(() => {
      // only continue if at least one GA prop is enabled
      const isEnabled = this._appConfig.googleAnalytics.ga4.isEnabled

      debug(`GoogleAnalyticsService: init, isEnabled: ${isEnabled ? 1 : 0}`)

      // init
      this.init()

      this.determineReady()
    })
  }

  updateContext(context: GoogleAnalyticsServiceContext) {
    this._context = context
  }

  determineReady(countdown = 5) {
    // last try
    if (!countdown) {
      this.turnOn()
      return
    }

    // we got fp, let's go!
    if (this._context.deviceId !== UNKNOWN_DEVICE_ID) {
      this.turnOn()
      return
    }

    setTimeout(() => {
      this.determineReady(countdown - 1)
    }, 600)
  }

  turnOn() {
    debug(`GoogleAnalyticsService: turnOn`)

    this._isReady = true

    // init - tracking landing page
    this.initialTracking()

    // call anything that hasn't processed yet
    this.buffer.forEach((fn) => fn())
  }

  /**
   * Google Analytics 4 = few things:
   * Sets up the `window.gtag` fn as `this.ga4`
   *
   * See:
   *  * "Measure pageviews and screenviews"
   *    https://developers.google.com/analytics/devguides/collection/ga4/views?technology=websites
   */
  init() {
    const isEnabled = this._appConfig.googleAnalytics.ga4.isEnabled

    // GA4: config
    window.dataLayer = window.dataLayer || []
    this.ga4 = function () {
      if (isEnabled) {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments)
      }
    }

    this.ga4("js", new Date())

    this.setConsent(
      this._localStorageService.getItem<{
        ads: boolean
      }>(StorageKey.consent).ads,
      this._localStorageService.getItem<{
        traffic: boolean
      }>(StorageKey.consent).traffic
    )
  }

  setConsent(enablePersonalizedAds: boolean, enableTrafficMetrics: boolean) {
    debug(
      `GoogleAnalyticsService: setConsent(enablePersonalizedAds: ${enablePersonalizedAds}, enableTrafficMetrics: ${enableTrafficMetrics})`
    )

    // privacy controls, see:
    // https://developers.google.com/tag-platform/security/guides/privacy
    this.ga4?.("set", {
      allow_ad_personalization_signals: enablePersonalizedAds,
      restricted_data_processing: !enableTrafficMetrics,
    })

    // privacy controls, see:
    // https://developers.google.com/tag-platform/devguides/privacy#gtag.js_1
    this.ga4?.("consent", "default", {
      ad_storage: enablePersonalizedAds ? "granted" : "denied",
      analytics_storage: enableTrafficMetrics ? "granted" : "denied",
    })
  }

  initialTracking() {
    this.ga4 &&
      this.ga4("config", this._appConfig?.googleAnalytics.ga4.id, {
        debug_mode: this._appConfig?.googleAnalytics.isDebug,
        send_page_view: false,
        web_version: "v2", // nextjs users
        in_app: this._context.isInApp ? "yes" : "no", // webscreen users
      })

    this.trackPageView(null)
  }

  setDimensions(dimensions: Partial<GA_DIMENSIONS>) {
    // user profile ID as a specific configuration
    if (typeof dimensions.USER_PROFILE_ID != "undefined") {
      // add derivative of profile ID
      dimensions.IS_REGISTERED = dimensions.USER_PROFILE_ID ? "Yes" : "No"
    }

    // set the new dimensions to be sent with every hit
    this.dimensions = {
      ...this.dimensions,
      ...dimensions,
    }

    const ga4config: GA4Config = {
      ...this.dimensions,
      send_page_view: false,
      web_version: "v2", // nextjs users
      in_app: this._context.isInApp ? "yes" : "no", // webscreen users
    }

    if (this._context.profileId) {
      ga4config["user_id"] = this._context.profileId
    } else {
      ga4config["client_id"] = this._context.deviceId
    }

    if (this._appConfig?.googleAnalytics.isDebug) {
      debug(
        `GoogleAnalyticsService: setDimensions - UA: ${JSON.stringify(
          dimensions
        )}, GA4: ${JSON.stringify(ga4config)}`
      )
    }

    if (!this._appConfig?.googleAnalytics.ga4.isEnabled) {
      return
    }

    this.ga4 &&
      this.ga4("config", this._appConfig?.googleAnalytics.ga4.id, ga4config)

    // ga4 user property
    if (this.ga4 && this._appConfig?.googleAnalytics.isDebug) {
      debug(
        `GoogleAnalyticsService: setUserProperties - GA4: ${JSON.stringify({
          web_fingerprint: this._context.deviceId,
          d_id: this._localStorageService.getItem<{
            d_id: string
          }>(StorageKey.misc).d_id,
        })}`
      )
    }

    this.ga4 &&
      this.ga4("set", "user_properties", {
        web_fingerprint: this._context.deviceId,
        d_id: this._localStorageService.getItem<{
          d_id: string
        }>(StorageKey.misc).d_id,
      })
  }

  trackPageView(
    pageData: {
      title: string
      location: string
      path: string
    } | null,
    reportToGA4 = true
  ) {
    if (!this._isReady) {
      this.buffer.push(this.trackPageView.bind(this, pageData, reportToGA4))
      return
    }

    function removeLanguageFromPageString(locationOrPath: string) {
      return locationOrPath.replace(/\/[a-z]{2}(-[A-Z]{2})?\//, "/")
    }

    const page = {
      page_title: pageData?.title || document.title,
      page_location: removeLanguageFromPageString(
        pageData?.location || window.location.href
      ),
      page_path: removeLanguageFromPageString(
        pageData?.path || window.location.pathname
      ),
    }

    if (this._appConfig?.googleAnalytics.isDebug) {
      debug(
        `GoogleAnalyticsService: trackPageView - ${page.page_path} (${page.page_title})`
      )
    }

    // GA4
    if (reportToGA4 && this._appConfig?.googleAnalytics.ga4.isEnabled) {
      // GA4
      this.ga4 &&
        this.ga4("event", "page_view", {
          ...page,
          web_version: "v2", // nextjs users
          in_app: this._context.isInApp ? "yes" : "no", // webscreen users
        })
    }
  }

  trackEvent(event: GoogleAnalyticsEventInterface) {
    if (!this._isReady) {
      this.buffer.push(this.trackEvent.bind(this, event))
      return
    }

    if (this._appConfig?.googleAnalytics.isDebug) {
      debug(`GoogleAnalyticsService: trackEvent - ${JSON.stringify(event)}`)
    }

    const eventData: {
      ea: string
      ec: string
      el: string
      ev?: string | number
    } = {
      ea: event.action,
      ec: event.category,
      el: event.label,
    }

    if (event.value) {
      eventData["ev"] = event.value
    }

    // GA4
    if (event.ga4 && this._appConfig?.googleAnalytics.ga4.isEnabled) {
      const eventParams = event.ga4.params

      if (this._context.deviceId) {
        eventParams["web_fingerprint"] = this._context.deviceId
      }

      // webscreen users
      eventParams["in_app"] = this._context.isInApp ? "yes" : "no"

      // nextjs users
      eventParams["web_version"] = "v2"

      eventParams["d_id"] = this._localStorageService.getItem<{
        d_id: string
      }>(StorageKey.misc).d_id

      // see https://support.google.com/analytics/answer/10085872?hl=en&ref_topic=9756175
      this.ga4 && this.ga4("event", event.ga4.name, eventParams)
    }
  }

  registerRouter(router: NextRouter) {
    if (!this._isNextRouterSet) {
      router.events.on("routeChangeComplete", () => {
        this.trackPageView(null, true)
      })

      this._isNextRouterSet = true
    }
  }
}

export default GoogleAnalyticsService
