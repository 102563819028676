import styled, { useTheme } from "styled-components"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import DebugViewButton from "../DebugViewButton"
import { AppConfig } from "@hornet-web-react/core/services/AppConfig"
import { FC, ReactNode } from "react"

const WebViewScreenWrapper = styled.div<{
  backgroundColor: string | null
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.regular};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.bg.light03};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};
    margin: 0 auto;
  }
`

const WebViewScreen: FC<{
  className?: string
  backgroundColor?: string | null
  children: ReactNode
}> = ({ className = "", backgroundColor, children }) => {
  const theme = useTheme()
  const appConfig = useCoreService<AppConfig>(CORE_TYPES.AppConfig)

  const debugView = ["development", "alpha"].includes(appConfig.environment) ? (
    <DebugViewButton />
  ) : undefined

  return (
    <>
      <style jsx global>{`
        html {
          height: auto !important; // otherwise there's bottom white space
          min-height: 100vh;
        }
        body {
          background-color: ${backgroundColor ||
          theme.color.bg.light03} !important;
        }
      `}</style>
      <WebViewScreenWrapper
        className={className}
        backgroundColor={backgroundColor || null}
      >
        {children}
        {debugView}
      </WebViewScreenWrapper>
    </>
  )
}

export default WebViewScreen
