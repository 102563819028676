// alias
enum CORE_TYPES {
  ApiService = "ApiService",
  ApiServiceFactory = "ApiServiceFactory",
  AppConfig = "AppConfig",
  AppStoreService = "AppStoreService",
  CommunityApiService = "CommunityApiService",
  CommunityApiServiceFactory = "CommunityApiServiceFactory",
  EventTrackerService = "EventTrackerService",
  GoogleAnalyticsService = "GoogleAnalyticsService",
  LocalStorageService = "LocalStorageService",
  LoginService = "LoginService",
  LoggerService = "LoggerService",
  LookupDataService = "LookupDataService",
  WebsocketService = "WebsocketService",
}

export { CORE_TYPES, CORE_TYPES as TYPES }
