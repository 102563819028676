import useTranslation from "next-translate/useTranslation"
import PropTypes from "prop-types"
import styled from "styled-components"
// import Image from "next/legacy/image"
// import ImageErrorIllustration from "../../../public/assets/images/error-illustration.svg"
import device from "../../styles/breakpoints"
import {
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { DefaultButtonLink } from "@hornet-web-react/core/components/UI/TextButton"
import WebViewScreen from "@hornet-web-react/core/components/Layout/WebViewScreen"
import { useNavigation } from "../../hooks/use-navigation"

const ContentBox = styled.div`
  width: 80%;
  margin: auto;
  padding: 0;
  overflow: hidden;
  min-height: 200px;
  text-align: center;
  max-width: 400px;
`

const Header = styled.h1`
  ${({ theme }) => theme.font.bold.title2};

  @media screen and ${device.gtSm} {
    ${({ theme }) => theme.font.bold.largeTitle};
  }
`

const SubHeader = styled.h3`
  ${({ theme }) => theme.font.regular.body};

  @media screen and ${device.gtSm} {
    ${({ theme }) => theme.font.regular.title3};
  }
`

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  background: ${({ theme }) => theme.color.bg.light04};
`

export default function PageError({ statusCode, message }) {
  const { t } = useTranslation()
  const { isInApp } = useSessionEnvironment()
  const { isAuthenticated } = useSessionUser()
  const { routeForLanding, routeForHome } = useNavigation()

  const text = isInApp
    ? t("hornet:error_page.subtitle")
    : message && statusCode // TODO: pretty i18n message if it's not translated
    ? `${message} (${statusCode})`
    : null

  const component = (
    <CenterContent>
      <ContentBox>
        {/*<Image src={ImageErrorIllustration} alt="" />*/}
        <Header>{t("hornet:error_page.headline")}</Header>
        {text && <SubHeader>{text}</SubHeader>}

        {!isInApp && (
          <DefaultButtonLink
            href={isAuthenticated ? routeForHome : routeForLanding}
          >
            {t("hornet:error_page.go_to_homepage")}
          </DefaultButtonLink>
        )}
      </ContentBox>
    </CenterContent>
  )

  if (isInApp) {
    return <WebViewScreen>{component}</WebViewScreen>
  }

  return component
}

PageError.propTypes = {
  statusCode: PropTypes.number,
  message: PropTypes.string,
}
