import { ApiServiceEndpointType } from "./ApiServiceEndpoint"

export type CommunityApiServiceEndpointType = ApiServiceEndpointType & {
  api: "shop" | "community"
}

export const CommunityApiServiceEndpoints = {
  ShopOrdersGet: {
    url: `shop://v4/orders?page={page}&per_page={perPage}`,
    hasAuth: true,
    method: "GET",
    api: "shop",
  },
  ShopOrderGet: {
    url: `shop://v4/orders/{orderId}`,
    hasAuth: true,
    method: "GET",
    api: "shop",
  },
  ShopOrderPost: {
    url: `shop://v5/orders`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
  ShopDiscountPost: {
    url: `shop://v2/discount_code/get`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
  ShopOrderRetryPost: {
    url: `shop://v1/stripe/retry-invoice`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
  ShopCampaignGet: {
    url: `shop://v4/campaigns/{campaignId}`,
    hasAuth: false,
    method: "GET",
    api: "shop",
  },
  ShopProductsGet: {
    url: `shop://v4/products?{queryParams}`,
    hasAuth: false,
    method: "GET",
    api: "shop",
  },
  EntlShopCheckoutPost: {
    url: `shop://entl_shop/v1/checkout`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
  EntlShopProductsGet: {
    url: `shop://entl_shop/v2/products?id={availableProductIds}`,
    hasAuth: false,
    method: "GET",
    api: "shop",
  },
  CommunityLoginPost: {
    url: `cmnty://hornet/v1/login?{queryParams}`,
    hasAuth: true, // HACK: keep `true` because we require the Hornet Auth
    method: "POST",
    api: "community",
  },
  QuickiesShopProductsGet: {
    url: `shop://quickies/v1/products?{queryParams}`,
    hasAuth: false,
    method: "GET",
    api: "shop",
  },
  QuickiesShopOrderPost: {
    url: `shop://quickies/v1/orders`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
  QuickiesShopOrderRetryPost: {
    url: `shop://quickies/v1/stripe/retry-invoice`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
  QuickiesSubscriptionMyPlanGet: {
    url: `shop://quickies/v1/subscription/my_plan`,
    hasAuth: true,
    method: "GET",
    api: "shop",
  },
  QuickiesSubscriptionCancelPost: {
    url: `shop://quickies/v1/subscription/cancel`,
    hasAuth: true,
    method: "POST",
    api: "shop",
  },
}

type CommunityApiServiceEndpointKeys = keyof typeof CommunityApiServiceEndpoints

export const CommunityApiServiceEndpoint =
  CommunityApiServiceEndpoints as Record<
    CommunityApiServiceEndpointKeys,
    CommunityApiServiceEndpointType
  >
