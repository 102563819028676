import { LoggedInUser, User } from "@hornet-web-react/core/types/session"
import { getCorrectValue } from "./zod-schema-parse"

export default function getUserFromCookie(
  cookieData: string | undefined
): User | undefined {
  try {
    return getCorrectValue(JSON.parse(cookieData || ""), User)
  } catch (e) {
    return undefined
  }
}

export function getLoggedInUserFromCookie(
  cookieData: string | undefined
): LoggedInUser | undefined {
  try {
    return getCorrectValue(JSON.parse(cookieData || ""), LoggedInUser)
  } catch (e) {
    return undefined
  }
}
