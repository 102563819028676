import { z } from "zod"
import { differenceInDays, isToday, isYesterday } from "date-fns"
import { HornetProfileId } from "@hornet-web-react/core/types"

export const NotificationGroupDate = z.enum([
  "today",
  "yesterday",
  "thisWeek",
  "thisMonth",
  "earlier",
])
export type NotificationGroupDate = z.infer<typeof NotificationGroupDate>

export const NotificationApiPayload = z.object({
  activity: z.object({
    id: z.coerce.string(),
    title: z.string(),
    action: z.string(),
    owned_by_me: z.boolean(),
    is_muteable: z.boolean(),
    is_muted: z.boolean(),
    created_at: z.coerce.date(),
    thumbnail: z
      .object({
        url: z.string().url(),
      })
      .optional(),
    profile: z.object({
      id: HornetProfileId,
      display_name: z.string().nullable(),
      thumbnail_url: z.string().nullable(),
      verification_level: z.number(),
      account: z.object({
        username: z.string(),
      }),
    }),
  }),
})

export type NotificationApiPayload = z.infer<typeof NotificationApiPayload>

export const NotificationsApiPayload = z.object({
  pagination: z.object({
    previous: z.string().nullable().optional(),
    next: z.string().nullable().optional(),
  }),
  activities: z.array(NotificationApiPayload),
})

export type NotificationsApiPayload = z.infer<typeof NotificationsApiPayload>

export type NotificationGroup = Record<
  NotificationGroupDate,
  NotificationModel[]
>

export default class NotificationModel {
  private readonly notification: NotificationApiPayload["activity"]

  constructor(payload: NotificationApiPayload) {
    this.notification = payload.activity

    if (this.notification.thumbnail) {
      this.notification.thumbnail.url = String(
        this.notification.thumbnail.url
      ).replace(/^http:/i, "https:")
    }
  }

  get id() {
    return this.notification.id
  }

  get thumbnailUrl() {
    return this.notification.thumbnail?.url
  }

  get title() {
    return this.notification.title
  }

  get createdAt() {
    return this.notification.created_at
  }

  get profileUsername() {
    return this.notification.profile.account.username
  }

  get profileId() {
    return this.notification.profile.id
  }

  get notificationGroupDate(): NotificationGroupDate {
    if (isToday(this.createdAt)) {
      return NotificationGroupDate.enum.today
    }

    if (isYesterday(this.createdAt)) {
      return NotificationGroupDate.enum.yesterday
    }

    if (isLessThan7DaysAgo(this.createdAt)) {
      return NotificationGroupDate.enum.thisWeek
    }

    if (isLessThan30DaysAgo(this.createdAt)) {
      return NotificationGroupDate.enum.thisMonth
    }

    return NotificationGroupDate.enum.earlier
  }

  get isMuteable() {
    return this.notification.is_muteable
  }

  get isMuted() {
    return this.notification.is_muted
  }
}

function isLessThan7DaysAgo(date: Date) {
  return differenceInDays(new Date(), date) < 7
}

function isLessThan30DaysAgo(date: Date) {
  return differenceInDays(new Date(), date) < 30
}
