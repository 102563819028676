import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react"
import { debug } from "@hornet-web-react/core/utils"

type ProfileVerificationApi = {
  openEmailProfileVerification: () => void
  closeEmailProfileVerification: () => void
  openSmsProfileVerification: () => void
  closeSmsProfileVerification: () => void
  openRecaptchaProfileVerification: () => void
  closeRecaptchaProfileVerification: () => void
  openIpQualityProfileVerification: (redirectTo: string | undefined) => void
  closeIpQualityProfileVerification: () => void
}

type ProfileVerificationState = {
  isEmailProfileVerificationOpen: boolean
  isSmsProfileVerificationOpen: boolean
  isRecaptchaProfileVerificationOpen: boolean
  isIpQualityProfileVerificationOpen: boolean
  ipQualityRedirectTo: string | undefined
}

const initialProfileVerificationState: ProfileVerificationState = {
  isEmailProfileVerificationOpen: false,
  isSmsProfileVerificationOpen: false,
  isRecaptchaProfileVerificationOpen: false,
  isIpQualityProfileVerificationOpen: false,
  ipQualityRedirectTo: undefined,
}

const ProfileVerificationStateContext = createContext<ProfileVerificationState>(
  initialProfileVerificationState
)
const ProfileVerificationApiContext = createContext<ProfileVerificationApi>(
  {} as ProfileVerificationApi
)

type Actions =
  | {
      type: "openEmailProfileVerification"
    }
  | {
      type: "closeEmailProfileVerification"
    }
  | {
      type: "openSmsProfileVerification"
    }
  | {
      type: "closeSmsProfileVerification"
    }
  | {
      type: "openRecaptchaProfileVerification"
    }
  | {
      type: "closeRecaptchaProfileVerification"
    }
  | {
      type: "openIpQualityProfileVerification"
      redirectTo: string | undefined
    }
  | {
      type: "closeIpQualityProfileVerification"
    }

const reducer = (
  state: ProfileVerificationState,
  action: Actions
): ProfileVerificationState => {
  debug(`Profile Verification reducer action: ${action.type}`)

  switch (action.type) {
    case "openEmailProfileVerification":
      return {
        ...state,
        isEmailProfileVerificationOpen: true,
      }
    case "closeEmailProfileVerification":
      return {
        ...state,
        isEmailProfileVerificationOpen: false,
      }
    case "openSmsProfileVerification":
      return {
        ...state,
        isSmsProfileVerificationOpen: true,
      }
    case "closeSmsProfileVerification":
      return {
        ...state,
        isSmsProfileVerificationOpen: false,
      }
    case "openRecaptchaProfileVerification":
      return {
        ...state,
        isRecaptchaProfileVerificationOpen: true,
      }
    case "closeRecaptchaProfileVerification":
      return {
        ...state,
        isRecaptchaProfileVerificationOpen: false,
      }
    case "openIpQualityProfileVerification":
      return {
        ...state,
        isIpQualityProfileVerificationOpen: true,
        ipQualityRedirectTo: action.redirectTo,
      }
    case "closeIpQualityProfileVerification":
      return {
        ...state,
        isIpQualityProfileVerificationOpen: false,
      }
  }
}

type ProfileVerificationProviderProps = {
  children: ReactNode
}

export const ProfileVerificationProvider = ({
  children,
}: ProfileVerificationProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialProfileVerificationState)

  const profileVerificationApi: ProfileVerificationApi = useMemo(() => {
    const openEmailProfileVerification = () => {
      dispatch({ type: "openEmailProfileVerification" })
    }

    const closeEmailProfileVerification = () => {
      dispatch({ type: "closeEmailProfileVerification" })
    }

    const openSmsProfileVerification = () => {
      dispatch({ type: "openSmsProfileVerification" })
    }

    const closeSmsProfileVerification = () => {
      dispatch({ type: "closeSmsProfileVerification" })
    }

    const openRecaptchaProfileVerification = () => {
      dispatch({ type: "openRecaptchaProfileVerification" })
    }

    const closeRecaptchaProfileVerification = () => {
      dispatch({ type: "closeRecaptchaProfileVerification" })
    }

    const openIpQualityProfileVerification = (
      redirectTo: string | undefined
    ) => {
      dispatch({ type: "openIpQualityProfileVerification", redirectTo })
    }

    const closeIpQualityProfileVerification = () => {
      dispatch({ type: "closeIpQualityProfileVerification" })
    }

    return {
      openEmailProfileVerification,
      closeEmailProfileVerification,
      openSmsProfileVerification,
      closeSmsProfileVerification,
      openRecaptchaProfileVerification,
      closeRecaptchaProfileVerification,
      openIpQualityProfileVerification,
      closeIpQualityProfileVerification,
    }
  }, [])

  return (
    <ProfileVerificationApiContext.Provider value={profileVerificationApi}>
      <ProfileVerificationStateContext.Provider value={state}>
        {children}
      </ProfileVerificationStateContext.Provider>
    </ProfileVerificationApiContext.Provider>
  )
}

export const useProfileVerificationApi = () =>
  useContext(ProfileVerificationApiContext)
export const useProfileVerificationState = () =>
  useContext(ProfileVerificationStateContext)
