import styled from "styled-components"

export default styled.span`
  position: absolute;
  z-index: 1;
  background: ${({ theme }) => theme.color.tint.default};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.color.bg.light01};
  display: block;
  width: 12px;
  height: 12px;
  color: #fff;
  text-align: center;
  overflow: hidden;
`
