import PropTypes from "prop-types"
import useTranslation from "next-translate/useTranslation"
import { useId } from "react"

const HoneyIcon = (props: HoneyIconProps) => {
  const { t } = useTranslation()

  // get a unique ID for the gradient defs on every render
  // otherwise Safari fails to fill the path on subsequent renders
  const gradientId = useId()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "18px"}
      height={props.height || "20px"}
      viewBox="0 0 18 20"
      role="img"
      {...props}
    >
      <title>{t("core:components.icons.honey_icon")}</title>
      <defs>
        <linearGradient
          x1="-8.87747825%"
          y1="85.1785685%"
          x2="110.21013%"
          y2="76.4381217%"
          id={`honeyIcon-${gradientId}-1`}
        >
          <stop stopColor="#B558DF" offset="0%"></stop>
          <stop stopColor="#9D47B2" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0.34839527%"
          x2="50%"
          y2="100%"
          id={`honeyIcon-${gradientId}-2`}
        >
          <stop stopColor="#F42D4C" offset="0%"></stop>
          <stop stopColor="#FE5D5A" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="31.8637453%"
          y1="30.701687%"
          x2="96.1745024%"
          y2="65.5999735%"
          id={`honeyIcon-${gradientId}-3`}
        >
          <stop stopColor="#FA6F2C" offset="0%"></stop>
          <stop stopColor="#FFA32A" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="-4.88275171%"
          y1="34.3516175%"
          x2="81.3500853%"
          y2="34.3516175%"
          id={`honeyIcon-${gradientId}-4`}
        >
          <stop stopColor="#FEB721" offset="0%"></stop>
          <stop stopColor="#FEDC47" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id={`honeyIcon-${gradientId}-5`}
        >
          <stop stopColor="#78DD7A" offset="0%"></stop>
          <stop stopColor="#48C171" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="66.7010279%"
          y1="78.3348694%"
          x2="4.76235419%"
          y2="36.7845637%"
          id={`honeyIcon-${gradientId}-6`}
        >
          <stop stopColor="#43A6E9" offset="0%"></stop>
          <stop stopColor="#52C4F1" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="01" transform="translate(-292.000000, -56.000000)">
          <g id="Group-13" transform="translate(283.000000, 48.000000)">
            <g id="Group-30" transform="translate(9.000000, 8.000000)">
              <path
                d="M17.8909811,9.25088928 L17.886566,6.10529122 C17.886566,5.93688444 17.8638113,5.7721532 17.8295094,5.61042923 C17.7181132,6.57576095 17.4664528,7.49899097 17.0921887,8.35873432 C16.5820755,9.53190139 15.8467925,10.587451 14.9403396,11.4735915 C14.1184528,12.2768651 13.1549434,12.9387973 12.0909057,13.4239692 C10.8129057,14.0067101 9.39022642,14.3341677 7.88875472,14.3341677 C7.88264151,14.3341677 7.87720755,14.3338336 7.87109434,14.3338336 C8.68822642,14.8026326 9.57803774,15.1611653 10.5215094,15.3880467 C11.286,15.572158 12.083434,15.6717319 12.9053208,15.6717319 C13.6640377,15.6717319 14.4020377,15.5858578 15.1118491,15.4284777 C16.004717,15.2306665 16.8527547,14.9175769 17.6379623,14.5025745 C17.8026792,14.1701048 17.8970943,13.8025503 17.8964187,13.4206278 L17.8909811,9.25088928 Z"
                id="Fill-4"
                fill={`url(#honeyIcon-${gradientId}-1)`}
              ></path>
              <path
                d="M16.6316604,3.97284037 L12.4481887,1.60345054 L10.1886792,0.323692669 C9.75090566,0.0757604658 9.25573585,-0.026820648 8.76837736,0.00592511473 C9.64256604,0.539881531 10.4257358,1.20281616 11.0927547,1.96732947 C12.0892075,3.10942148 12.8248302,4.47739243 13.2072453,5.98403165 C13.397434,6.73384279 13.5006792,7.51740211 13.5047547,8.32468541 C13.5047547,8.34172657 13.5061132,8.35876773 13.5061132,8.37580889 C13.5061132,10.2212665 12.9885283,11.947436 12.0909057,13.4240026 C13.1549434,12.9388307 14.1184528,12.2768985 14.9403396,11.4736249 C15.8467925,10.5874844 16.5820755,9.5319348 17.0921887,8.35876773 C17.4664528,7.49902439 17.7181132,6.57579436 17.8295094,5.61046264 C17.6861887,4.92881616 17.2589434,4.32803165 16.6316604,3.97284037"
                id="Fill-6"
                fill={`url(#honeyIcon-${gradientId}-2)`}
              ></path>
              <path
                d="M13.2072453,5.98386458 C12.8248302,4.47722536 12.0892075,3.10925441 11.0927547,1.9671624 C10.4257358,1.20264909 9.64256604,0.539714461 8.76837736,0.00575804451 C8.39275472,0.0314868581 8.02120755,0.134736253 7.68430189,0.326532863 L3.39113208,2.77244085 L1.24981132,3.9917193 C0.695207547,4.30781616 0.297169811,4.81704618 0.114113208,5.4031285 C1.00426415,4.88053286 1.98407547,4.49192754 3.02501887,4.26137064 C3.73516981,4.10399049 4.47283019,4.01845054 5.23154717,4.01845054 C5.96818868,4.01845054 6.68513208,4.09864424 7.37660377,4.24767088 C8.48988679,4.48691543 9.53456604,4.90759824 10.4753208,5.4783101 C11.6772453,6.20740453 12.710717,7.17908492 13.5047547,8.32485248 C13.5006792,7.51756918 13.397434,6.73367572 13.2072453,5.98386458"
                id="Fill-8"
                fill={`url(#honeyIcon-${gradientId}-3)`}
              ></path>
              <path
                d="M7.37660377,4.24757064 C6.68513208,4.09887814 5.96818868,4.0183503 5.23154717,4.0183503 C4.47283019,4.0183503 3.73516981,4.10389025 3.02501887,4.26127039 C1.98407547,4.49182729 1.00426415,4.88076676 0.114113208,5.40302826 C0.0417735849,5.63492172 8.44607403e-15,5.87784182 8.44607403e-15,6.12744473 L0.00611320755,10.5504617 L0.0101886792,13.4427813 C0.0101886792,13.6449363 0.0387169811,13.8430815 0.0869433962,14.0348781 C0.206830189,13.1260162 0.450339623,12.2555803 0.804226415,11.4419484 C1.35475472,10.1765585 2.16781132,9.0491687 3.17479245,8.12259727 C3.91313208,7.44295562 4.75471698,6.87057306 5.67611321,6.43451979 C6.9870566,5.81435514 8.45626415,5.46651495 10.008,5.46651495 C10.1649057,5.46651495 10.3204528,5.47119291 10.4753208,5.478544 C9.53456604,4.90783214 8.48988679,4.48681519 7.37660377,4.24757064"
                id="Fill-10"
                fill={`url(#honeyIcon-${gradientId}-4)`}
              ></path>
              <path
                d="M6.80383019,17.6696578 C5.84745283,16.573343 5.1315283,15.2685246 4.73756604,13.8333915 C4.51273585,13.0127425 4.39081132,12.1513285 4.39081132,11.2611784 C4.39081132,11.1763067 4.39454717,11.0924375 4.39692453,11.0078999 C4.43971698,9.34889267 4.89854717,7.79179824 5.67628302,6.43451979 C4.75488679,6.87057306 3.91296226,7.44295562 3.17462264,8.12259727 C2.16798113,9.0491687 1.35458491,10.1765585 0.804396226,11.4419484 C0.450509434,12.2555803 0.206660377,13.1260162 0.0871132075,14.0348781 C0.24945283,14.6760936 0.667867925,15.2367813 1.26458491,15.5745973 L5.56522642,18.0108152 L7.70790566,19.2240791 C8.10730189,19.4502922 8.55424528,19.5575513 9.00016981,19.5502002 C8.17760377,19.0306118 7.43790566,18.3964133 6.80383019,17.6696578"
                id="Fill-13"
                fill={`url(#honeyIcon-${gradientId}-5)`}
              ></path>
              <path
                d="M15.1119849,15.4285779 C14.401834,15.585958 13.663834,15.6718321 12.9054566,15.6718321 C12.0835698,15.6718321 11.2857962,15.5722583 10.5213057,15.3881469 C9.57817358,15.1612656 8.68836226,14.8027329 7.87123019,14.3339338 C6.45500377,13.5209702 5.26021132,12.3768733 4.39689057,11.0082341 C4.39451321,11.0924375 4.39077736,11.1763067 4.39077736,11.2611784 C4.39077736,12.1513285 4.51270189,13.0130767 4.73753208,13.8333915 C5.13149434,15.2685246 5.84741887,16.573343 6.80379623,17.6696578 C7.4378717,18.3964133 8.17756981,19.0306118 9.00013585,19.5502002 C9.41923019,19.5428491 9.83696604,19.4349217 10.2122491,19.221406 L14.8504755,16.5790234 L16.6467396,15.5562195 C17.0848528,15.3066166 17.423117,14.9350525 17.6377585,14.5026748 C16.8528906,14.9176772 16.0048528,15.2307668 15.1119849,15.4285779"
                id="Fill-15"
                fill={`url(#honeyIcon-${gradientId}-6)`}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

type HoneyIconProps = {
  width?: string
  height?: string
}

export default HoneyIcon

HoneyIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}
