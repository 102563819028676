import { FC, FormEvent, ReactNode, RefObject } from "react"
import {
  Wrapper,
  Background,
  Label,
  AppendText,
  FormInfo,
  SelectContainer,
  FormSelect,
  SelectArrowIcon,
  InfoIcon,
} from "./Select.styles"
import { useUniqueId } from "@hornet-web-react/core/utils"

type SelectControlOption = {
  optionValue: string
  optionLabel: string
}

type SelectControlProps = {
  className?: string
  name: string
  value: string
  inputRef?: RefObject<HTMLSelectElement>
  onChange: (value: string, name: string) => void
  options: SelectControlOption[]
  appendText?: string
  inputUniqueId?: string
}

export const SelectControl: FC<SelectControlProps> = (props) => {
  const {
    className,
    name,
    value,
    options,
    onChange,
    inputRef,
    appendText,
    ...attrs
  } = props

  const uniqueId = useUniqueId(name)

  const id = props.inputUniqueId || uniqueId || ""

  const onInput = (e: FormEvent<HTMLSelectElement>) => {
    e.stopPropagation()
    onChange(e.currentTarget.value, e.currentTarget.name)
  }

  return (
    <SelectContainer className={className}>
      <FormSelect
        className="form-select__input"
        name={name}
        onInput={onInput}
        ref={inputRef}
        value={value}
        {...attrs}
        id={id}
      >
        {options.map((option) => {
          return (
            <option key={option.optionValue} value={option.optionValue}>
              {option.optionLabel}
            </option>
          )
        })}
      </FormSelect>

      {appendText && <AppendText>{appendText}</AppendText>}

      <SelectArrowIcon />
    </SelectContainer>
  )
}

export type SelectProps = {
  className?: string
  name: string
  value: string
  label: string
  inputRef?: RefObject<HTMLSelectElement>
  onChange: (value: string, name: string) => void
  options: SelectControlOption[]
  appendText?: string
  disabled?: boolean
  children?: ReactNode
}

const Select: FC<SelectProps> = (props) => {
  // consume some props and leave the attrs
  const {
    className,
    name,
    label,
    value,
    options,
    onChange,
    appendText,
    children,
    inputRef,
    ...attrs
  } = props

  const inputUniqueId = useUniqueId(name)

  return (
    <Wrapper className={className} isDisabled={props.disabled}>
      <Background className="form-input__background">
        {label && <Label htmlFor={inputUniqueId}>{label}</Label>}
        <SelectControl
          name={name}
          value={value}
          onChange={onChange}
          inputRef={inputRef}
          inputUniqueId={inputUniqueId}
          options={options}
          appendText={appendText}
          {...attrs}
        />
      </Background>

      {children && (
        <FormInfo>
          <InfoIcon />
          {children}
        </FormInfo>
      )}
    </Wrapper>
  )
}

export default Select
