import { debug, runOnClientOnly } from "@hornet-web-react/core/utils"
import applySpec from "ramda/es/applySpec"
import head from "ramda/es/head"
import propEq from "ramda/es/propEq"
import propOr from "ramda/es/propOr"
import LoggerService from "./LoggerService"
import { format } from "date-fns"
import { v4 as uuidv4 } from "uuid"
import { retrieveLocalStorageAnalytics } from "@hornet-web-react/core/utils/retrieve-local-storage-analytics"

interface StorageBox {
  trackedKey: StorageKey
  storageKey: string
  init: (value: any) => any
}

export enum StorageKey {
  shop = "shop",
  entitlementShop = "entitlement_shop",
  softPaywallProfileViews = "sp:p",
  softPaywallConversations = "sp:c",
  misc = "misc",
  featureFlags = "ff",
  consent = "consent",
  notificationsHead = "nots_head",
  api = "api",
  quickiesProfileViews = "qpv",
  quickiesVanillaMode = "qvm",
  analytics = "a",
}

export type LocalStorageAnalytics = {
  utmSource: string
  utmMedium: string
  utmCampaign: string
  utmTerm: string
  utmKeyword: string
  referralId: string
}

class LocalStorageService {
  private readonly _loggerService: LoggerService

  _isSupported = false

  storages: StorageBox[] = [
    {
      trackedKey: StorageKey.shop,
      storageKey: "local:shop",
      init(value: object | null) {
        return applySpec({
          cart: propOr([], "cart"),
          checkout: propOr({}, "checkout"),
          discount_code: propOr({}, "discount_code"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.entitlementShop,
      storageKey: "local:entl_shop",
      init(value: object | null) {
        return applySpec({
          cart: propOr([], "cart"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.softPaywallProfileViews,
      storageKey: "local:sp:p",
      init(value: object | null) {
        const today = format(new Date(), "Y-MM-d")

        return applySpec({
          date: propOr(today, "date"),
          pIds: propOr([], "pIds"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.softPaywallConversations,
      storageKey: "local:sp:c",
      init(value: object | null) {
        const today = format(new Date(), "Y-MM-d")

        return applySpec({
          date: propOr(today, "date"),
          pIds: propOr([], "pIds"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.misc,
      storageKey: "local:misc",
      init(value: object | null) {
        return applySpec({
          d_id: propOr(`web-${uuidv4()}`, "d_id"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.featureFlags,
      storageKey: "local:ff",
      init(value: object | null) {
        return applySpec({
          pushNotifications: propOr(false, "pushNotifications"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.consent,
      storageKey: "local:consent",
      init(value: object | null) {
        return applySpec({
          is_set: propOr(false, "is_set"),
          ads: propOr(false, "ads"),
          traffic: propOr(false, "traffic"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.notificationsHead,
      storageKey: "local:nots_head",
      init(value: string | null) {
        return value || ""
      },
    },
    {
      trackedKey: StorageKey.api,
      storageKey: "local:api",
      init(value: object | null) {
        return applySpec({
          hornet: propOr("", "hornet"),
          shop: propOr("", "shop"),
          community: propOr("", "community"),
          quickies: propOr("", "quickies"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.quickiesProfileViews,
      storageKey: "local:qpv",
      init(value: object | null) {
        const today = format(new Date(), "Y-MM-d")

        return applySpec({
          date: propOr(today, "date"),
          pIds: propOr([], "pIds"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.quickiesVanillaMode,
      storageKey: "local:qvm",
      init(value: object | null) {
        return applySpec({
          state: propOr(false, "state"),
        })(value)
      },
    },
    {
      trackedKey: StorageKey.analytics,
      storageKey: "local:a",
      init(value: LocalStorageAnalytics | null) {
        const {
          utmSource,
          utmMedium,
          utmCampaign,
          utmTerm,
          utmKeyword,
          isUtmEmpty,
          referralId,
        } = retrieveLocalStorageAnalytics()

        // referral ID gets only set, if it did not exist before
        // it gets never overwritten
        let storageReferralId = value?.referralId || ""
        if (!storageReferralId) {
          storageReferralId = referralId
        }

        // when the utm params are empty, we don't want to override the
        // existing UTM values
        if (isUtmEmpty) {
          // re-use existing values
          return applySpec({
            utmSource: propOr("", "utmSource"),
            utmMedium: propOr("", "utmMedium"),
            utmCampaign: propOr("", "utmCampaign"),
            utmTerm: propOr("", "utmTerm"),
            utmKeyword: propOr("", "utmKeyword"),
            referralId: propOr(storageReferralId, "referralId"),
          })(value)
        }

        // otherwise, it means we need to wipe clean our current UTM analytics
        // and apply the new ones (so we don't get mixed values from now and the past
        return {
          utmSource,
          utmMedium,
          utmCampaign,
          utmTerm,
          utmKeyword,
          referralId: storageReferralId,
        }
      },
    },
  ]

  constructor(loggerService: LoggerService) {
    this._loggerService = loggerService

    debug(`LocalStorageService: constructor`)

    runOnClientOnly(() => {
      try {
        window.localStorage.setItem("lstest", "lstest")
        window.localStorage.removeItem("lstest")
        this._isSupported = true
      } catch (e) {
        this._isSupported = false
        this._loggerService.logMessageWithSentry(
          "LocalStorage not supported.",
          this._loggerService.createLoggingContext({
            service: "LocalStorage",
          })
        )
      }

      debug(
        `LocalStorageService: init, isSupported: ${this._isSupported ? 1 : 0}`
      )

      this.storages.forEach((storage: StorageBox) => {
        this._initStorage(storage)
      })
    })
  }

  _initStorage(storage: StorageBox) {
    let data = null

    try {
      data = JSON.parse(window.localStorage.getItem(storage.storageKey) || "")
    } catch (e) {
      // defaults will be provided
    }

    this.setItem(storage.trackedKey, storage.init(data))
  }

  getItem<T>(storageName: StorageKey): T {
    debug(`LocalStorageService: getItem(${storageName})`)

    const storage = head(
      this.storages.filter(propEq("trackedKey", storageName))
    )

    if (!storage) {
      throw new Error(
        `LocalStorage@setItem: unknown storageName: ${storageName}`
      )
    }

    try {
      return JSON.parse(window.localStorage.getItem(storage.storageKey) || "")
    } catch (e) {
      // return defaults
      return storage.init(null)
    }
  }

  setItem(storageName: StorageKey, value: any) {
    debug(`LocalStorageService: setItem(${storageName}, ...)`)

    const storage = head(
      this.storages.filter(propEq("trackedKey", storageName))
    )

    if (!storage) {
      throw new Error(
        `LocalStorage@setItem: unknown storageName: ${storageName}`
      )
    }

    // validation, enforcing expected structure
    value = storage.init(value)

    // set
    if (this._isSupported) {
      try {
        window.localStorage.setItem(storage.storageKey, JSON.stringify(value))
      } catch (error) {
        if (error instanceof Error) {
          // oops, this shouldn't happen :/
          this._loggerService.logExceptionWithSentry(
            error,
            this._loggerService.createLoggingContext({
              service: "LocalStorage",
              step: "support_check",
            })
          )
        }
      }
    }
  }

  clear(storageName: StorageKey) {
    debug(`LocalStorageService: clear(${storageName})`)

    const storage = head(
      this.storages.filter(propEq("trackedKey", storageName))
    )

    if (!storage) {
      throw new Error(
        `LocalStorage@setItem: unknown storageName: ${storageName}`
      )
    }

    this.setItem(storage.trackedKey, storage.init(null))
  }
}

export default LocalStorageService
