const StoriesIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 20 22"}
    width={20}
    height={22}
    {...props}
  >
    <path
      fill="#5A5A5A"
      fillRule="evenodd"
      stroke="#5A5A5A"
      strokeWidth={0.3}
      d="M16.115 1c.524 0 .969.173 1.335.52.367.346.55.766.55 1.261V18.22c0 .495-.183.915-.55 1.261-.366.347-.811.52-1.335.52H3.572c-.236 0-.508-.074-.815-.223-.308-.148-.461-.47-.461-.965V16.42h-.648a.598.598 0 0 1-.422-.176.556.556 0 0 1-.187-.418c0-.16.062-.297.187-.408a.616.616 0 0 1 .422-.167h.648v-2.375h-.648a.598.598 0 0 1-.422-.176.556.556 0 0 1-.187-.418c0-.16.062-.297.187-.408a.616.616 0 0 1 .422-.167h.648V9.294h-.629a.605.605 0 0 1-.431-.167.54.54 0 0 1-.177-.408.54.54 0 0 1 .177-.408.605.605 0 0 1 .431-.167h.629V5.75h-.687a.605.605 0 0 1-.432-.167A.54.54 0 0 1 1 5.175c0-.161.059-.3.177-.418a.588.588 0 0 1 .432-.176h.687V2.225c0-.297.104-.576.314-.835.21-.26.536-.39.981-.39h12.524ZM3.552 18.75h10.05v-16H3.553v2.304h.609a.6.6 0 0 1 .431.17.539.539 0 0 1 0 .794.618.618 0 0 1-.431.16h-.609v2.304h.667c.17 0 .314.054.432.16a.511.511 0 0 1 .177.394.511.511 0 0 1-.177.393.618.618 0 0 1-.432.16h-.667v2.322H4.2c.17 0 .317.053.441.16a.5.5 0 0 1 .187.393.526.526 0 0 1-.187.402.635.635 0 0 1-.441.17h-.648v2.285H4.2c.17 0 .317.054.441.161a.5.5 0 0 1 .187.393.526.526 0 0 1-.187.402.635.635 0 0 1-.441.17h-.648v2.303Zm13.192-.571V3.32a.526.526 0 0 0-.187-.401.635.635 0 0 0-.442-.17H14.86v16h1.256c.17 0 .318-.057.442-.17a.526.526 0 0 0 .187-.401Z"
    />
  </svg>
)
export default StoriesIcon
