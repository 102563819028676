const getMobileOS = (userAgent) => {
  if (/android/i.test(userAgent)) {
    return "android"
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios"
  }
  return "other"
}

export function isIOS(userAgent = "") {
  // in SSR, supply the userAgent from request headers
  if (!userAgent && typeof window !== "undefined") {
    userAgent = window.navigator ? window.navigator.userAgent : ""
  }

  return getMobileOS(String(userAgent)) === "ios"
}

export function isAndroid(userAgent = "") {
  if (!userAgent && typeof window !== "undefined") {
    userAgent = window.navigator ? window.navigator.userAgent : ""
  }

  return getMobileOS(String(userAgent)) === "android"
}
