import useImmutableSWR, { mutate } from "swr"
import MemberModel, {
  MemberApiPayload,
} from "@hornet-web-react/core/models/member.model"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { HornetProfileId } from "@hornet-web-react/core/types"

type UseMemberResult = {
  data: MemberModel | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useMember(
  profileId: HornetProfileId | undefined
): UseMemberResult {
  const { currentUser } = useSessionUser()

  const { data, error } = useImmutableSWR<MemberApiPayload>(
    profileId ? `members/${profileId}` : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const memberModel = MemberApiPayload.safeParse(data)

  let member
  if (memberModel.success) {
    member = new MemberModel(
      memberModel.data,
      Boolean(currentUser?.isImperialUnitOfMeasure)
    )
  }

  return {
    data: member,
    isLoading: !error && !data,
    error,
  }
}

export function reloadMember(profileId: HornetProfileId): Promise<void> {
  return mutate(`members/${profileId}`)
}

export async function updateMemberLocally(data: MemberModel): Promise<void> {
  const serializedData = data.serialize()

  await mutate(`members/${data.profileId}`, serializedData, {
    revalidate: false,
    optimisticData: serializedData,
    rollbackOnError: false,
  })
}

export async function setMemberIsFavouriteLocally(
  data: MemberModel,
  isFavourite: boolean
): Promise<void> {
  return updateMemberLocally(data.setIsFavourite(isFavourite))
}
