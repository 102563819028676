import { z } from "zod"
import { InboxType, MessageApiPayload } from "./messages-conversation.model"

export const IncomingRealtimeMessageApiPayload = z.object({
  type: z.literal("message"),
  data: MessageApiPayload.merge(
    z.object({
      inbox: InboxType.nullable(),
      inboxes: z.array(InboxType).nullable(),
    })
  ),
})
export type IncomingRealtimeMessageApiPayload = z.infer<
  typeof IncomingRealtimeMessageApiPayload
>

export const UpdatedRealtimeMessageApiPayload = z.object({
  data: MessageApiPayload,
})
export type UpdatedRealtimeMessageApiPayload = z.infer<
  typeof UpdatedRealtimeMessageApiPayload
>

export const RealtimeConversationUpdatedApiPayload = z.object({
  data: z.object({
    conversation: z.object({
      sender_id: z.coerce.string(),
      inbox: InboxType,
    }),
  }),
})
export type RealtimeConversationUpdatedApiPayload = z.infer<
  typeof RealtimeConversationUpdatedApiPayload
>
