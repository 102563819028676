import { z } from "zod"

export const SendingDisabledState = z.boolean()
export type SendingDisabledState = z.infer<typeof SendingDisabledState>

export const SendingDisabledReason = z.string().nullable()
export type SendingDisabledReason = z.infer<typeof SendingDisabledReason>

export const RealtimeSessionTotalsApiPayload = z.object({
  type: z.literal("totals").optional(),
  data: z.object({
    totals: z.object({
      blocks: z.number().optional(),
      private_photo_access_permissions: z.number().optional(),
      favourites: z.number().optional(),
      fans: z.number().optional(),
      matches: z.number().optional(),
      posts: z.number().optional(),
      awards: z.number().optional(),
      spaces: z.number().optional(),
      timeline_head: z.string().optional(),
      timeline_updated_at: z.coerce.date().optional(),
      notifications_head: z.string().optional(),
      notifications_updated_at: z.coerce.date().optional(),
      unread_messages: z.number().optional(),
      primary_inbox_dot: z.boolean().optional(),
      requests_inbox_dot: z.boolean().optional(),
    }),
  }),
})
export type RealtimeSessionTotalsApiPayload = z.infer<
  typeof RealtimeSessionTotalsApiPayload
>

export const RealtimeSessionValidationApiPayload = z.object({
  type: z.literal("account_validated"),
  data: z.object({
    sending_disabled: SendingDisabledState,
    sending_disabled_reason: SendingDisabledReason,
  }),
})
export type RealtimeSessionValidationApiPayload = z.infer<
  typeof RealtimeSessionValidationApiPayload
>
