// import Image from "next/legacy/image"
import useTranslation from "next-translate/useTranslation"
import styled from "styled-components"
import Container from "./Container"
import device from "../../styles/breakpoints"
import { useSessionEnvironment } from "@hornet-web-react/core/contexts/session"
import { getEmberAppUrl } from "../../utils/ember-app-url"

const footerHeight = "50px"
const footerHeightMobile = "50px"

const Wrapper = styled.footer`
  width: 100%;
  height: ${footerHeightMobile};
  background: white;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 300;

  @media screen and ${device.gtSm} {
    height: ${footerHeight};
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: calc(${footerHeightMobile} + var(--safe-area-inset-bottom));
  }
`

const FooterContainer = styled(Container)`
  height: ${footerHeightMobile};

  @media screen and ${device.gtSm} {
    height: ${footerHeight};
  }
`

const Content = styled.div`
  height: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.bg.light01};
  position: relative;
`

const BetaDisclaimer = styled.div`
  ${({ theme }) => theme.font.regular.caption2};
  color: ${({ theme }) => theme.color.text.primary};
  max-height: ${footerHeight};
  overflow: hidden;
  text-align: center;
  margin-bottom: 2px;
`

const Copyright = styled.div`
  ${({ theme }) => theme.font.regular.caption2};
  color: ${({ theme }) => theme.color.text.secondary};
  max-height: ${footerHeight};
  overflow: hidden;
  text-align: center;
`

// const Logo = styled.div`
//   cursor: pointer;
//   width: 152px;
//
//   img {
//     display: block;
//     height: 30px;
//   }
//
//   @media screen and ${device.gtSm} {
//     width: 200px;
//   }
// `

function Footer() {
  const { t } = useTranslation()
  const { appUrl } = useSessionEnvironment()
  const emberAppUrl = getEmberAppUrl(appUrl)

  return (
    <Wrapper>
      <FooterContainer>
        <Content>
          <BetaDisclaimer>
            This is a Beta version. For the main site, visit{" "}
            <a href={emberAppUrl} target={"_blank"}>
              hornet.com
            </a>
          </BetaDisclaimer>
          <Copyright>{t("hornet:copyright")}</Copyright>
        </Content>
      </FooterContainer>
    </Wrapper>
  )
}

export default Footer
