import useImmutableSWR, { KeyedMutator } from "swr"
import {
  ApiSessionPayload,
  createFullSession,
  FullSessionInterface,
} from "@hornet-web-react/core/models/full-session-model"
import {
  useSessionApi,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useCallback, useMemo } from "react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { LoginApiPayload } from "@hornet-web-react/core/types/session"
import { debug } from "@hornet-web-react/core/utils"
import LoginService from "@hornet-web-react/core/services/LoginService"

type UseFullSessionResult = {
  data: FullSessionInterface | undefined
  isLoading: boolean
  error: Error | undefined
  mutate: KeyedMutator<ApiSessionPayload>
  markAccountAsVerified: (isVerified: boolean) => void
  updatePhoneNumber: (phoneNumber: string | null) => void
}

export function useHornetSession(): UseFullSessionResult {
  const user = useSessionUser()
  const loginService = useCoreService<LoginService>(CORE_TYPES.LoginService)
  const {
    user: { storeUser },
  } = useSessionApi()
  const { data, error, mutate } = useImmutableSWR<ApiSessionPayload>(
    user.isAuthenticated ? "/session" : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  // TODO: remove this logging once we figure out the `Cannot read properties of undefined (reading 'profile')` error
  // see https://hornet-networks-ltd.sentry.io/issues/?alert_rule_id=12740597&alert_type=issue&notification_uuid=2b6d50ef-7fae-4e57-b333-4ef24f8d35ea&project=4504004558651392&query=is%3Aunresolved+profile&referrer=issue-list&statsPeriod=14d
  // essentially it seems that `data` is not `undefined` but `data.session` is so let's log the payload in such case
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)

  const session = useMemo(() => {
    if (typeof data !== "undefined" && typeof data.session === "undefined") {
      loggerService.logMessageWithSentry(
        "useFullSession: data.session is undefined",
        loggerService.createLoggingContext({ data })
      )
    }

    return typeof data !== "undefined" && typeof data.session !== "undefined"
      ? createFullSession(data)
      : undefined

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    data: session,
    isLoading: !error && !data,
    error,

    mutate: async () => {
      const data = await mutate()

      // also, keep user cookie in sync when session is updated
      if (data) {
        // validate first
        const session = LoginApiPayload.safeParse(data)
        debug(`useHornetSession: storeUser: ${session.success ? "yes" : "no"} `)
        if (session.success) {
          storeUser(loginService.transformSessionToLoginPayload(session.data))
        } else {
          console.log("useHornetSession: storeUser error", session.error.issues)
        }
      }

      return data
    },

    markAccountAsVerified: useCallback(
      (isVerified: boolean) => {
        if (!data) {
          return
        }

        void mutate(
          {
            ...data,
            session: {
              ...data.session,
              account: {
                ...data.session.account,
                email_verified: isVerified,
              },
            },
          },
          { revalidate: false }
        )
      },
      [data, mutate]
    ),

    updatePhoneNumber: useCallback(
      (phoneNumber: string | null) => {
        if (!data) {
          return
        }

        void mutate(
          {
            ...data,
            session: {
              ...data.session,
              account: {
                ...data.session.account,
                phone_number: phoneNumber,
              },
            },
          },
          { revalidate: false }
        )
      },
      [data, mutate]
    ),
  }
}
