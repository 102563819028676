import { Device, Size } from "@hornet-web-react/core/types/theme"

export const size: Size = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
}
const device: Device = {
  xs: `(max-width: ${size.xs - 1}px)`, // < 576
  gtXs: `(min-width: ${size.xs}px)`, // >= 576
  sm: `(min-width: ${size.xs}px) and (max-width: ${size.sm - 1}px)`, // >= 576 && < 768
  gtSm: `(min-width: ${size.sm}px)`, // >= 768
  md: `(min-width: ${size.sm}px) and (max-width: ${size.md - 1}px)`, // >= 768 && < 992
  gtMd: `(min-width: ${size.md}px)`, // >= 992
  lg: `(min-width: ${size.md}px) and (max-width: ${size.lg - 1}px)`, // >= 992 && < 1200
  gtLg: `(min-width: ${size.lg}px)`, // >= 1200
  xl: `(min-width: ${size.xl}px)`, // >= 1400
}

export default device
