import styled, { css } from "styled-components"

export const UnstyledButtonStyle = css`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  user-select: none;
  text-align: left;
  -webkit-tap-highlight-color: transparent;
`

const UnstyledButton = styled.button`
  ${UnstyledButtonStyle};
`

export const UnstyledButtonLink = styled.a`
  ${UnstyledButtonStyle};
`

export default UnstyledButton
