import { FC } from "react"
import styled from "styled-components"
import Menu from "./Menu"

type MenuDrawerProps = {
  className?: string
  isOpen: boolean
  onFinish: () => void
}

const MenuDrawer: FC<MenuDrawerProps> = ({ className, isOpen, onFinish }) => {
  return (
    <StyledMenu className={className} open={isOpen}>
      <Menu onFinish={onFinish} />
    </StyledMenu>
  )
}

export default MenuDrawer

const StyledMenu = styled.div<{ open: boolean }>`
  color: black;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  transform: ${({ open }) =>
    open ? "translate(-50%, 0)" : "translate(-50%, -100%)"};
  height: auto;
  text-align: left;
  padding: 16px 32px 32px;
  width: 100%;
  position: fixed;
  top: ${({ open, theme }) => (open ? theme.height.topNavBar : 0)};
  left: 50%;
  transition: transform 0.3s ease-in-out;
  z-index: 200;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.default};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};
  max-width: 420px;

  // it could overflow the short screen
  max-height: calc(100% - ${({ theme }) => theme.height.topNavBar} - 32px);
  overflow: auto;

  @media (max-width: 876px) {
    width: 100%;
  }
`
