let locales = [
  "af",
  "af-ZA",
  "ar",
  "ar-SA",
  "be",
  "be-BY",
  "bg",
  "bg-BG",
  "cs",
  "cs-CZ",
  "da",
  "da-DK",
  "de",
  "de-DE",
  "el",
  "el-GR",
  "en",
  "en-CA",
  "en-GB",
  "es",
  "es-ES",
  "es-MX",
  "fa",
  "fa-IR",
  "fil",
  "fil-PH",
  "fi",
  "fi-FI",
  "fr",
  "fr-CA",
  "fr-FR",
  "he",
  "he-IL",
  "hi",
  "hi-IN",
  "hu",
  "hu-HU",
  "id",
  "id-ID",
  "it",
  "it-IT",
  "ja",
  "ja-JP",
  "ko",
  "ko-KR",
  "ky",
  "ky-KG",
  "lt",
  "lt-LT",
  "ms",
  "ms-MY",
  "nl",
  "nl-NL",
  "no",
  "no-NO",
  "pl",
  "pl-PL",
  "pt",
  "pt-BR",
  "pt-PT",
  "ro",
  "ro-RO",
  "ru",
  "ru-RU",
  "sk",
  "sk-SK",
  "sv",
  "sv-SE",
  "th",
  "th-TH",
  "tr",
  "tr-TR",
  "uk",
  "uk-UA",
  "vi",
  "vi-VN",
  "zh",
  "zh-CN",
  "zh-TW",
]

// for testing purposes, speed this up by having just 2 locales
if (process.env.NEXT_PUBLIC_IS_TESTING) {
  locales = ["en", "tr"]
}

if (["alpha", "beta"].includes(process.env.NEXT_PUBLIC_APP_ENV)) {
  // support only some locales on alpha so the generation does not take forever
  locales = [
    "en",
    "tr",
    "tr-TR",
    "ru",
    "ru-RU",
    "uk",
    "uk-UA",
    "cs",
    "cs-CZ",
    "pt",
    "pt-PT",
    "pt-BR",
    "id",
    "id-ID",
  ]
}

module.exports = locales
