import { Locale, LocaleRoute } from "@hornet-web-react/core/types/session"

/**
 *
 * @param deviceLocale
 * @param appDefaultLocale
 * @returns string Either empty (for default locale or failure) or prefixed with /, like "/uk"
 */
export const getLocaleRoute = (
  deviceLocale: string,
  appDefaultLocale: Locale
) => {
  const safeLocale = Locale.safeParse(deviceLocale)

  if (safeLocale.success) {
    const safeLocaleRoute = LocaleRoute.safeParse(
      safeLocale.data === appDefaultLocale ? "" : `/${safeLocale.data}`
    )

    if (safeLocaleRoute.success) {
      return safeLocaleRoute.data
    }

    // shouldn't really happen, but just in case we'd at least see it in the logs
    console.error(safeLocaleRoute.error)
    return ""
  }

  return ""
}

export const buildLocaleRouteUrl = (
  deviceLocale: string,
  appDefaultLocale: Locale,
  baseUrl: string,
  path: string,
  search: string
): URL => {
  const localeRoute = getLocaleRoute(deviceLocale, appDefaultLocale)

  const queryString = !search || search === "?" ? "" : search

  // for landing page, we don't want to end with trailing slash, so
  // hornetx.com/uk/ should be hornetx.com/uk
  // and hornetx.com/de should be hornetx.com
  if (path === "/") {
    return new URL(`${localeRoute ? localeRoute : "/"}${queryString}`, baseUrl)
  }

  return new URL(`${localeRoute}${path}${queryString}`, baseUrl)
}
