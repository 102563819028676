import { FC, ReactNode, useContext, useEffect } from "react"
import { useAppStart } from "@hornet-web-react/core/hooks/use-app-start"
import NiceModal from "@ebay/nice-modal-react"
import FlashMessageModal from "../Modals/FlashMessageModal"
import { useRouter } from "next/router"
import { useServiceWorker } from "@hornet-web-react/core/hooks/use-service-worker"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { AppConfig } from "@hornet-web-react/core/services/AppConfig"

type AppStartProps = {
  children: ReactNode
  appConfig: AppConfig
  hasPushNotifications?: boolean
  hasServiceWorker?: boolean
}

const AppStart: FC<AppStartProps> = ({
  children,
  appConfig,
  hasPushNotifications = false,
  hasServiceWorker = true,
}) => {
  const { isAuthenticated } = useSessionUser()

  useAppStart(appConfig)
  useServiceWorker(
    { hasPushNotifications, isEnabled: hasServiceWorker },
    isAuthenticated
  )

  const modals = useContext(NiceModal.NiceModalContext)
  const router = useRouter()

  NiceModal.register("FlashMessageModal", FlashMessageModal)

  // close all modals on page transitions
  useEffect(() => {
    const handleRouteChange = () => {
      Object.keys(modals).forEach((modalId) => {
        void NiceModal.hide(modalId)
      })
    }

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [modals, router])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default AppStart
