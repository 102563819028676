import { FC, useEffect, useState } from "react"
import { BellIcon } from "@heroicons/react/24/outline"
import styled from "styled-components"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { useNavigation } from "../../../hooks/use-navigation"
import { useSessionTotals } from "@hornet-web-react/core/hooks/use-session-totals"
import OrangeDot from "@hornet-web-react/core/components/UI/OrangeDot"
import { useNotifications } from "../../../hooks/use-notifications"

type NotificationsButtonProps = {
  className?: string
}

const NotificationsButton: FC<NotificationsButtonProps> = ({ className }) => {
  const { navigateToNotifications } = useNavigation()
  const { data: sessionTotals, isLoading } = useSessionTotals()
  const { notificationsHead } = useNotifications()
  const [isOrangeDot, setIsOrangeDot] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      setIsOrangeDot(
        notificationsHead !== "" &&
          notificationsHead !== sessionTotals?.notifications_head
      )
    }
  }, [notificationsHead, sessionTotals?.notifications_head, isLoading])

  return (
    <Button
      size={40}
      type={"button"}
      onClick={() => navigateToNotifications()}
      className={className}
      aria-label={"Notifications"}
    >
      {isOrangeDot && <UnreadOrangeDot />}
      <BellIcon height={24} />
    </Button>
  )
}

export default NotificationsButton

const Button = styled(IconButton)`
  position: relative;
  color: ${({ theme }) => theme.color.text.secondary};
`

const UnreadOrangeDot = styled(OrangeDot)`
  position: absolute;
  top: 5px;
  right: 8px;
`
