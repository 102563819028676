import "@abraham/reflection"
import { SessionDataProvider } from "@hornet-web-react/core/contexts/session"
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import GlobalStyle from "../styles/app"
import SWRConfigWrapper from "@hornet-web-react/core/components/Utils/SWRConfigWrapper"
import { ServicesProvider } from "@hornet-web-react/core/contexts/services"
import createContainer from "../services/container"
import PageError from "../components/Pages/PageError"
import ErrorBoundary from "@hornet-web-react/core/components/Utils/ErrorBoundary"
import { isClient } from "@hornet-web-react/core/utils"
import appWithI18n from "next-translate/appWithI18n"
import i18nConfig from "../../i18n"
import appConfig from "../app-config"
import { getReq } from "../utils/od"
import NiceModal from "@ebay/nice-modal-react"
import { ProfileVerificationProvider } from "@hornet-web-react/core/contexts/profile-verification-context"
import ProfileVerification from "../components/ProfileVerification/ProfileVerification"

// @harry: not sure if this is the right place to import css
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import "yet-another-react-lightbox/styles.css"
import Layout from "../components/Layout/Layout"
import HornetAppStart from "../components/HornetAppStart"

// we want to have a singleton container on client
let serviceContainer
if (isClient) {
  serviceContainer = createContainer()
}

// DEPRECATED in favour of Sentry performance
export function reportWebVitals(metric) {
  if (appConfig.environment !== "production") {
    console.log(`${metric.name}: ${metric.value.toFixed(1)}ms`)
  }
  // const googleAnalyticsService = servicesContainer.get<GoogleAnalyticsService>(
  //   TYPES.GoogleAnalyticsService
  // )
  //
  // googleAnalyticsService.trackTiming({ id, name, label, value })
}

const HornetApp = ({ Component, pageProps }) => {
  const sessionDataProviderInitialData = pageProps.session

  const content = pageProps.error ? (
    <Layout title={pageProps.error.message}>
      <PageError
        statusCode={pageProps.error.statusCode}
        message={pageProps.error.message}
      />
    </Layout>
  ) : (
    <Component {...pageProps} />
  )

  const [req1, req2] = getReq(appConfig.version, pageProps.rt)

  // HACK: for special comparison SSO page in non-prod, we want to render
  // as least stuff as possible
  if (
    appConfig.environment !== "production" &&
    Component.displayName === "PreviewSSOPage"
  ) {
    return <>{content}</>
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SessionDataProvider
        initialData={sessionDataProviderInitialData}
        appConfig={appConfig}
      >
        <ProfileVerificationProvider>
          <ServicesProvider
            container={serviceContainer}
            createContainer={createContainer}
            req1={req1}
            req2={req2}
          >
            <SWRConfigWrapper fallback={pageProps?.fallback}>
              <NiceModal.Provider>
                <ErrorBoundary errorElement={<PageError />}>
                  <HornetAppStart>
                    <ProfileVerification>{content}</ProfileVerification>
                  </HornetAppStart>
                </ErrorBoundary>
              </NiceModal.Provider>
            </SWRConfigWrapper>
          </ServicesProvider>
        </ProfileVerificationProvider>
      </SessionDataProvider>
    </ThemeProvider>
  )
}

HornetApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  return { pageProps }
}

export default appWithI18n(HornetApp, {
  ...i18nConfig,
  loadLocaleFrom: (lang, ns) => {
    switch (ns) {
      case "entitlement_shop":
      case "shop":
      case "core":
      case "login":
      case "chat":
      case "profile":
        return import(`../../../../locales/${lang}/${ns}.json`).then(
          (m) => m.default
        )
    }

    return import(`../../locales/${lang}/${ns}.json`).then((m) => m.default)
  },
})
