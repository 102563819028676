import CustomApiError from "./CustomApiError"

export class CustomStripeError extends CustomApiError {
  type = ""
  declineCode = ""

  constructor(message: string, type?: string, declineCode?: string) {
    super(message)

    this.type = type || ""
    this.declineCode = declineCode || ""
  }
}
