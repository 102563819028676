import { isClient } from "./run-on-client-only"

declare global {
  interface Window {
    Navigator: {
      standalone: boolean
    }
  }
}

export function isInstalledAsPwa(): boolean {
  if (!isClient) {
    return false
  }

  return (
    window.Navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches
  )
}
