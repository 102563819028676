type SessionQueryParamsState = Record<string, string>

const SESSION_STORAGE_KEY = "hrnt_session_qp"

export const getSessionStorageData = (): SessionQueryParamsState => {
  try {
    const rawValue = sessionStorage.getItem(SESSION_STORAGE_KEY)

    return rawValue ? JSON.parse(rawValue) : {}
  } catch (error) {
    return {}
  }
}

export const setSessionStorageData = (newParams: Record<string, string>) => {
  try {
    const currentData = getSessionStorageData()
    const newSessionQueryParams = {
      ...currentData,
      ...newParams,
    }

    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify(newSessionQueryParams)
    )
  } catch (error) {
    // oops, no session storage here, so let's just keep it in-memory anyway
  }
}
