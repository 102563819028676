export function customPriceFormat(locale: string, amount: number) {
  // HACK: arabic/farsi doesn't use arabic numbers
  if (["ar", "ar-SA", "fa", "fa-IR"].includes(locale)) {
    return String(amount)
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "XYZ",
    minimumFractionDigits: 0,
  })

  // locale based formatting without the actual symbol (`XXX 1.200` -> `1.200`)
  return formatter
    .format(amount || 0)
    .replace(/([^0-9.,\s]+)/, "")
    .trim()
}

// TODO: currency can be typed as @hornet-web-react/shop/types/Currency
export function priceFormat(
  locale: string,
  amount: number,
  currency: string,
  fractionDigits = 2
) {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    // hack to allow multiple EUR prices
    currency: currency === "eua" ? "eur" : currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })

  return formatter.format(amount || 0)
}
