// HACK: importing this causes errors for Edge runtime in middleware
// import { ScopeContext } from "@sentry/types"
import { debug } from "@hornet-web-react/core/utils"
import { type AppConfig } from "./AppConfig"
import { DeviceId } from "@hornet-web-react/core/types/session"
import { ZodError } from "zod"

// HACK: so just copy over what we need
// type LoggingContext = Partial<ScopeContext>
type LoggingContext = {
  extra?: Record<string, unknown>
}

export interface LoggerServiceContext {
  locale: string
  deviceId: DeviceId
  profileId: string | null
}

export type LogMessageWithSentry = (
  message: string,
  context?: LoggingContext
) => void
export type LogExceptionWithSentry = (
  error: Error,
  context?: LoggingContext
) => void
export type CreateLoggingContext = (context: object) => LoggingContext

class LoggerService {
  private readonly _appConfig: AppConfig
  private _context: LoggerServiceContext

  constructor(appConfig: AppConfig, context: LoggerServiceContext) {
    debug(`LoggerService: constructor`)

    this._appConfig = appConfig
    this._context = context
  }

  get isSentryEnabled() {
    return this._appConfig.sentry.isEnabled
  }

  updateContext(context: LoggerServiceContext) {
    this._context = context
  }

  createLoggingContext(context: object): LoggingContext {
    return {
      extra: {
        ...context,
      },
    }
  }

  logMessageWithSentry(message: string, context: LoggingContext = {}) {
    debug(`LoggerService: logMessageWithSentry(${message}, ...)`)

    if (this.isSentryEnabled) {
      const logAction = async () => {
        const { captureMessage, withScope } = await import("@sentry/nextjs")

        withScope((scope) => {
          scope.setTag("profile_id", this._context.profileId)
          scope.setTag("device_identifier", this._context.deviceId)
          scope.setTag("app_locale", this._context.locale)
          scope.setTag("web_app", "web_react_v1")

          captureMessage(message, context)
        })
      }

      logAction().catch(() => {
        // oops, no logging, probs quota exceeded
      })
    } else {
      // eslint-disable-next-line
      console.log("Logger@logMessageWithSentry", message, context)
    }
  }

  logExceptionWithSentry(error: Error, context: LoggingContext = {}) {
    debug(`LoggerService: logExceptionWithSentry(${error.message}, ...)`)

    if (error instanceof ZodError) {
      context = {
        ...(context ?? {}),
        extra: {
          ...(context?.extra ?? {}),
          zodIssues: JSON.stringify(error.issues),
        },
      }
    }

    if (this.isSentryEnabled) {
      const logAction = async () => {
        const { captureException, withScope } = await import("@sentry/nextjs")

        withScope((scope) => {
          scope.setTag("profile_id", this._context.profileId)
          scope.setTag("device_identifier", this._context.deviceId)
          scope.setTag("app_locale", this._context.locale)
          scope.setTag("web_app", "web_react_v1")

          captureException(error, context)
        })
      }

      logAction().catch(() => {
        // oops, no logging, probs quota exceeded
      })
    } else {
      // eslint-disable-next-line
      console.error("Logger@logExceptionWithSentry", error, context)
    }
  }
}

export default LoggerService
