import styled, { css } from "styled-components"
import { FormInputStyles } from "./Input.styles"
import DropdownArrowIcon from "../Icons/DropdownArrowIcon"

export {
  Label,
  InputContainer,
  AppendText,
  FormError,
  FormInfo,
  InfoIcon,
} from "./Input.styles"

export const Background = styled.div`
  position: relative;
  padding: ${({ theme }) => theme.spacing.half}
    ${({ theme }) => theme.spacing.less};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: ${({ theme }) => theme.spacing.half}
      ${({ theme }) => theme.spacing.regular};
  }
`

type WrapperProps = {
  isDisabled?: boolean
}

const disabledWrapperStyle = css`
  background: ${({ theme }) => theme.color.tint.disabled};
`

export const Wrapper = styled.div<WrapperProps>`
  background: ${({ theme }) => theme.color.bg.light01};
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  ${(props) => props.isDisabled && disabledWrapperStyle}
`

export const SelectContainer = styled.div`
  display: block;
  max-width: 100%;
  position: relative;
`

export const SelectArrowIcon = styled(DropdownArrowIcon)`
  position: absolute;
  top: 3px;
  right: 8px;
  pointer-events: none;
`

export const FormSelect = styled.select`
  ${FormInputStyles};
  // ${({ theme }) => theme.font.bold.body};
  // color: ${({ theme }) => theme.color.tint.light01};
  border: 0;
  padding-right: 46px;

  &:disabled {
    color: ${({ theme }) => theme.color.text.tertiary};
  }
`
