import { Middleware, SWRConfig, SWRHook } from "swr"
import { FC, ReactNode, useCallback, useEffect, useRef } from "react"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpointType } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"
import ForbiddenError from "@hornet-web-react/core/services/API/Errors/ForbiddenError"
import RequireSmsVerificationError from "@hornet-web-react/core/services/API/Errors/RequireSmsVerificationError"
import { useProfileVerification } from "@hornet-web-react/core/hooks/use-profile-verification"
import ProfileVerificationError from "@hornet-web-react/core/services/API/Errors/ProfileVerificationError"
import RequireRecaptchaVerificationError from "@hornet-web-react/core/services/API/Errors/RequireRecaptchaVerificationError"
import RequireIpQualityVerificationError from "@hornet-web-react/core/services/API/Errors/RequireIpQualityVerificationError"

interface SWRConfigWrapperProps {
  children: ReactNode
  fallback?: object
}

const keepPreviousResultMiddleware: Middleware =
  (useSWRNext: SWRHook) => (key, fetcher, config) => {
    // Use a ref to store previous returned data.
    const laggyDataRef = useRef<unknown>()

    // Actual SWR hook.
    const swr = useSWRNext(key, fetcher, config)

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data
      }
    }, [swr.data])

    // Expose a method to clear the laggy data, if any.
    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined
    }, [])

    // Fallback to previous data if the current data is undefined.
    const dataOrLaggyData =
      swr.data === undefined ? laggyDataRef.current : swr.data

    // Is it showing previous data?
    const isLagging =
      swr.data === undefined && laggyDataRef.current !== undefined

    // Also add a `isLagging` field to SWR.
    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    })
  }

const SWRConfigWrapper: FC<SWRConfigWrapperProps> = ({
  children,
  fallback = {},
}) => {
  // TODO: `useApi` hook instead eventually
  // const { makeApiRequest } = useApi()
  const apiService = useCoreService<ApiService>(CORE_TYPES.ApiService)
  const communityApiService = useCoreService<ApiService>(
    CORE_TYPES.CommunityApiService
  )
  const { verifyWithSms, verifyWithRecaptcha, redirectToVerifyWithIpQuality } =
    useProfileVerification()

  const {
    user: { logout },
  } = useSessionApi()

  const swrOptions = {
    fallback,
    fetcher: (endpoint: string | ApiServiceEndpointType) => {
      if (typeof endpoint === "string") {
        // HACK: use community api service for cmnty|shop routes
        if (endpoint.match(/^shop:\/\//) || endpoint.match(/^cmnty:\/\//)) {
          return communityApiService.get(endpoint)
        }

        // also no way to use the `makeApiRequest` because it only works with
        // the endpoint objects
        return apiService.get(endpoint)
      }

      if (["community", "shop"].includes(endpoint.api)) {
        return communityApiService.useEndpoint(endpoint)
      }

      // not easy to use `makeApiRequest` here because then any SWR hooks
      // receive the Either and they expect error instead
      // TODO: return makeApiRequest(endpoint)

      return apiService.useEndpoint(endpoint)
    },
    use: [keepPreviousResultMiddleware],
    revalidateIfStale: false,
    onError: (err: Error | unknown) => {
      // handle profile verification flow
      if (err instanceof RequireSmsVerificationError) {
        void verifyWithSms()
        return
      }

      if (err instanceof RequireRecaptchaVerificationError) {
        void verifyWithRecaptcha()
        return
      }

      if (err instanceof RequireIpQualityVerificationError) {
        void redirectToVerifyWithIpQuality()
        return
      }

      if (err instanceof ForbiddenError) {
        logout()
        // hard refresh to clean up any SWR cache etc
        window.location.href = "/"
      }
    },
    shouldRetryOnError: (error: any) => {
      if (error.status === 404) {
        return false
      }

      if (error instanceof ProfileVerificationError) {
        return false
      }

      return true
    },
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
  }

  return <SWRConfig value={swrOptions}>{children}</SWRConfig>
}

export default SWRConfigWrapper
