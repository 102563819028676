import CustomApiError from "./CustomApiError"

export default class TeapotMessageError extends CustomApiError {
  override status = 0
  title: string
  delta: number

  constructor({
    title,
    message,
    t = 0,
  }: {
    title: string
    message: string
    t?: number
  }) {
    super(message)

    this.title = title
    this.message = message
    this.delta = t || 0
  }

  get teapotTitle() {
    return this.title
  }

  get teapotMessage() {
    return this.message
  }

  get teapotDelta() {
    return this.delta
  }
}
