import { z } from "zod"

export const MemberPhotoApiPayload = z.object({
  photo: z.object({
    id: z.coerce.string(),
    state: z.string(),
    slot: z.number(),
    is_public: z.boolean(),
    url: z.string(),
    full_url: z.string(),
    full_large_url: z.string(),
    thumbnail_url: z.string(),
    thumbnail_large_url: z.string(),
    square_url: z.string(),
    v6_full_url: z.string(),
    dick_verified: z.boolean().optional(),
  }),
})
export type MemberPhotoApiPayload = z.infer<typeof MemberPhotoApiPayload>

export const MemberPhotoCreateApiPayload = z.object({
  photo: z.object({
    id: z.coerce.string(),
    state: z.string(),
    slot: z.number(),
    public: z.boolean(),
    photo: z.object({
      cropped_retina: z.object({ url: z.string().url() }),
      cropped_noretina: z.object({ url: z.string().url() }),
      square_retina: z.object({ url: z.string().url() }),
      thumbnail_noretina: z.object({ url: z.string().url() }),
      thumbnail_retina: z.object({ url: z.string().url() }),
      v6_cropped_retina: z.object({ url: z.string().url() }),
    }),
  }),
})

export type MemberPhotoCreateApiPayload = z.infer<
  typeof MemberPhotoCreateApiPayload
>

export function createMemberPhotoModel(payload: MemberPhotoApiPayload) {
  return new MemberPhotoModel(payload)
}

export default class MemberPhotoModel {
  private readonly photo: MemberPhotoApiPayload["photo"]

  constructor(payload: MemberPhotoApiPayload) {
    this.photo = payload.photo
  }

  get id() {
    return this.photo.id
  }

  get isDefault() {
    return this.photo.id.indexOf("default") !== -1
  }

  get fullUrl() {
    if (this.photo.v6_full_url) {
      return this.photo.v6_full_url
    }

    if (this.photo.full_large_url) {
      return this.photo.full_large_url
    }

    if (this.photo.full_url) {
      return this.photo.full_url
    }

    return this.photo.url
  }

  get thumbnailUrl() {
    return this.photo.thumbnail_url
  }

  get thumbnailLargeUrl() {
    return this.photo.thumbnail_large_url
  }

  get squareUrl() {
    return this.photo.square_url
  }

  get isPublic() {
    return this.photo.is_public
  }

  get slot() {
    return this.photo.slot
  }

  get isVerifiedDick() {
    return this.photo.dick_verified === true
  }

  serialize() {
    return {
      photo: {
        ...this.photo,
      },
    }
  }

  setAsProfilePhoto() {
    return new MemberPhotoModel({
      photo: {
        ...this.photo,
        slot: 0,
      },
    })
  }

  changeSlot(slot: number) {
    return new MemberPhotoModel({
      photo: {
        ...this.photo,
        slot,
      },
    })
  }

  toggleStatus(newSlot: number) {
    return new MemberPhotoModel({
      photo: {
        ...this.photo,
        is_public: !this.isPublic,
        slot: newSlot,
      },
    })
  }
}
