export const UNKNOWN_DEVICE_ID = "unknown"
export const UNKNOWN_USER_AGENT = "unknown"
export const UNKNOWN_USER_COUNTRY = "unknown"
export const DEFAULT_USER_COUNTRY = "us"

export const UTM_SOURCE = {
  HOME_TOP: "web-htop",
  TOP_NAV: "web-topnav",
  EVENT_PAGE: "web-showevent",
  POST_PAGE: "web-showpost",
  SPACE_PAGE: "web-showspace",
  SOFT_PAYWALL: "web-softpaywall",
}

// TODO: remove eventually (in favour of the enum
export const PLATFORM = {
  IOS: "ios",
  ANDROID: "android",
}

export enum Platform {
  ios = "ios",
  android = "android",
}
