import {
  EffectCallback,
  DependencyList,
  useLayoutEffect,
  useState,
  useEffect,
  useMemo,
} from "react"

// lib/canUseDOM
const canUseDOM = (): boolean => {
  return (
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
  )
}

// lib/useIsomorphicLayoutEffect
function useIsomorphicLayoutEffect(
  fn: EffectCallback,
  deps: DependencyList
): void {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return canUseDOM() ? useLayoutEffect(fn, deps) : useEffect(fn, deps)
}

let isClientEnv = false
let id = 0

function generateId(prefix: string) {
  return `${prefix}${++id}`
}

export function useUniqueId(prefix = ""): string | undefined {
  const idRef = useMemo(
    () => (isClientEnv ? generateId(prefix) : undefined),
    []
  )
  const [id, setId] = useState(idRef)

  useIsomorphicLayoutEffect(() => {
    if (id === undefined) {
      setId(generateId(prefix))
    }
  }, [])

  useEffect(() => {
    if (!isClientEnv) {
      isClientEnv = true
    }
  }, [])

  return id
}
