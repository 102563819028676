import { z } from "zod"

export const LookupDataItem = z.object({
  id: z.coerce.string(),
  title: z.string(),
})
export type LookupDataItem = z.infer<typeof LookupDataItem>

export const LookupDataApiPayload = z.object({
  pronouns: z.array(z.object({ pronoun: LookupDataItem })),
  unit_of_measures: z.array(z.object({ unit_of_measure: LookupDataItem })),
  relationships: z.array(z.object({ relationship: LookupDataItem })),
  sexualities: z.array(z.object({ sexuality: LookupDataItem })),
  genders: z.array(z.object({ gender: LookupDataItem })),
  hiv_statuses: z.array(z.object({ hiv_status: LookupDataItem })),
  report_reasons: z.array(z.object({ report_reason: LookupDataItem })),
  ethnicities: z.array(z.object({ ethnicity: LookupDataItem })),
  identities: z.array(z.object({ identity: LookupDataItem })),
  looking_fors: z.array(z.object({ looking_for: LookupDataItem })),
})
export type LookupDataApiPayload = z.infer<typeof LookupDataApiPayload>

const LookupData = z.object({
  pronouns: z.array(LookupDataItem),
  unit_of_measures: z.array(LookupDataItem),
  relationships: z.array(LookupDataItem),
  sexualities: z.array(LookupDataItem),
  genders: z.array(LookupDataItem),
  hiv_statuses: z.array(LookupDataItem),
  report_reasons: z.array(LookupDataItem),
  ethnicities: z.array(LookupDataItem),
  identities: z.array(LookupDataItem),
  looking_fors: z.array(LookupDataItem),
})
type LookupData = z.infer<typeof LookupData>

export class LookupDataModel {
  private readonly data: LookupData

  constructor(private readonly payload: LookupDataApiPayload) {
    this.data = {
      pronouns: payload.pronouns.map((item) => item.pronoun),
      unit_of_measures: payload.unit_of_measures.map(
        (item) => item.unit_of_measure
      ),
      relationships: payload.relationships.map((item) => item.relationship),
      sexualities: payload.sexualities.map((item) => item.sexuality),
      genders: payload.genders.map((item) => item.gender),
      hiv_statuses: payload.hiv_statuses.map((item) => item.hiv_status),
      report_reasons: payload.report_reasons.map((item) => item.report_reason),
      ethnicities: payload.ethnicities.map((item) => item.ethnicity),
      identities: payload.identities.map((item) => item.identity),
      looking_fors: payload.looking_fors.map((item) => item.looking_for),
    }
  }

  lookupItemTitle(
    lookupKey: keyof LookupData,
    lookupId?: string,
    defaultValue = ""
  ) {
    const item = this.lookupItem(lookupKey, lookupId)

    if (item) {
      return item.title
    }

    return defaultValue
  }

  lookupItem(lookupKey: keyof LookupData, lookupId?: string) {
    if (!lookupId) {
      return undefined
    }

    const lookup = this.data[lookupKey]

    if (lookup) {
      const item = lookup.filter((item) => item.id === lookupId)

      return item.length > 0 ? item[0] : undefined
    }

    return undefined
  }

  get reportReasons() {
    return this.data.report_reasons
  }

  get pronouns() {
    return this.data.pronouns
  }

  get genders() {
    return this.data.genders
  }

  get sexualities() {
    return this.data.sexualities
  }

  get ethnicities() {
    return this.data.ethnicities
  }

  get unitsOfMeasure() {
    return this.data.unit_of_measures
  }

  get identities() {
    return this.data.identities
  }

  get relationships() {
    return this.data.relationships
  }

  get hivStatuses() {
    return this.data.hiv_statuses
  }

  get lookingFor() {
    return this.data.looking_fors
  }
}
