import { FC } from "react"
import { FaCrown } from "react-icons/fa"
import styled from "styled-components"

const Wrapper = styled.span<{
  size: number
}>`
  display: flex;
  background: #f5a623;
  width: ${(props) => `${props.size + 9}px`};
  min-width: ${(props) => `${props.size + 9}px`};
  height: ${(props) => `${props.size + 9}px`};
  border-radius: ${(props) => `${props.size + 9}px`};
  align-items: center;
  justify-content: center;
`

type PremiumIconProps = {
  className?: string
  size?: number
  crownSize?: number
}

const PremiumIcon: FC<PremiumIconProps> = ({ size, crownSize, className }) => {
  return (
    <Wrapper size={size || 15} className={className}>
      <FaCrown color={"#fff"} size={crownSize || size || 15} />
    </Wrapper>
  )
}

export default PremiumIcon
