import styled, { css } from "styled-components"
import { PropsWithChildren } from "react"
import Link from "next/link"
import Spinner from "./Spinner"
import { CheckIcon } from "@heroicons/react/24/solid"

export const smallSizeStyle = css`
  font-size: 14px;
  font-weight: 500;
  min-height: 36px;
  height: 36px;
  line-height: 27px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
`

export const ButtonStyle = css<{
  size?: string
}>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 36px;
  position: relative;

  max-width: 100%;
  height: 46px;
  min-width: auto;

  background: ${({ theme }) => theme.color.bg.light01};
  color: ${({ theme }) => theme.color.text.primary};
  border: 2px solid;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-family: ${({ theme }) => theme.font.family};
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  ${(props) => props.size === "small" && smallSizeStyle};
`

// define button basics
export const Button = styled.button<{
  size?: string
}>`
  ${ButtonStyle};
`

export const ButtonLink = styled(Link)<{
  size?: string
}>`
  ${ButtonStyle};
`

// define Default (Secondary) button colors
export const DefaultButtonStyle = css`
  background: ${({ theme }) => theme.color.bg.light01};
  color: ${({ theme }) => theme.color.tint.default};
  border: 2px solid ${({ theme }) => theme.color.tint.default};

  a {
    color: ${({ theme }) => theme.color.tint.default};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.tint.pressed};
    border-color: ${({ theme }) => theme.color.tint.pressed};
  }

  &:hover a,
  &:focus a {
    color: ${({ theme }) => theme.color.tint.pressed};
  }

  &[disabled] {
    color: ${({ theme }) => theme.color.tint.disabled};
    border-color: ${({ theme }) => theme.color.tint.disabled};
  }

  &[disabled] a {
    color: ${({ theme }) => theme.color.tint.disabled};
  }
`

export const DefaultButton = styled(Button)`
  ${DefaultButtonStyle};
`

export const DefaultButtonLink = styled(ButtonLink)`
  ${DefaultButtonStyle};
`

export const BlackButtonStyle = css`
  background: ${({ theme }) => theme.color.bg.light01};
  color: ${({ theme }) => theme.color.text.secondary};
  border: 2px solid ${({ theme }) => theme.color.text.secondary};

  a {
    color: ${({ theme }) => theme.color.text.secondary};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.text.secondary};
    border-color: ${({ theme }) => theme.color.text.secondary};
  }

  &:hover a,
  &:focus a {
    color: ${({ theme }) => theme.color.text.secondary};
  }

  &[disabled] {
    color: ${({ theme }) => theme.color.tint.disabled};
    border-color: ${({ theme }) => theme.color.tint.disabled};
  }

  &[disabled] a {
    color: ${({ theme }) => theme.color.tint.disabled};
  }
`

// define Black button colors
export const BlackButton = styled(Button)`
  ${BlackButtonStyle};
`

export const PrimaryButtonStyle = css`
  background: ${({ theme }) => theme.color.tint.default};
  color: ${({ theme }) => theme.color.text.white};
  border-color: ${({ theme }) => theme.color.tint.default};

  a {
    color: #ffffff;
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.color.tint.pressed};
    border-color: ${({ theme }) => theme.color.tint.pressed};
  }

  &[disabled] {
    background: ${({ theme }) => theme.color.tint.disabled};
    border-color: ${({ theme }) => theme.color.tint.disabled};
    color: ${({ theme }) => theme.color.text.disabled};
  }
`

// define Primary button colors
export const PrimaryButton = styled(Button)`
  ${PrimaryButtonStyle};
`

export const PrimaryButtonLink = styled(ButtonLink)`
  ${PrimaryButtonStyle};
`

const LinkButton = styled(DefaultButton)`
  border: 0;
`

type TextButtonType = "button" | "submit" | "reset" | undefined

type TextButtonProps = PropsWithChildren & {
  type?: TextButtonType
  size?: "default" | "small"
  primary?: boolean
  link?: boolean
  disabled?: boolean
  id?: string
  className?: string
  onClick?: () => void
  style?: object
  isLoading?: boolean
  isDone?: boolean
  doneLabel?: string
}

export default function TextButton(props: TextButtonProps) {
  const { type, primary, link, children, size, disabled, ...buttonProps } =
    props

  // make `button` the default type
  const typeProp = type === "submit" ? type : ("button" as TextButtonType)
  const sizeProp = size && ["small"].includes(size) ? size : "default"

  const finalProps = {
    type: typeProp,
    size: sizeProp,
    ...buttonProps,
    disabled: !!disabled || !!props.isLoading || !!props.isDone,
  }

  const childrenWrap = (
    <>
      <ButtonInnerContentLabel
        isVisible={!props.isLoading && !props.isDone}
        size={sizeProp}
      >
        {children}
      </ButtonInnerContentLabel>

      {props.isLoading === true ? (
        <ButtonInnerContentSpinner>
          <ButtonInnerSpinner size={"auto"} />
        </ButtonInnerContentSpinner>
      ) : null}

      {!props.isLoading && props.isDone ? (
        <ButtonInnerContentSpinner>
          <CheckIcon width={24} />
          {props.doneLabel !== undefined ? props.doneLabel : null}
        </ButtonInnerContentSpinner>
      ) : null}
    </>
  )

  if (primary) {
    return <PrimaryButton {...finalProps}>{childrenWrap}</PrimaryButton>
  }

  if (link) {
    return <LinkButton {...finalProps}>{childrenWrap}</LinkButton>
  }

  return <DefaultButton {...finalProps}>{childrenWrap}</DefaultButton>
}

TextButton.defaultProps = {
  type: "button",
  primary: false,
}

const ButtonInnerContentSpinner = styled.span`
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonInnerContentLabel = styled.span<{
  isVisible: boolean
  size: string
}>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  min-height: ${({ size }) => (size === "small" ? "27px" : "22px")};
`

const ButtonInnerSpinner = styled(Spinner)``
