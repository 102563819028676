import styled, { css } from "styled-components"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

export const Wrapper = styled.div`
  position: relative;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

export const Background = styled.div`
  background: ${({ theme }) => theme.color.bg.light01};
  padding: ${({ theme }) => theme.spacing.half} ${({ theme }) =>
  theme.spacing.less};
  border-left: 2px solid ${(props) =>
    props.hasError
      ? props.theme.color.text.error
      : props.theme.color.tint.default};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: ${({ theme }) => theme.spacing.half} ${({ theme }) =>
  theme.spacing.regular};
  }
}
`

const isRequiredLabelStyle = css`
  &:after {
    content: " *";
    color: red;
  }
`

export const FormInputStyles = css`
  ${({ theme }) => theme.font.regular.body}
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.color.text.primary};
  font-family: ${({ theme }) => theme.font.family};
  appearance: none;
  border: 0;
  background: transparent;
  flex: 1;
  padding: 2px 1px;
  resize: none;

  &::-webkit-date-and-time-value {
    text-align: left !important;
  }

  &:focus {
    outline: 1px none ${({ theme }) => theme.color.text.tertiary};
  }
`

export const Label = styled.label`
  ${({ theme }) => theme.font.regular.caption1}
  display: block;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 2px;

  ${(props) => props.isRequired && isRequiredLabelStyle}
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 27px;
`

export const FormInput = styled.input`
  ${FormInputStyles};
`

export const AppendText = styled.div`
  padding-bottom: 2px;
`

export const FormError = styled.div`
  ${({ theme }) => theme.font.regular.caption1};
  color: ${({ theme }) => theme.color.text.error};
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.less};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.less};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0 ${({ theme }) => theme.spacing.regular};
  }
`

export const FormInfo = styled(FormError)`
  color: ${({ theme }) => theme.color.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
`

export const InfoIcon = styled(InformationCircleIcon)`
  width: 18px;
  height: 18px;
  margin-right: 3px;
`

export const ErrorIcon = styled(ExclamationCircleIcon)`
  width: 18px;
  height: 18px;
  margin-right: 3px;
`
