import PropTypes from "prop-types"

const ChevronRightIcon = (props: ChevronRightIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "1em"}
      height={props.height || "1em"}
      viewBox="0 0 128 512"
      role="img"
      {...props}
    >
      <title>{props.title}</title>
      <path
        d="M24.707 38.101 4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
        fill={props.color || "#000000"}
      />
    </svg>
  )
}

type ChevronRightIconProps = {
  title: string
  width?: string
  height?: string
  color?: string
}

export default ChevronRightIcon

ChevronRightIcon.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
}
