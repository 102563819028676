const HomeIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 20 22"}
    width={20}
    height={22}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-1 0h22v22H-1z" />
      <path
        stroke="#5A5A5A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.8}
        d="m10 1.833-8.25 8.2v10.134h5.405v-3.155c0-1.555 1.28-2.828 2.845-2.828 1.565 0 2.845 1.273 2.845 2.828v3.155h5.405V10.033L10 1.833Z"
      />
    </g>
  </svg>
)
export default HomeIcon
