import { GridType } from "../types"
import { InboxType } from "@hornet-web-react/chat/models/messages-conversation.model"
import { MouseEvent, useCallback, useMemo } from "react"
import Router from "next/router"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import { useHornetRouter } from "./use-hornet-router"
import { HornetProfileId } from "@hornet-web-react/core/types"

export function useNavigation() {
  const { localeRoute } = useSessionDevice()
  const { parse } = useHornetRouter()

  const routes = useMemo(() => {
    const searchParams =
      typeof window !== "undefined" ? window.location.search.toString() : ""

    return {
      routeForLanding: `${localeRoute ? localeRoute : "/"}${searchParams}`,
      routeForSignup: `${localeRoute}/signup${searchParams}`,
      routeForHome: `${localeRoute}/grids/nearby${searchParams}`,
      routeForAccount: `${localeRoute}/account${searchParams}`,
      routeForNotifications: `${localeRoute}/notifications${searchParams}`,
      routeForOnboarding: `${localeRoute}/onboarding${searchParams}`,
    }
  }, [localeRoute])

  return {
    ...routes,

    navigateToLanding: useCallback(
      () => Router.push(routes.routeForLanding),
      [routes.routeForLanding]
    ),

    navigateToSignup: useCallback(
      () => Router.push(routes.routeForSignup),
      [routes.routeForSignup]
    ),

    navigateHome: useCallback(
      () => Router.push(routes.routeForHome),
      [routes.routeForHome]
    ),

    navigateToAccount: useCallback(
      () => Router.push(routes.routeForAccount),
      [routes.routeForAccount]
    ),

    navigateToNotifications: useCallback(
      () => Router.push(routes.routeForNotifications),
      [routes.routeForNotifications]
    ),

    navigateToProfile: useCallback(
      (username: string, profileId: HornetProfileId) =>
        Router.push(`${localeRoute}/profiles/${username}/${profileId}`),
      [localeRoute]
    ),

    navigateToGridSearchByHashtag: useCallback(
      (hashtagWithoutPound: string) =>
        Router.push(`${localeRoute}/grids/tag/${hashtagWithoutPound}`),
      [localeRoute]
    ),

    navigateToGridSearchByUsername: useCallback(
      (usernameWithoutAt: string) =>
        Router.push(`${localeRoute}/grids/members/${usernameWithoutAt}`),
      [localeRoute]
    ),

    navigateToGridExplore: useCallback(
      (lat: number, lng: number) =>
        Router.push(`${localeRoute}/grids/explore/${lat}/${lng}`),
      [localeRoute]
    ),

    navigateToGrid: useCallback(
      (gridType: GridType) => Router.push(`${localeRoute}/grids/${gridType}`),
      [localeRoute]
    ),

    navigateToInbox: useCallback(
      (inboxType: InboxType) =>
        Router.push(`${localeRoute}/inbox/${inboxType}`),
      [localeRoute]
    ),

    navigateToConversation: useCallback(
      (inboxType: InboxType, conversationId: string) =>
        Router.push(`${localeRoute}/inbox/${inboxType}/${conversationId}`),
      [localeRoute]
    ),

    maybeNavigateToRoute: useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        const url = event.currentTarget.getAttribute("href")
        if (!url) {
          return
        }

        const route = parse(url, localeRoute)

        if (!route) {
          return
        }

        event.stopPropagation()
        event.preventDefault()

        return Router.push(route)
      },
      [parse, localeRoute]
    ),
  }
}
