import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LocalStorageService, {
  StorageKey,
} from "@hornet-web-react/core/services/LocalStorageService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import useSWRImmutable from "swr/immutable"
import { useCallback } from "react"
import { useSWRConfig } from "swr"
import { useEffectOnce } from "react-use"

const SWR_KEY = "/_services/local_storage/feature_flags"

export function usePushNotificationsFlag() {
  const localStorageService = useCoreService<LocalStorageService>(
    CORE_TYPES.LocalStorageService
  )

  const localStorageFetcher = useCallback(
    () =>
      localStorageService.getItem<{ pushNotifications: boolean }>(
        StorageKey.featureFlags
      ),
    [localStorageService]
  )

  const { mutate } = useSWRConfig()
  const { data } = useSWRImmutable(SWR_KEY, localStorageFetcher)

  const setPushNotifications = useCallback(
    (newState: boolean) => {
      localStorageService.setItem(StorageKey.featureFlags, {
        ...localStorageFetcher(),
        pushNotifications: newState,
      })
      void mutate(SWR_KEY)
    },
    [localStorageFetcher, localStorageService, mutate]
  )

  useEffectOnce(() => {
    // make it correct to start with
    if (window.Notification?.permission) {
      // granted or denied can be safely considered as true
      // the default value is "prompt" on iOS even if it's "granted" (after reload)
      if (
        window.Notification.permission === "granted" ||
        window.Notification.permission === "denied"
      ) {
        setPushNotifications(window.Notification.permission === "granted")
      }
    }
  })

  return {
    isEnabled: data?.pushNotifications || false,

    setPushNotifications,
  }
}
