import Head from "next/head"
import { useRouter } from "next/router"
import TopNavBar from "./TopNavBar"
import AppContainer from "./AppContainer"
import Footer from "./Footer"
import appConfig from "../../config"
import ImageSocialMediaThumb from "../../../public/assets/images/social-media-thumb.jpg"
import { useSessionEnvironment } from "@hornet-web-react/core/contexts/session"
import useTranslation from "next-translate/useTranslation"
import { buildDocumentMeta } from "../../utils/document-meta"
import BottomNavBar from "./BottomNavBar"
import { PropsWithChildren } from "react"

type LayoutProps = PropsWithChildren & {
  title: string
  meta?: Record<
    string,
    {
      name: string
      content?: string
      property?: string
    }
  >
  showTopNavBar?: boolean
  showBottomNavBar?: boolean
  showFooter?: boolean
  hideTopNavBarMenu?: boolean
  canonicalUrl?: string | null
  utmCampaign?: string
}

function Layout({
  children,
  title,
  canonicalUrl = null,
  meta = {},
  showTopNavBar = true,
  showBottomNavBar = true,
  showFooter = false,
  hideTopNavBarMenu = false,
  utmCampaign = "index",
}: LayoutProps) {
  const router = useRouter()
  const { isInApp } = useSessionEnvironment()
  const { t } = useTranslation()

  // default title
  title = title
    ? `${title} | ${t("hornet:page_title.app")}`
    : t("hornet:page_title.index")

  // correct usage of App URl -> absolute, immutable `appConfig.appUrl`
  // for canonical - even if user accesses from different domain, there shall
  // be only a single canonical URL domain
  canonicalUrl = canonicalUrl ?? `${appConfig.appUrl}${router.asPath}`

  // build meta
  const metaTags = buildDocumentMeta(
    t,
    appConfig,
    ImageSocialMediaThumb,
    canonicalUrl,
    meta
  ).map((metaObj) => {
    return <meta key={metaObj.id} {...metaObj} />
  })

  showTopNavBar = !isInApp && showTopNavBar
  showBottomNavBar = !isInApp && showBottomNavBar
  showFooter = !isInApp && showFooter

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />

        {metaTags}
      </Head>

      {showTopNavBar && <TopNavBar hideNavBarMenu={hideTopNavBarMenu} />}

      <AppContainer
        hasTopNavBar={showTopNavBar}
        hasBottomNavBar={showBottomNavBar}
      >
        {children}
      </AppContainer>

      {showBottomNavBar && <BottomNavBar utmCampaign={utmCampaign} />}

      {showFooter && <Footer />}
    </>
  )
}

export default Layout
