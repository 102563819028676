import { FC, ForwardedRef, forwardRef, MouseEvent, ReactNode } from "react"
import UnstyledButton from "../UI/UnstyledButton"
import styled, { css } from "styled-components"

export const smallSizeStyle = css`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 36px;
  font-size: 14px;
  font-weight: 500;
  min-height: 36px;
`

const Button = styled(UnstyledButton)<IconButtonProps & { finalSize: string }>`
  background: ${(props) =>
    props.primary
      ? props.disabled
        ? props.theme.color.tint.disabled
        : props.theme.color.tint.default
      : "transparent"};

  color: ${(props) =>
    props.primary
      ? props.theme.color.bg.light01
      : props.theme.color.text.primary};

  display: inline-block;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.finalSize};
  min-width: ${(props) => props.finalSize};
  height: ${(props) => props.finalSize};
  border-radius: ${(props) => props.finalSize};
  border: 0;
  text-align: center;
  position: relative;
  margin: 0;
  padding: 0;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  user-select: none;

  ${(props) => props.size === "small" && smallSizeStyle};
`

type IconButtonProps = {
  type: "button" | "submit" | "reset"
  children: ReactNode
  size?: "small" | "default" | number
  primary?: boolean
  disabled?: boolean
  className?: string
  onClick?: (event: MouseEvent) => void
  ref?: ForwardedRef<HTMLButtonElement>
}

const IconButton: FC<IconButtonProps> = (props) => {
  const { children, size, ...buttonProps } = props

  //
  let finalSize = "44px"
  switch (true) {
    case size === "small": {
      finalSize = "36px"
      break
    }

    case typeof size === "number": {
      finalSize = `${size}px`
      break
    }
  }

  return (
    <Button finalSize={finalSize} size={size} {...buttonProps}>
      {children}
    </Button>
  )
}

export const IconButtonWithRef = forwardRef(
  (props: IconButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { children, ...rest } = props
    return <IconButton {...rest}>{children}</IconButton>
  }
)
IconButtonWithRef.displayName = "IconButtonWrapper"

export default IconButton
