import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import ApiService, {
  ApiEndpointPayload,
} from "@hornet-web-react/core/services/API/ApiService"
import { useCallback } from "react"
import { ApiServiceEndpointType } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { Either, Left, makeLeft, makeRight } from "@hornet-web-react/core/utils"
import CustomApiError from "@hornet-web-react/core/services/API/Errors/CustomApiError"
import ProfileVerificationError from "@hornet-web-react/core/services/API/Errors/ProfileVerificationError"
import RequireEmailVerificationError from "@hornet-web-react/core/services/API/Errors/RequireEmailVerificationError"
import { useProfileVerification } from "./use-profile-verification"
import RequireSmsVerificationError from "@hornet-web-react/core/services/API/Errors/RequireSmsVerificationError"
import RequireRecaptchaVerificationError from "@hornet-web-react/core/services/API/Errors/RequireRecaptchaVerificationError"
import RequireIpQualityVerificationError from "@hornet-web-react/core/services/API/Errors/RequireIpQualityVerificationError"
import NetworkError from "@hornet-web-react/core/services/API/Errors/NetworkError"
import { removeCookie } from "typescript-cookie"
import { CookieName } from "@hornet-web-react/core/types"

export type ApiRequestError =
  | CustomApiError
  | Error
  | { message: string; title?: string }

export type ApiRequestResult<T> = Either<ApiRequestError, T>
type ApiErrorHandler = (error: unknown) => Left<ApiRequestError> | void

/**
 * Use this hook in any client-facing component that needs to make an API request.
 * It triggers profile verification if needed.
 * Do not use in SSR as the verification flow needs to run on the client.
 * In SSR, we defer the verification to the client - usually redirecting to the grid
 * and handling it from there.
 */
export function useApi() {
  const apiService = useCoreService<ApiService>(CORE_TYPES.ApiService)
  const communityApiService = useCoreService<ApiService>(
    CORE_TYPES.CommunityApiService
  )
  const {
    verifyWithEmail,
    verifyWithSms,
    verifyWithRecaptcha,
    redirectToVerifyWithIpQuality,
  } = useProfileVerification()

  return {
    getEndpoint: useCallback(
      (endpoint: ApiServiceEndpointType, params: (string | number)[] = []) => {
        return ApiService.getEndpoint(endpoint, params)
      },
      []
    ),

    getAbortableEndpoint: useCallback(
      (endpoint: ApiServiceEndpointType, params: (string | number)[] = []) => {
        return apiService.useAbortableEndpoint(endpoint, params)
      },
      [apiService]
    ),

    makeApiRequest: useCallback(
      async <T>(
        endpoint: ApiServiceEndpointType,
        payload?: ApiEndpointPayload,
        errorHandler?: ApiErrorHandler
      ): Promise<ApiRequestResult<T>> => {
        try {
          if (["community", "shop"].includes(endpoint.api)) {
            return makeRight(
              await communityApiService.useEndpoint<T>(endpoint, payload)
            )
          }

          return makeRight(await apiService.useEndpoint<T>(endpoint, payload))
        } catch (error) {
          let isProfileVerificationError = false
          if (error instanceof RequireEmailVerificationError) {
            void verifyWithEmail()
            isProfileVerificationError = true
          }

          if (error instanceof RequireSmsVerificationError) {
            void verifyWithSms()
            isProfileVerificationError = true
          }

          if (error instanceof RequireRecaptchaVerificationError) {
            void verifyWithRecaptcha()
            isProfileVerificationError = true
          }

          if (error instanceof RequireIpQualityVerificationError) {
            await redirectToVerifyWithIpQuality()
            isProfileVerificationError = true
          }

          if (isProfileVerificationError) {
            return makeLeft(error as ProfileVerificationError)
          }

          // network error marks website for reload
          if (error instanceof NetworkError) {
            removeCookie(CookieName.IsPwaFresh)
          }

          // only call error handler if the error is not related to profile verification
          if (!isProfileVerificationError && errorHandler) {
            const errorHandlerResult = errorHandler(error)
            if (errorHandlerResult) {
              return errorHandlerResult
            }
          }

          return makeDefaultApiErrorLeft(error)
        }
      },
      [
        apiService,
        communityApiService,
        redirectToVerifyWithIpQuality,
        verifyWithEmail,
        verifyWithRecaptcha,
        verifyWithSms,
      ]
    ),
  }
}

export const makeDefaultApiErrorLeft = (error: unknown) =>
  makeLeft(error instanceof Error ? error : new Error(JSON.stringify(error)))
