import { FC, ReactNode, useCallback } from "react"
import {
  MobileNavigationWrapper,
  MobileNavigationButton,
  MobileNavigationTitle,
  MobileNavigationButtonWrapper,
  MobileNavigationLeftButtonIcon,
  MobileNavigationRightButtonIcon,
  MobileNavigationLink,
  MobileNavigationNextLink,
  MobileNavigationLabel,
} from "./MobileNavigation.styles"
import { useTheme } from "styled-components"
import {
  type NavigationAction,
  NavigationLink,
} from "@hornet-web-react/core/types"

type MobileNavigationProps = {
  leftAction?: ReactNode
  rightAction?: ReactNode
  title?: string | ReactNode
  hasPadding?: boolean
}

export function useMobileNavigation() {
  const theme = useTheme()

  const createNavActionButton = useCallback(
    (action: NavigationAction, position: "left" | "right" = "left") => {
      return (
        <MobileNavigationButton
          onClick={action.onClick}
          isRight={position === "right"}
          isDisabled={action.isDisabled}
          hasChevron={Boolean(action.hasChevron)}
          hasLongTitle={hasLongTitle(action.label)}
        >
          {action.hasChevron && position === "left" && (
            <MobileNavigationLeftButtonIcon
              title={action.label}
              color={theme.color.tint.default}
            />
          )}

          <MobileNavigationLabel>{action.label}</MobileNavigationLabel>

          {action.hasChevron && position === "right" && (
            <MobileNavigationRightButtonIcon
              title={action.label}
              color={theme.color.tint.default}
            />
          )}
        </MobileNavigationButton>
      )
    },
    [theme.color.tint.default]
  )

  const createNavLinkButton = useCallback(
    (action: NavigationLink, position: "left" | "right" = "left") => {
      return (
        <MobileNavigationNextLink
          href={action.href}
          data-test={"mobileNavigationLinkRight"}
          onClick={
            typeof action.onClick === "function" ? action.onClick : undefined
          }
        >
          <MobileNavigationLink
            isRight={position === "right"}
            hasChevron={Boolean(action.hasChevron)}
            hasLongTitle={hasLongTitle(action.label)}
          >
            {action.hasChevron && position === "left" && (
              <MobileNavigationLeftButtonIcon
                title={action.label}
                color={theme.color.tint.default}
              />
            )}

            <MobileNavigationLabel>{action.label}</MobileNavigationLabel>

            {action.hasChevron && position === "right" && (
              <MobileNavigationRightButtonIcon
                title={action.label}
                color={theme.color.tint.default}
              />
            )}
          </MobileNavigationLink>
        </MobileNavigationNextLink>
      )
    },
    [theme.color.tint.default]
  )

  return {
    createNavActionButton,
    createNavLinkButton,
  }
}

const MobileNavigation: FC<MobileNavigationProps> = ({
  title,
  leftAction,
  rightAction,
  hasPadding,
}) => {
  return (
    <MobileNavigationWrapper hasPadding={Boolean(hasPadding)}>
      <MobileNavigationButtonWrapper hasTitle={!!title}>
        {leftAction}
      </MobileNavigationButtonWrapper>

      <MobileNavigationTitle data-test={"mobileNavigationTitle"}>
        {title}
      </MobileNavigationTitle>

      <MobileNavigationButtonWrapper isRight={true} hasTitle={!!title}>
        {rightAction}
      </MobileNavigationButtonWrapper>
    </MobileNavigationWrapper>
  )
}

export default MobileNavigation

const hasLongTitle = (title: string) => {
  return title.length > 10
}
