module.exports = {
  locales: require("./locales.js"),
  defaultLocale: "en",
  pages: {
    "*": [
      "hornet",
      "core",
      "shop",
      "entitlement_shop",
      "login",
      "chat",
      "profile",
    ],
  },
  logBuild: false,
  loader: false,
}
