import { useCallback } from "react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import HornetAnalyticsEvent from "../models/hornet-analytics-event"

export const useEventTrackerService = () => {
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  return {
    reportEvent: useCallback(
      async (analyticsEvent: HornetAnalyticsEvent) =>
        eventTrackerService.report(analyticsEvent),
      [eventTrackerService]
    ),
  }
}
