import head from "ramda/es/head"
import propEq from "ramda/es/propEq"

// countries with currency are assumed to have items with that currency in the Shop
export const COUNTRIES = [
  { title: "United States", id: "us", currency: "usd" },
  { title: "United Kingdom", id: "gb", currency: "gbp" },
  { title: "Ukraine", id: "ua", currency: "uah" },
  { title: "Turkey", id: "tr", currency: "try" },
  { title: "Thailand", id: "th", currency: "thb" },
  { title: "Taiwan", id: "tw", currency: "twd" },
  { title: "Russian Federation", id: "ru", currency: "rub" },
  { title: "Netherlands", id: "nl", currency: "eua", is_eu: true },
  { title: "Indonesia", id: "id", currency: "idr" },
  { title: "Germany", id: "de", currency: "eua", is_eu: true },
  { title: "France", id: "fr", currency: "eur", is_eu: true },
  { title: "Brazil", id: "br", currency: "brl" },
  { title: "Afghanistan", id: "af" },
  { title: "Åland Islands", id: "ax" },
  { title: "Albania", id: "al" },
  { title: "Algeria", id: "dz" },
  { title: "American Samoa", id: "as" },
  { title: "Andorra", id: "ad" },
  { title: "Angola", id: "ao" },
  { title: "Anguilla", id: "ai" },
  { title: "Antarctica", id: "aq" },
  { title: "Antigua and Barbuda", id: "ag" },
  { title: "Argentina", id: "ar" },
  { title: "Armenia", id: "am" },
  { title: "Aruba", id: "aw" },
  { title: "Australia", id: "au", currency: "aud" },
  { title: "Austria", id: "at", currency: "eur", is_eu: true },
  { title: "Azerbaijan", id: "az" },
  { title: "Bahamas", id: "bs" },
  { title: "Bahrain", id: "bh" },
  { title: "Bangladesh", id: "bd" },
  { title: "Barbados", id: "bb" },
  { title: "Belarus", id: "by" },
  { title: "Belgium", id: "be", currency: "eur", is_eu: true },
  { title: "Belize", id: "bz" },
  { title: "Benin", id: "bj" },
  { title: "Bermuda", id: "bm" },
  { title: "Bhutan", id: "bt" },
  { title: "Bolivia", id: "bo" },
  { title: "Bosnia and Herzegovina", id: "ba" },
  { title: "Botswana", id: "bw" },
  { title: "Bouvet Island", id: "bv" },
  { title: "British Indian Ocean Territory", id: "io" },
  { title: "Brunei Darussalam", id: "bn" },
  { title: "Bulgaria", id: "bg", is_eu: true },
  { title: "Burkina Faso", id: "bf" },
  { title: "Burundi", id: "bi" },
  { title: "Cambodia", id: "kh" },
  { title: "Cameroon", id: "cm" },
  { title: "Canada", id: "ca" },
  { title: "Cape Verde", id: "cv" },
  { title: "Cayman Islands", id: "ky" },
  { title: "Central African Republic", id: "cf" },
  { title: "Chad", id: "td" },
  { title: "Chile", id: "cl" },
  { title: "China", id: "cn" },
  { title: "Christmas Island", id: "cx" },
  { title: "Cocos (Keeling) Islands", id: "cc" },
  { title: "Colombia", id: "co" },
  { title: "Comoros", id: "km" },
  { title: "Congo", id: "cg" },
  { title: "Congo, The Democratic Republic of the", id: "cd" },
  { title: "Cook Islands", id: "ck" },
  { title: "Costa Rica", id: "cr" },
  { title: 'Cote D"Ivoire', id: "ci" },
  { title: "Croatia", id: "hr", is_eu: true },
  { title: "Cuba", id: "cu" },
  { title: "Cyprus", id: "cy", currency: "eur", is_eu: true },
  { title: "Czech Republic", id: "cz", is_eu: true },
  { title: "Denmark", id: "dk", is_eu: true },
  { title: "Djibouti", id: "dj" },
  { title: "Dominica", id: "dm" },
  { title: "Dominican Republic", id: "do" },
  { title: "Ecuador", id: "ec" },
  { title: "Egypt", id: "eg" },
  { title: "El Salvador", id: "sv" },
  { title: "Equatorial Guinea", id: "gq" },
  { title: "Eritrea", id: "er" },
  { title: "Estonia", id: "ee", currency: "eur", is_eu: true },
  { title: "Ethiopia", id: "et" },
  { title: "Falkland Islands (Malvinas)", id: "fk" },
  { title: "Faroe Islands", id: "fo" },
  { title: "Fiji", id: "fj" },
  { title: "Finland", id: "fi", currency: "eur", is_eu: true },
  { title: "French Guiana", id: "gf" },
  { title: "French Polynesia", id: "pf" },
  { title: "French Southern Territories", id: "tf" },
  { title: "Gabon", id: "ga" },
  { title: "Gambia", id: "gm" },
  { title: "Georgia", id: "ge" },
  { title: "Ghana", id: "gh" },
  { title: "Gibraltar", id: "gi" },
  { title: "Greece", id: "gr", currency: "eur", is_eu: true },
  { title: "Greenland", id: "gl" },
  { title: "Grenada", id: "gd" },
  { title: "Guadeloupe", id: "gp" },
  { title: "Guam", id: "gu" },
  { title: "Guatemala", id: "gt" },
  { title: "Guernsey", id: "gg" },
  { title: "Guinea", id: "gn" },
  { title: "Guinea-Bissau", id: "gw" },
  { title: "Guyana", id: "gy" },
  { title: "Haiti", id: "ht" },
  { title: "Heard Island and Mcdonald Islands", id: "hm" },
  { title: "Holy See (Vatican City State)", id: "va" },
  { title: "Honduras", id: "hn" },
  { title: "Hong Kong", id: "hk" },
  { title: "Hungary", id: "hu", is_eu: true },
  { title: "Iceland", id: "is" },
  { title: "India", id: "in" },
  { title: "Iran, Islamic Republic Of", id: "ir" },
  { title: "Iraq", id: "iq" },
  { title: "Ireland", id: "ie", currency: "eur", is_eu: true },
  { title: "Isle of Man", id: "im" },
  { title: "Israel", id: "il" },
  { title: "Italy", id: "it", currency: "eur", is_eu: true },
  { title: "Jamaica", id: "jm" },
  { title: "Japan", id: "jp" },
  { title: "Jersey", id: "je" },
  { title: "Jordan", id: "jo" },
  { title: "Kazakhstan", id: "kz" },
  { title: "Kenya", id: "ke" },
  { title: "Kiribati", id: "ki" },
  { title: 'Korea, Democratic People"S Republic of', id: "kp" },
  { title: "Korea, Republic of", id: "kr" },
  { title: "Kuwait", id: "kw" },
  { title: "Kyrgyzstan", id: "kg" },
  { title: 'Lao People"S Democratic Republic', id: "la" },
  { title: "Latvia", id: "lv", currency: "eur", is_eu: true },
  { title: "Lebanon", id: "lb" },
  { title: "Lesotho", id: "ls" },
  { title: "Liberia", id: "lr" },
  { title: "Libyan Arab Jamahiriya", id: "ly" },
  { title: "Liechtenstein", id: "li" },
  { title: "Lithuania", id: "lt", currency: "eur", is_eu: true },
  { title: "Luxembourg", id: "lu", currency: "eur", is_eu: true },
  { title: "Macao", id: "mo" },
  { title: "Macedonia, The Former Yugoslav Republic of", id: "mk" },
  { title: "Madagascar", id: "mg" },
  { title: "Malawi", id: "mw" },
  { title: "Malaysia", id: "my" },
  { title: "Maldives", id: "mv" },
  { title: "Mali", id: "ml" },
  { title: "Malta", id: "mt", currency: "eur", is_eu: true },
  { title: "Marshall Islands", id: "mh" },
  { title: "Martinique", id: "mq" },
  { title: "Mauritania", id: "mr" },
  { title: "Mauritius", id: "mu" },
  { title: "Mayotte", id: "yt" },
  { title: "Mexico", id: "mx" },
  { title: "Micronesia, Federated States of", id: "fm" },
  { title: "Moldova, Republic of", id: "md" },
  { title: "Monaco", id: "mc" },
  { title: "Mongolia", id: "mn" },
  { title: "Montenegro", id: "me" },
  { title: "Montserrat", id: "ms" },
  { title: "Morocco", id: "ma" },
  { title: "Mozambique", id: "mz" },
  { title: "Myanmar", id: "mm" },
  { title: "Namibia", id: "na" },
  { title: "Nauru", id: "nr" },
  { title: "Nepal", id: "np" },
  { title: "Netherlands Antilles", id: "an" },
  { title: "New Caledonia", id: "nc" },
  { title: "New Zealand", id: "nz" },
  { title: "Nicaragua", id: "ni" },
  { title: "Niger", id: "ne" },
  { title: "Nigeria", id: "ng" },
  { title: "Niue", id: "nu" },
  { title: "Norfolk Island", id: "nf" },
  { title: "Northern Mariana Islands", id: "mp" },
  { title: "Norway", id: "no" },
  { title: "Oman", id: "om" },
  { title: "Pakistan", id: "pk" },
  { title: "Palau", id: "pw" },
  { title: "Palestinian Territory, Occupied", id: "ps" },
  { title: "Panama", id: "pa" },
  { title: "Papua New Guinea", id: "pg" },
  { title: "Paraguay", id: "py" },
  { title: "Peru", id: "pe" },
  { title: "Philippines", id: "ph" },
  { title: "Pitcairn", id: "pn" },
  { title: "Poland", id: "pl", is_eu: true },
  { title: "Portugal", id: "pt", currency: "eur", is_eu: true },
  { title: "Puerto Rico", id: "pr" },
  { title: "Qatar", id: "qa" },
  { title: "Reunion", id: "re" },
  { title: "Romania", id: "ro", is_eu: true },
  { title: "RWANDA", id: "rw" },
  { title: "Saint Helena", id: "sh" },
  { title: "Saint Kitts and Nevis", id: "kn" },
  { title: "Saint Lucia", id: "lc" },
  { title: "Saint Pierre and Miquelon", id: "pm" },
  { title: "Saint Vincent and the Grenadines", id: "vc" },
  { title: "Samoa", id: "ws" },
  { title: "San Marino", id: "sm" },
  { title: "Sao Tome and Principe", id: "st" },
  { title: "Saudi Arabia", id: "sa" },
  { title: "Senegal", id: "sn" },
  { title: "Serbia", id: "rs" },
  { title: "Seychelles", id: "sc" },
  { title: "Sierra Leone", id: "sl" },
  { title: "Singapore", id: "sg" },
  { title: "Slovakia", id: "sk", currency: "eur", is_eu: true },
  { title: "Slovenia", id: "si", currency: "eur", is_eu: true },
  { title: "Solomon Islands", id: "sb" },
  { title: "Somalia", id: "so" },
  { title: "South Africa", id: "za" },
  { title: "South Georgia and the South Sandwich Islands", id: "gs" },
  { title: "Spain", id: "es", currency: "eur", is_eu: true },
  { title: "Sri Lanka", id: "lk" },
  { title: "Sudan", id: "sd" },
  { title: "Surititle", id: "sr" },
  { title: "Svalbard and Jan Mayen", id: "sj" },
  { title: "Swaziland", id: "sz" },
  { title: "Sweden", id: "se", is_eu: true },
  { title: "Switzerland", id: "ch", currency: "chf" },
  { title: "Syrian Arab Republic", id: "sy" },
  { title: "Tajikistan", id: "tj" },
  { title: "Tanzania, United Republic of", id: "tz" },
  { title: "Timor-Leste", id: "tl" },
  { title: "Togo", id: "tg" },
  { title: "Tokelau", id: "tk" },
  { title: "Tonga", id: "to" },
  { title: "Trinidad and Tobago", id: "tt" },
  { title: "Tunisia", id: "tn" },
  { title: "Turkmenistan", id: "tm" },
  { title: "Turks and Caicos Islands", id: "tc" },
  { title: "Tuvalu", id: "tv" },
  { title: "Uganda", id: "ug" },
  { title: "United Arab Emirates", id: "ae", currency: "aed" },
  { title: "United States Minor Outlying Islands", id: "um" },
  { title: "Uruguay", id: "uy" },
  { title: "Uzbekistan", id: "uz" },
  { title: "Vanuatu", id: "vu" },
  { title: "Venezuela", id: "ve" },
  { title: "Viet Nam", id: "vn" },
  { title: "Virgin Islands, British", id: "vg" },
  { title: "Virgin Islands, U.S.", id: "vi" },
  { title: "Wallis and Futuna", id: "wf" },
  { title: "Western Sahara", id: "eh" },
  { title: "Yemen", id: "ye" },
  { title: "Zambia", id: "zm" },
  { title: "Zimbabwe", id: "zw" },
]

export const findCountryByCode = (code: string) =>
  head(COUNTRIES.filter(propEq("id", code)))
