import { ProfileId } from "@hornet-web-react/core/types/session"
import { useCallback, useEffect, useRef, useState } from "react"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import ProfileVerificationError from "@hornet-web-react/core/services/API/Errors/ProfileVerificationError"
import { useApi } from "./use-api"
import { useFlashMessage } from "./use-flash-message"
import { useScript } from "usehooks-ts"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import TrackEvent from "@hornet-web-react/core/models/track-event"

export class IpQualityError extends Error {
  public data: IpQualityVerificationResult | null
  public reason: string

  constructor(reason: string, data: IpQualityVerificationResult | null = null) {
    super(`IpQualityError: ${reason}`)

    this.data = data
    this.reason = reason
  }
}

declare global {
  interface Window {
    IPQ?: unknown
    Storage?: {
      Result?: IpQualityVerificationResult
    }
    Startup: {
      AfterResult: (
        callback: (result: IpQualityVerificationResult) => void
      ) => void
      AfterFailure: (
        callback: (result: IpQualityVerificationResult) => void
      ) => void
      Store: (key: string, value: unknown) => void
      Init: () => void
    }
  }
}

// from https://www.ipqualityscore.com/documentation/device-fingerprint-api/overview
export type IpQualityVerificationResult = {
  success: boolean //	Status of the request.
  device_id: string // SHA256 / string	The Device ID is generated as a hash from the user's device hardware and personal settings. This value can be used for tracking users, detecting duplicate accounts, or passed to our callback endpoint for confirmation.
  guid: string // SHA256 / string	Hardware tracking ID which uses a different algorithm for calculating a hash of the user's device. This value can overlap with other devices that share the same hardware configuration. Please use in conjunction with "guid_confidence".
  guid_confidence: number // (0 - 100)	Accuracy of the "guid" match which associates a GUID hardware profile with other users, where 0 = not likely, 100 = very likely. A result of 100 is a guaranteed match. Confidence levels below 100 use an intelligent "best guess" approach. Some "guid" results may overlap users, such as a device with factory settings for popular devices.
  fraud_chance: number // (0 - 100)	How likely this device is to commit fraud or engage in abusive behavior. 0 = not likely, 100 = very likely. 25 is the median result. Fraud Scores >= 75 are suspicious, but not necessarily fraudulent. We recommend flagging or blocking traffic with Fraud Scores >= 85, but you may find it beneficial to use a higher or lower threshold.
  is_crawler: boolean //	Is this device associated with being a confirmed crawler from a mainstream search engine such as Googlebot, Bingbot, Yandex, etc.
  connection_type: string //	Classification of the IP address connection type as "Residential", "Corporate", "Education", "Mobile", or "Data Center".
  proxy: boolean //	Returns true if the lookup is on a Proxy, VPN, or Tor connection.
  vpn: boolean //	Is this IP suspected of being a VPN connection? (proxy will always be true if this is true)
  tor: boolean //	Is this IP suspected of being a Tor connection? (proxy will always be true if this is true)
  active_vpn: boolean // Premium, // Account Feature - Identifies active VPN connections used by popular VPN services and private VPN servers.	boolean
  active_tor: boolean // Premium, // Account Feature - Identifies active TOR exits on the TOR network.	boolean
  recent_abuse: boolean //	This value will indicate if there has been any recently verified abuse across our network for this user. Abuse could be a confirmed chargeback, compromised device, fake app install, or similar malicious behavior within the past few days.
  bot_status: boolean //	Premium Account Feature - Indicates if this device is a bot, spoofed device, or non-human request. Provides stronger confidence in decision making.
  reasons: string[] //[string]	Premium Account Feature - Fraud Score Insights explain how this device's Fraud Score was calculated and provides further detail into enhanced Fraud Scores and penalties. This data point is only available via the postback API so real-time users cannot reverse engineer why they were penalized.
  ssl_fingerprint: string //	Premium Account Feature - SSL fingerprint contains a sha256 of the SSL/TLS cyphers this device supports. Useful for detecting small changes in device fingerprints. This data point is only available via the postback API so real-time users cannot reverse engineer why they were penalized.
  ISP: string //	Internet Service Provider of the IP address. If unavailable, then "N/A".
  country: string //	Two letter country code of the IP address, example: "US".
  city: string //	City of IP address if available or "N/A" if unknown.
  region: string //	Region or state of IP address if available or "N/A" if unknown.
  timezone: string //	Timezone of IP address if available or "N/A" if unknown.
  mobile: boolean //	Is this a mobile device?
  operating_system: string //	Operating system name and version or "N/A" if unknown.
  browser: string //	Browser name and version or "N/A" if unknown.
  brand: string //	Brand name of the device or "N/A" if unknown.
  model: string //	Model name of the device or "N/A" if unknown.
  ip_address: string //	The IP Address associated with the device in IPv4 or IPv6 format.
  unique: boolean //	Returns false if this device ID has been seen on multiple IP addresses. Returns true if we haven't seen this ID on multiple IPs.
  canvas_hash: string // SHA256 / string	A hash of the user's Canvas profile, calculated by the graphics card and other device hardware. This value is often not unique, so should not be used to identify a specific user.
  webgl_hash: string // SHA256 / string	A hash of the user's WebGL profile, calculated by the graphics card and other device hardware. This value is often not unique, so should not be used to identify a specific user.
  request_id: string //	A unique identifier for this request that can be used to lookup the request details, interact with our API reports, or send a postback conversion notice.
  click_date: string // Date, // Premium Time	Time of this request. (Premium feature)
  first_seen: string // Date, // Premium Time	Time of the first request. (Premium feature)
  last_seen: string // Date, // Premium Time	Time of the most recent request. (Premium feature)
}

type UseIpQualityServiceProps = {
  isEnabled: boolean
  ipQualityScriptSrc: string
  logoutAction: (targetLocation: string) => Promise<void>
  getRouteToTrafficFailure: (failedCheck: string) => string
  profileId?: ProfileId
}

export const useIpQualityService = ({
  isEnabled,
  ipQualityScriptSrc,
  logoutAction,
  getRouteToTrafficFailure,
  profileId,
}: UseIpQualityServiceProps) => {
  const { makeApiRequest } = useApi()
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)
  const { showOops } = useFlashMessage()
  const scriptStatus = useScript(ipQualityScriptSrc, {
    removeOnUnmount: false,
  })
  const [ipQualityVerificationResult, setIpQualityVerificationResult] =
    useState<IpQualityVerificationResult | null>(null)

  const [ipQualityVerificationError, setIpQualityVerificationError] =
    useState<IpQualityError | null>(null)
  const isInit = useRef(false)

  // set up the callback for the ip quality script right away
  const setupCallbacks = () => {
    isInit.current = true

    if (typeof window === "undefined") {
      return
    }

    if (
      typeof window.IPQ != "undefined" &&
      typeof window.Storage != "undefined" &&
      typeof window.Storage.Result != "undefined" &&
      Object.keys(window.Storage.Result).length > 0
    ) {
      // console.log(
      //   "useIpQualityService: window.Storage.Result",
      //   window.Storage.Result
      // )

      setIpQualityVerificationResult(window.Storage.Result)

      if (profileId) {
        window.Startup.Store("userID", profileId)
      }
      return
    }

    window.IPQ = {
      Callback: function () {
        window.Startup.AfterResult(function (result) {
          // console.log(`useIpQualityService: AfterResult result`, result)
          setIpQualityVerificationResult(result)
        })

        window.Startup.AfterFailure(function (result) {
          // console.log(`useIpQualityService: AfterFailure result`, result)
          const reason = result?.active_tor
            ? "active_tor"
            : result?.active_vpn
            ? "active_vpn"
            : "ipq_failure"
          setIpQualityVerificationError(new IpQualityError(reason, result))
        })

        if (profileId) {
          window.Startup.Store("userID", profileId)
        }
        window.Startup.Init() // Start the fraud tracker.
      },
    }
  }

  if (!isInit.current) {
    setupCallbacks()
  }

  const postIpQualityError = useCallback(
    async (error: IpQualityError, isBackground = false) => {
      return makeApiRequest(
        ApiService.getEndpoint(ApiServiceEndpoint.DeviceFingerprintsPost),
        {
          error: btoa(JSON.stringify(error.data)),
          background: isBackground,
          success: false,
        }
      )
    },
    [makeApiRequest]
  )

  const verifyIpQualityFingerprint = useCallback(
    async (fingerprint: IpQualityVerificationResult, isBackground = false) => {
      const apiResult = await makeApiRequest(
        ApiService.getEndpoint(ApiServiceEndpoint.DeviceFingerprintsPost),
        {
          device_fingerprint: btoa(JSON.stringify(fingerprint)),
          background: isBackground,
          success: true,
        }
      )

      if (isRight(apiResult)) {
        return true
      }

      const error = unwrapEither(apiResult)

      // this is expected, it could be that this triggers another type of verification
      if (error instanceof ProfileVerificationError) {
        return true
      }

      if (!isBackground) {
        void showOops(error)
      }

      return false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleIpQualityCheckFailure = useCallback(
    async (
      screen: string,
      failedEvent: TrackEvent,
      error: IpQualityError | null,
      result: IpQualityVerificationResult | null
    ) => {
      void eventTrackerService.report(failedEvent)

      // final error goes by priority
      //  * use supplied error
      //  * try determine error from result
      //  * then it's just kinda unknown error
      function determineIpQualityError(
        error: IpQualityError | null,
        result: IpQualityVerificationResult | null
      ): IpQualityError {
        if (error instanceof IpQualityError) {
          return error
        }

        if (result) {
          const reason = result.success
            ? "no_success"
            : result.active_tor
            ? "tor"
            : result.active_vpn
            ? "vpn"
            : "unknown"

          return new IpQualityError(reason, result)
        }

        return new IpQualityError("ipq_failed")
      }

      const errorToReport = determineIpQualityError(error, result)

      loggerService.logExceptionWithSentry(
        errorToReport,
        loggerService.createLoggingContext({
          component: screen,
          details: JSON.stringify(errorToReport.data),
        })
      )

      // give 500ms to report the event before logging out
      await new Promise((r) => setTimeout(r, 500))

      void logoutAction(getRouteToTrafficFailure(errorToReport.reason))
    },
    [eventTrackerService, loggerService, logoutAction, getRouteToTrafficFailure]
  )

  const handleIpQualityCheck = useCallback(
    (screen: string, failedEvent: TrackEvent) => {
      if (ipQualityVerificationError) {
        void handleIpQualityCheckFailure(
          screen,
          failedEvent,
          ipQualityVerificationError,
          null
        )
        return false
      }

      if (!ipQualityVerificationResult) {
        void handleIpQualityCheckFailure(screen, failedEvent, null, null)
        return false
      }

      if (
        isEnabled &&
        (!ipQualityVerificationResult.success ||
          ipQualityVerificationResult.active_tor ||
          ipQualityVerificationResult.active_vpn)
      ) {
        void handleIpQualityCheckFailure(
          screen,
          failedEvent,
          null,
          ipQualityVerificationResult
        )
        return false
      }

      // happy path, all good
      return true
    },
    [
      handleIpQualityCheckFailure,
      ipQualityVerificationError,
      ipQualityVerificationResult,
      isEnabled,
    ]
  )

  useEffect(() => {
    // report when script does not load at all
    if (scriptStatus === "error") {
      loggerService.logExceptionWithSentry(
        new Error("IPQ script failed to load")
      )

      void logoutAction(getRouteToTrafficFailure("ipq_script_failed"))
    }
  }, [getRouteToTrafficFailure, loggerService, logoutAction, scriptStatus])

  return {
    ipQualityScriptStatus: scriptStatus,
    verifyIpQualityFingerprint,
    postIpQualityError,
    ipQualityVerificationResult,
    ipQualityVerificationError,
    handleIpQualityCheckFailure,
    handleIpQualityCheck,
    // originally had a check for actual loading, but it turns out that even
    // if you block the script loading (or it fails for SSL reasons etc), the
    // `scriptStatus` is still `ready` even though it doesn't really work
    // so instead just going to check if the script is loading, and the problem
    // with missing result+error will be deferred to when actual handler is called
    //
    isLoading: !ipQualityVerificationResult && !ipQualityVerificationError,
  }
}
