import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoggerService, {
  CreateLoggingContext,
  LogExceptionWithSentry,
  LogMessageWithSentry,
} from "@hornet-web-react/core/services/LoggerService"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"

type UseLoggerServiceReturn = {
  logMessageWithSentry: LogMessageWithSentry
  logExceptionWithSentry: LogExceptionWithSentry
  createLoggingContext: CreateLoggingContext
}

export const useLoggerService = (): UseLoggerServiceReturn => {
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)

  return {
    logExceptionWithSentry:
      loggerService.logExceptionWithSentry.bind(loggerService),
    logMessageWithSentry:
      loggerService.logMessageWithSentry.bind(loggerService),
    createLoggingContext:
      loggerService.createLoggingContext.bind(loggerService),
  }
}
