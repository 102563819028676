import { FC, PropsWithChildren, useEffect, useRef, useState } from "react"
import NiceModal from "@ebay/nice-modal-react"
import {
  useProfileVerificationApi,
  useProfileVerificationState,
} from "@hornet-web-react/core/contexts/profile-verification-context"
import { useNavigation } from "../../hooks/use-navigation"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { isRight } from "@hornet-web-react/core/utils"
import appConfig from "../../app-config"
import {
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useHornetSession } from "../../hooks/use-hornet-session"
import BackgroundIpqProfileVerification from "./BackgroundIpqProfileVerification"

type ProfileVerificationProps = PropsWithChildren

const ProfileVerification: FC<ProfileVerificationProps> = ({ children }) => {
  const {
    isEmailProfileVerificationOpen,
    isSmsProfileVerificationOpen,
    isRecaptchaProfileVerificationOpen,
    isIpQualityProfileVerificationOpen,
    ipQualityRedirectTo,
  } = useProfileVerificationState()
  const {
    closeEmailProfileVerification,
    closeSmsProfileVerification,
    closeRecaptchaProfileVerification,
    closeIpQualityProfileVerification,
  } = useProfileVerificationApi()
  const { navigateToAccount } = useNavigation()
  const { makeApiRequest } = useApi()
  const { currentUser } = useSessionUser()
  const { data: fullSession, updatePhoneNumber } = useHornetSession()
  const { appUrl } = useSessionEnvironment()
  const hasBackgroundIpqTriggered = useRef(false)
  const [isBackgroundIpqVisible, setIsBackgroundIpqVisible] = useState(false)

  const openEmailProfileVerification = async (
    isAccountEmailVerified: boolean,
    isVerificationEmailResent = false
  ) => {
    const EmailVerificationModal = (
      await import(
        "@hornet-web-react/core/components-lazy/EmailVerificationModal"
      )
    ).default

    NiceModal.show(EmailVerificationModal, {
      isVerificationEmailResent,
      isAccountEmailVerified,
    })
      .then(async (action: string) => {
        if (action === "change_email") {
          closeEmailProfileVerification()
          void navigateToAccount()
          return
        }

        if (action === "resend") {
          const apiResult = await makeApiRequest(
            ApiService.getEndpoint(
              ApiServiceEndpoint.ResendEmailVerificationPost
            )
          )

          if (isRight(apiResult)) {
            void openEmailProfileVerification(isAccountEmailVerified, true)
          }
        }
      })
      .catch(closeEmailProfileVerification)
  }

  useEffect(() => {
    if (isEmailProfileVerificationOpen && fullSession) {
      void openEmailProfileVerification(fullSession.isAccountEmailVerified)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailProfileVerificationOpen, fullSession])

  useEffect(() => {
    const openSmsProfileVerification = async (phoneNumber: string | null) => {
      const SmsVerificationModal = (
        await import(
          "@hornet-web-react/core/components-lazy/SmsVerificationModal"
        )
      ).default

      NiceModal.show(SmsVerificationModal, {
        sessionPhoneNumber: phoneNumber,
        updatePhoneNumber,
      }).catch(closeSmsProfileVerification)
    }

    if (isSmsProfileVerificationOpen && fullSession) {
      void openSmsProfileVerification(fullSession.account.phone_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmsProfileVerificationOpen, fullSession])

  const openRecaptchaProfileVerification = async () => {
    const RecaptchaVerificationModal = (
      await import(
        "@hornet-web-react/core/components-lazy/RecaptchaVerificationModal"
      )
    ).default

    NiceModal.show(RecaptchaVerificationModal, {
      googleRecaptchaSiteKey: appConfig.googleRecaptcha.siteKey,
      googleRecaptchaV3SiteKey: appConfig.googleRecaptchaV3.siteKey,
    })
      .then(closeRecaptchaProfileVerification)
      // could try to refresh the page here instead
      .catch(closeRecaptchaProfileVerification)
  }

  useEffect(() => {
    if (isRecaptchaProfileVerificationOpen) {
      void openRecaptchaProfileVerification()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecaptchaProfileVerificationOpen])

  const openIpQualityProfileVerification = async (
    redirectTo: string | undefined
  ) => {
    const IpQualityVerificationModal = (
      await import(
        "@hornet-web-react/core/components-lazy/IpQualityVerificationModal"
      )
    ).default

    let ipQualityScriptSrc: string
    switch (appConfig.environment) {
      case "beta":
      case "alpha":
        ipQualityScriptSrc = appConfig.ipQualityScore.hornetScriptSrc
        break

      case "production":
      default:
        ipQualityScriptSrc = appUrl.includes("hornetapp")
          ? appConfig.ipQualityScore.hornetAppScriptSrc
          : appConfig.ipQualityScore.hornetScriptSrc
    }

    const successIpqHandler = () => {
      closeIpQualityProfileVerification()

      if (redirectTo) {
        // cannot router push, but need to do hard redirect because the CSP update
        // needs to be loaded fresh (otherwise the page still allows the unsafe-eval)
        window.location.href = `${appUrl}/${redirectTo}`
      }
    }

    const errorIpqHandler = () => {
      closeIpQualityProfileVerification()
      window.location.href = `${appUrl}/404`
    }

    NiceModal.show(IpQualityVerificationModal, {
      profileId: currentUser?.profileId,
      ipQualityScriptSrc,
    })
      .then(successIpqHandler)
      // could try to refresh the page here instead
      .catch(errorIpqHandler)
  }

  useEffect(() => {
    if (isIpQualityProfileVerificationOpen) {
      void openIpQualityProfileVerification(ipQualityRedirectTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIpQualityProfileVerificationOpen, ipQualityRedirectTo])

  // background IPQ check
  useEffect(() => {
    if (
      (fullSession?.onOpenDeeplinks || []).includes("hrnt://background_ipq") &&
      !hasBackgroundIpqTriggered.current
    ) {
      hasBackgroundIpqTriggered.current = true
      setIsBackgroundIpqVisible(true)
    }
  }, [fullSession?.onOpenDeeplinks])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {children}
      {isBackgroundIpqVisible && <BackgroundIpqProfileVerification />}
    </>
  )
}

export default ProfileVerification
