import { v4 as uuidv4 } from "uuid"
import { debug } from "@hornet-web-react/core/utils"
import EventTrackerService from "./EventTrackerService"
import {
  PLATFORM,
  Platform,
  UNKNOWN_DEVICE_ID,
} from "@hornet-web-react/core/utils/constants"
import { type AppConfig } from "./AppConfig"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import { DeviceId } from "@hornet-web-react/core/types/session"

export interface AppStoreServiceContext {
  deviceId: DeviceId
  publicAppUrl: string
}

class AppStoreService {
  private readonly _appConfig: AppConfig
  private readonly _eventTrackerService: EventTrackerService
  private _context: AppStoreServiceContext

  constructor(
    appConfig: AppConfig,
    eventTrackerService: EventTrackerService,
    context: AppStoreServiceContext
  ) {
    debug(`AppStoreService: constructor`)

    this._appConfig = appConfig
    this._eventTrackerService = eventTrackerService
    this._context = context
  }

  updateContext(context: AppStoreServiceContext) {
    this._context = context
  }

  getStoreUrl(
    platform: Platform,
    utmSource: string,
    utmCampaign: string | null = null,
    includeCheckForDesktop = true
  ) {
    debug(
      `AppStoreService: getStoreUrl(${platform}), ${utmSource}, ${utmCampaign}, ${
        includeCheckForDesktop ? 1 : 0
      })`
    )

    utmSource = normalizeUtmSource(utmSource)
    utmCampaign = normalizeUtmCampaign(utmCampaign)

    // on desktop, we want only the direct links, no firebase (as that falls back to
    // our web app url so user ends up in a loop)

    // HACK: actually, turn off Firebase dynamic links for now because we don't
    // have it set up and IDK if native apps even support it

    // if (includeCheckForDesktop && isDesktop()) {
    switch (platform) {
      case PLATFORM.IOS:
        return this._createDirectIOsLink(
          utmSource,
          utmCampaign,
          this._appConfig.appStore.ios.appStoreId
        )
      case PLATFORM.ANDROID:
        if (typeof this._appConfig.appStore.android !== "undefined") {
          return this._createDirectAndroidLink(
            utmSource,
            utmCampaign,
            this._appConfig.appStore.android.playStoreId
          )
        }
        return ""

      // TODO: use `isStrictNever` and re-type the `platform`
      default:
        return ""
    }
    // }

    // return this._createFirebaseDynamicLink(utmSource, utmCampaign, platform)
  }

  async openAppStore(
    platform: Platform,
    utmSource: string,
    utmCampaign: string | null = null
  ) {
    debug(
      `AppStoreService: openAppStore(${platform}, ${utmSource}, ${utmCampaign})`
    )

    utmSource = normalizeUtmSource(utmSource)
    utmCampaign = normalizeUtmCampaign(utmCampaign)

    const storeWindow = window.open(
      this.getStoreUrl(platform, utmSource),
      uuidv4(),
      "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212"
    )

    debug(
      `openAppStore: ${platform}, utmSource: ${utmSource}, utmCampaign: ${utmCampaign}`
    )

    await this._eventTrackerService.report(
      TrackEvent.appDownload(platform, utmSource)
    )

    if (storeWindow) {
      storeWindow.focus()
    }
  }

  _createFirebaseDynamicLink(
    utmSource: string,
    utmCampaign: string,
    platform: Platform
  ) {
    const utmMedium = normalizeUtmMedium(this._context.deviceId)

    const baseUrl = this._appConfig.firebaseDynamicLink.baseUrl
    const fallbackUrl = `${this._context.publicAppUrl}/`

    let params

    if (platform === PLATFORM.IOS) {
      params = new URLSearchParams({
        link: `${fallbackUrl}`,
        ibi: this._appConfig.appStore.ios.appId,
        ius: this._appConfig.appStore.ios.appName,
        isi: this._appConfig.appStore.ios.appStoreId,
        ct: utmCampaign,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_source: utmSource,
        pt: utmSource,
      })
    } else {
      params = new URLSearchParams({
        link: `${fallbackUrl}`,
        apn: this._appConfig.appStore.android.playStoreId,
        ct: utmCampaign,
        utm_campaign: utmCampaign,
        utm_medium: utmMedium,
        utm_source: utmSource,
        pt: utmSource,
      })
    }

    return baseUrl + "?" + params.toString()
  }

  _createDirectIOsLink(
    utmSource: string,
    utmCampaign: string,
    appStoreId: string
  ) {
    return `https://apps.apple.com/app/apple-store/id${appStoreId}?pt=${encodeURIComponent(
      utmSource
    )}&mt=8&ct=${encodeURIComponent(utmCampaign)}`
  }

  _createDirectAndroidLink(
    utmSource: string,
    utmCampaign: string,
    playStoreId: string
  ) {
    return `https://play.google.com/store/apps/details?id=${playStoreId}&referrer=utm_source%3Dhornet.com%26utm_campaign%3D${encodeURIComponent(
      utmSource || "unknown"
    )}`
  }
}

function normalizeUtmMedium(utmMedium: string | null = null) {
  return utmMedium !== UNKNOWN_DEVICE_ID && utmMedium ? utmMedium : "web"
}

function normalizeUtmSource(utmSource: string | null = null) {
  return utmSource ? String(utmSource) : "unknown"
}

function normalizeUtmCampaign(utmCampaign: string | null = null) {
  return utmCampaign ? String(utmCampaign) : "index"
}

function isDesktop() {
  // check for support first
  if (typeof window == "undefined" || !window.matchMedia) {
    return null
  }

  // greater than medium-sized screen
  const mql = window.matchMedia("(min-width: 992px)")

  return mql.matches
}

export default AppStoreService
