export function convertMetricHeightToImperial(cm: number): [number, number] {
  const feet = Math.floor(cm * 0.0328084)
  const inches = Math.round(((cm * 0.0328084 - feet) / 0.0328084) * 0.393701)

  // handle case when 1ft is returned as 0ft 12in and similar
  if (inches === 12) {
    return [feet + 1, 0]
  }

  return [feet, inches]
}

export function formatImperialHeight([feet, inches]: [number, number]) {
  return `${feet}′${inches}″`
}

export function convertMetricWeightToImperial(kg: number) {
  return Math.round(kg * 2.20462)
}

export function convertImperialHeightToMetric(feet: number, inches: number) {
  return Math.round(feet * 30.48 + inches * 2.54)
}

export function convertImperialWeightToMetric(lbs: number) {
  return Math.round(lbs * 0.453592)
}
