import { useCallback } from "react"
import NiceModal from "@ebay/nice-modal-react"
import { PremiumPaywall } from "@hornet-web-react/core/types"
import HornetAnalyticsEvent from "../models/hornet-analytics-event"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"
import { useEventTrackerService } from "./use-event-tracker-service"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

export function usePremium() {
  const {
    premium: { setPremiumPaywall },
  } = useSessionApi()
  const { reportEvent } = useEventTrackerService()
  const { get: getPremiumModal } = useLazyLoaded(
    () => import("../components/Modals/PremiumModal")
  )

  return {
    openPremiumModal: useCallback(
      async (paywall: PremiumPaywall) => {
        void reportEvent(HornetAnalyticsEvent.premiumOpened(paywall))

        setPremiumPaywall(paywall)

        void getPremiumModal((modal) =>
          NiceModal.show(modal, {
            paywall,
          }).catch(() => setPremiumPaywall(undefined))
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  }
}
