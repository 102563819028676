import { FC, MouseEvent, ReactNode, useEffect, useState } from "react"
import styled from "styled-components"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { useNavigation } from "../../../hooks/use-navigation"

type MenuItemProps = {
  href: string
  label: string
  isActive: boolean
  itemIcon?: ReactNode
  itemActiveIcon?: ReactNode
  badge?: number
  isExternal?: boolean
  isSmall?: boolean
  isRequiresAuth?: boolean
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
}

const MenuItem: FC<MenuItemProps> = ({
  href,
  label,
  isActive,
  itemIcon,
  itemActiveIcon,
  onClick,
  badge,
  isExternal = false,
  isSmall = false,
  isRequiresAuth = true,
}) => {
  const { isAuthenticated } = useSessionUser()
  const { navigateToSignup } = useNavigation()
  const [isShowingOverlay, setIsShowingOverlay] = useState(false)

  const linkProps: Record<string, any> = isExternal
    ? {
        rel: "noopener noreferrer",
        target: "_blank",
      }
    : {}

  if (typeof onClick === "function") {
    linkProps.onClick = onClick
  }

  useEffect(() => {
    setIsShowingOverlay(!isAuthenticated)
  }, [isAuthenticated])

  return (
    <MainMenuItem>
      {isShowingOverlay && isRequiresAuth && (
        <MainMenuOverlay
          role="button"
          onClick={() => {
            void navigateToSignup()
          }}
          aria-label={label}
        />
      )}
      <MainMenuItemLink
        href={href}
        aria-label={label}
        {...linkProps}
        isActive={isActive}
        isSmall={isSmall}
        isDisabled={isShowingOverlay && isRequiresAuth}
      >
        {itemIcon && itemActiveIcon ? (
          <IconContainer>{isActive ? itemActiveIcon : itemIcon}</IconContainer>
        ) : null}
        <Label>
          {label}{" "}
          {badge ? <MainMenuItemBadge>{badge}</MainMenuItemBadge> : null}
        </Label>
      </MainMenuItemLink>
    </MainMenuItem>
  )
}

export default MenuItem

const MainMenuItem = styled.li`
  position: relative;
`

const MainMenuOverlay = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  cursor: pointer;
`

const MainMenuItemLink = styled.a<{
  isActive: boolean
  isSmall: boolean
  isDisabled: boolean
}>`
  ${({ theme, isSmall }) =>
    isSmall ? theme.font.regular.footnote : theme.font.regular.body};

  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme, isActive, isSmall }) =>
    isActive
      ? theme.color.tint.default
      : isSmall
      ? theme.color.text.tertiary
      : theme.color.text.primary};
  font-weight: 500;
  line-height: ${({ isSmall }) => (isSmall ? "22px" : "32px")};
  height: ${({ isSmall }) => (isSmall ? "22px" : "32px")};
  text-decoration: none;
  margin-bottom: ${({ theme }) => theme.spacing.half};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  img {
    display: block;
    height: 22px;
  }

  &:active,
  &:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.color.tint.default};
  }
`

const Label = styled.span`
  display: flex;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
`

const IconContainer = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const MainMenuItemBadge = styled.span`
  z-index: 100;
  margin-left: 7px;
  background: ${({ theme }) => theme.color.tint.default};
  border-radius: 20px;
  display: block;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.spacing.regular};
  min-width: 20px;
  padding: 0 5px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  overflow: hidden;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
`
