import styled, { css } from "styled-components"
import UnstyledButton from "../UI/UnstyledButton"
import ChevronLeftIcon from "../Icons/ChevronLeftIcon"
import ChevronRightIcon from "../Icons/ChevronRightIcon"
import Link from "next/link"

const hasPaddingStyle = css`
  padding: 0 12px;
`

export const MobileNavigationWrapper = styled.div<{
  hasPadding: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  max-height: 40px;

  ${(props) => props.hasPadding && hasPaddingStyle}
`

// left side of navigation
export const MobileNavigationButtonWrapper = styled.div<{
  isRight?: boolean
  hasTitle: boolean
}>`
  position: relative;
  width: ${(props) => (props.hasTitle ? "25%" : "50%")};
  max-width: ${(props) => (props.hasTitle ? "25%" : "50%")};
  flex-grow: 0;
  display: flex;
  justify-content: ${(props) => (props.isRight ? "flex-end" : "flex-start")};
  overflow: ${(props) =>
    props.isRight
      ? "visible"
      : "hidden"}; // must be visible for <ShoppingCartLink />
  text-overflow: ellipsis;
  height: 40px;
`

const isDisabledStyles = css`
  cursor: default;
  pointer-events: none;
  color: ${({ theme }) => theme.color.text.tertiary};
`

const mobileNavigationButtonStyles = css<{
  hasChevron: boolean
  hasLongTitle: boolean
  isRight?: boolean
}>`
  ${({ theme, hasLongTitle }) =>
    hasLongTitle
      ? theme.font.regular.footnote
      : theme.font.regular.subheadline};
  color: ${({ theme }) => theme.color.tint.default};
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
  padding: 2px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isRight ? "flex-end" : "flex-start")};
`

export const MobileNavigationButton = styled(UnstyledButton)<{
  hasChevron: boolean
  hasLongTitle: boolean
  isRight?: boolean
  isDisabled?: boolean
}>`
  ${mobileNavigationButtonStyles};
  ${(props) => props.isDisabled && isDisabledStyles};
`

export const MobileNavigationNextLink = styled(Link)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`

export const MobileNavigationLink = styled.span<{
  hasChevron: boolean
  hasLongTitle: boolean
  isRight?: boolean
}>`
  ${mobileNavigationButtonStyles};
`

export const MobileNavigationTitle = styled.h1`
  ${({ theme }) => theme.font.bold.subheadline};
  margin: 0;
  max-width: 50%;
  flex: 1 1 auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const MobileNavigationLeftButtonIcon = styled(ChevronLeftIcon)`
  margin-right: 2px;
`

export const MobileNavigationRightButtonIcon = styled(ChevronRightIcon)`
  margin-left: 2px;
`

export const MobileNavigationLabel = styled.span`
  display: block;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`
