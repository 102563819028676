import CustomApiError from "./CustomApiError"

export default class UnprocessableEntityError extends CustomApiError {
  delta: number
  errors: Record<string, string[]>

  constructor({ errors, t }: { errors: Record<string, string[]>; t?: number }) {
    super()

    this.errors = errors
    this.delta = t || 0
  }
}
