import { debug } from "@hornet-web-react/core/utils"
import CommunityApiService, {
  CommunityApiServiceContext,
} from "./API/CommunityApiService"
import { ServicesContextInterface } from "@hornet-web-react/core/types/services"
import { AppConfig } from "./AppConfig"
import { CORE_TYPES } from "./types"
import ApiService, { ApiServiceContext } from "./API/ApiService"
import LoggerService from "./LoggerService"
import AppStoreService from "./AppStoreService"
import EventTrackerService from "./EventTrackerService"
import GoogleAnalyticsService from "./GoogleAnalyticsService"
import LocalStorageService from "./LocalStorageService"
import LoginService from "./LoginService"
import LookupDataService from "./LookupDataService"
import WebsocketService from "./WebsocketService"
import Bottle from "bottlejs"

export function createContainer(
  appConfig: AppConfig,
  context: ServicesContextInterface
) {
  const bottle = new Bottle()
  debug(`CoreServicesIoc: createContainerWithContext`)

  bottle.service("AppConfig", function () {
    return appConfig
  })

  addFactoryBindings(bottle)
  addBindings(bottle, context)

  return bottle
}

export const addFactoryBindings = (bottle: Bottle): void => {
  debug(`CoreServicesIoc: addFactoryBindings`)

  bottle.factory(CORE_TYPES.ApiServiceFactory, (container) => {
    return (apiServiceContext: ApiServiceContext) => {
      debug(`ApiServiceFactory`)
      return new ApiService(
        container[CORE_TYPES.AppConfig] as AppConfig,
        container[CORE_TYPES.LoggerService] as LoggerService,
        container[CORE_TYPES.LocalStorageService] as LocalStorageService,
        apiServiceContext
      )
    }
  })

  bottle.factory(CORE_TYPES.CommunityApiServiceFactory, (container) => {
    return (communityApiServiceContext: CommunityApiServiceContext) => {
      debug(`CommunityApiServiceFactory`)
      return new CommunityApiService(
        container[CORE_TYPES.AppConfig] as AppConfig,
        container[CORE_TYPES.LoggerService] as LoggerService,
        container[CORE_TYPES.LocalStorageService] as LocalStorageService,
        communityApiServiceContext
      )
    }
  })
}

export function addBindings(
  bottle: Bottle,
  servicesContext: ServicesContextInterface
): void {
  debug(`CoreServicesIoc: addBindings`)

  // TODO: check if provider maybe already exists
  bottle.factory(CORE_TYPES.ApiService, (container) => {
    return new ApiService(
      container[CORE_TYPES.AppConfig] as AppConfig,
      container[CORE_TYPES.LoggerService] as LoggerService,
      container[CORE_TYPES.LocalStorageService] as LocalStorageService,
      {
        locale: servicesContext.locale,
        accessToken: servicesContext.accessToken,
        profileId: servicesContext.profileId,
        appUrl: servicesContext.appUrl,
        deviceId: servicesContext.deviceId,
        deviceLocation: servicesContext.deviceLocation,
        appName: servicesContext.appName,
      }
    )
  })

  bottle.factory(CORE_TYPES.AppStoreService, (container) => {
    return new AppStoreService(
      container[CORE_TYPES.AppConfig] as AppConfig,
      container[CORE_TYPES.EventTrackerService] as EventTrackerService,
      {
        deviceId: servicesContext.deviceId,
        publicAppUrl: servicesContext.publicAppUrl,
      }
    )
  })

  bottle.factory(CORE_TYPES.CommunityApiService, (container) => {
    return new CommunityApiService(
      container[CORE_TYPES.AppConfig] as AppConfig,
      container[CORE_TYPES.LoggerService] as LoggerService,
      container[CORE_TYPES.LocalStorageService] as LocalStorageService,
      {
        locale: servicesContext.locale,
        accessToken: servicesContext.accessToken,
        profileId: servicesContext.profileId,
        appUrl: servicesContext.appUrl,
        deviceId: servicesContext.deviceId,
        communityToken: servicesContext.communityToken,
        setCommunityToken: servicesContext.setCommunityToken,
        deviceLocation: servicesContext.deviceLocation,
      }
    )
  })

  bottle.factory(CORE_TYPES.EventTrackerService, (container) => {
    return new EventTrackerService(
      container[CORE_TYPES.AppConfig] as AppConfig,
      container[CORE_TYPES.GoogleAnalyticsService] as GoogleAnalyticsService,
      container[CORE_TYPES.LocalStorageService] as LocalStorageService,
      {
        deviceId: servicesContext.deviceId,
        isInApp: servicesContext.isInApp,
        profileId: servicesContext.profileId,
        sessionQueryParams: servicesContext.sessionQueryParams,
        locale: servicesContext.locale,
        serverSessionAnalytics: servicesContext.serverSessionAnalytics,
      }
    )
  })

  // DEPRECATED: remove (in favour pf plain custom uuid from LocalStorage)
  // bottle.factory(CORE_TYPES.FingerprintService, (container) => {
  //   return new FingerprintService(
  //     container[CORE_TYPES.LoggerService] as LoggerService
  //   )
  // })

  bottle.factory(CORE_TYPES.GoogleAnalyticsService, (container) => {
    return new GoogleAnalyticsService(
      container[CORE_TYPES.AppConfig] as AppConfig,
      container[CORE_TYPES.LocalStorageService] as LocalStorageService,
      {
        deviceId: servicesContext.deviceId,
        profileId: servicesContext.profileId,
        isInApp: servicesContext.isInApp,
      }
    )
  })

  bottle.factory(CORE_TYPES.LocalStorageService, (container) => {
    return new LocalStorageService(
      container[CORE_TYPES.LoggerService] as LoggerService
    )
  })

  bottle.factory(CORE_TYPES.LoginService, (container) => {
    return new LoginService(container[CORE_TYPES.ApiService] as ApiService)
  })

  bottle.factory(CORE_TYPES.LookupDataService, (container) => {
    return new LookupDataService(container[CORE_TYPES.ApiService] as ApiService)
  })

  bottle.factory(CORE_TYPES.LoggerService, (container) => {
    return new LoggerService(container[CORE_TYPES.AppConfig] as AppConfig, {
      locale: servicesContext.locale,
      profileId: servicesContext.profileId,
      deviceId: servicesContext.deviceId,
    })
  })

  bottle.factory(CORE_TYPES.WebsocketService, (container) => {
    return new WebsocketService(
      container[CORE_TYPES.AppConfig] as AppConfig,
      container[CORE_TYPES.LoggerService] as LoggerService,
      {
        accessToken: servicesContext.accessToken,
        profileId: servicesContext.profileId,
      }
    )
  })
}

export function updateContext(
  bottle: Bottle,
  servicesContext: ServicesContextInterface
): void {
  bottle.container[CORE_TYPES.ApiService].updateContext(servicesContext)
  bottle.container[CORE_TYPES.AppStoreService].updateContext(servicesContext)
  bottle.container[CORE_TYPES.CommunityApiService].updateContext(
    servicesContext
  )
  bottle.container[CORE_TYPES.EventTrackerService].updateContext(
    servicesContext
  )
  bottle.container[CORE_TYPES.GoogleAnalyticsService].updateContext(
    servicesContext
  )
  bottle.container[CORE_TYPES.LoggerService].updateContext(servicesContext)
  bottle.container[CORE_TYPES.WebsocketService].updateContext(servicesContext)
}
