import { CommunityApiToken } from "@hornet-web-react/core/types/session"
import { getCorrectValue } from "./zod-schema-parse"

export default function getCommunityTokenFromCookie(
  cookieData: string | undefined
): CommunityApiToken | undefined {
  try {
    return getCorrectValue(JSON.parse(cookieData || ""), CommunityApiToken)
  } catch (e) {
    return undefined
  }
}
