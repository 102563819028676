import { FC } from "react"
import styled from "styled-components"
import Container from "./Container"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import NavBar from "./NavBar/NavBar"

type BottomNavBarProps = {
  utmCampaign?: string
}

const BottomNavBar: FC<BottomNavBarProps> = () => {
  const { isAuthenticated } = useSessionUser()

  if (!isAuthenticated) {
    return null
  }

  return (
    <Wrapper>
      <Container>
        <NavBar hasNotifications={false} />
      </Container>
    </Wrapper>
  )
}

export default BottomNavBar

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 50px;
  position: fixed;
  z-index: 40;
  bottom: 0;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-bottom: constant(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    height: calc(50px + var(--safe-area-inset-bottom));
    padding-bottom: var(--safe-area-inset-bottom);
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    height: calc(50px + var(--safe-area-inset-bottom));
    padding-bottom: var(--safe-area-inset-bottom);
  }

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: none;
  }
`
