import styled from "styled-components"

const StyledBurger = styled.button<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  div {
    width: 20px;
    height: 2px;
    background: ${({ isOpen }) => (isOpen ? "#777777" : "#777777")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ isOpen }) => (isOpen ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ isOpen }) => (isOpen ? "0" : "1")};
      transform: ${({ isOpen }) =>
        isOpen ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ isOpen }) => (isOpen ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

type BurgerProps = {
  className?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const Burger = ({ className, isOpen, setIsOpen }: BurgerProps) => {
  return (
    <StyledBurger
      className={className}
      title="Burger Button"
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  )
}
export default Burger
