function createMetaId(name) {
  return `meta-${name}-tag`
}

export function shortenDescription(content) {
  const string = (String(content) || "").substr(0, 160)
  return `${string}${String(content).length > 160 ? "…" : ""}`
}

export function createMeta(name, content, attrName = "name") {
  const id = createMetaId(name)
  return {
    id,
    attrs: {
      [attrName]: name,
      content,
      id,
    },
  }
}

export function buildTitle(titleChunks = []) {
  return titleChunks.join(" | ")
}

export function buildMetaFromArray(meta = [], isWebScreen = false) {
  let metaArray = [...meta]
  if (isWebScreen) {
    metaArray = [
      ...metaArray,
      { name: "robots", content: "noindex,nofollow" },
      {
        name: "viewport",
        content:
          "viewport-fit=cover, width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
      },
    ]
  }

  return metaArray.reduce((acc, cur) => {
    const item = createMeta(
      cur.name || cur.property,
      cur.content,
      cur.name ? "name" : "property"
    )
    acc[item.id] = item.attrs
    return acc
  }, {})
}

export function buildDocumentMeta(
  t,
  appConfig,
  defaultImageSocialMediaThumb,
  canonicalUrl,
  meta = {}
) {
  let defaultMeta = [
    {
      name: "viewport",
      content: "viewport-fit=cover, width=device-width, initial-scale=1",
    },
    {
      name: "description",
      content: t("hornet:pages.landing.meta_description"),
    },
    {
      property: "og:description",
      content: t("hornet:pages.landing.meta_description"),
    },
    {
      property: "og:image",
      content: defaultImageSocialMediaThumb.src,
    },
    {
      property: "og:image:width",
      content: defaultImageSocialMediaThumb.width,
    },
    {
      property: "og:image:height",
      content: defaultImageSocialMediaThumb.height,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: t("hornet:pages.landing.meta_title"),
    },
    {
      name: "twitter:title",
      content: t("hornet:pages.landing.meta_title"),
    },
    {
      name: "twitter:description",
      content: t("hornet:pages.landing.meta_description"),
    },
    {
      name: "twitter:image",
      content: appConfig.appUrl + defaultImageSocialMediaThumb.src,
    },
    {
      name: "robots",
      content: "index, follow",
    },
    {
      property: "fb:app_id",
      content: appConfig.auth.facebook.appId,
    },
    {
      property: "og:url",
      content: canonicalUrl,
    },
  ]

  // build default meta
  defaultMeta = {
    ...buildMetaFromArray(defaultMeta),
    ...meta,
  }

  // enhance `image` with `image:url` and `image:secure_url`
  const metaOgImageUrl = createMeta(
    "og:image:url",
    defaultMeta[createMetaId("og:image")].content,
    "property"
  )
  const metaOgImageSecureUrl = createMeta(
    "og:image:secure_url",
    defaultMeta[createMetaId("og:image")].content,
    "property"
  )

  defaultMeta[metaOgImageUrl.id] = metaOgImageUrl.attrs
  defaultMeta[metaOgImageSecureUrl.id] = metaOgImageSecureUrl.attrs

  return Object.values(defaultMeta)
}
