import styled, { css, useTheme } from "styled-components"
import useTranslation from "next-translate/useTranslation"
import CrossIcon from "../Icons/CrossIcon"
import { MouseEvent } from "react"

const closesModalStyle = css`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
`

const isDisabledStyle = css`
  pointer-events: none;
  opacity: 0.5;
`

const Button = styled.button<{
  size: string
  closesModal?: boolean
  isDisabled?: boolean
}>`
  width: ${({ size }) => `${Number(size) + 14}px`};
  height: ${({ size }) => `${Number(size) + 14}px`};
  line-height: ${({ size }) => `${Number(size) + 10}px`};
  text-align: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.bg.light03};
  color: ${({ theme }) => theme.color.tint.default};
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; // otherwise squished in iOS Safari

  &:hover {
    background: ${({ theme }) => theme.color.bg.light02};
  }

  & svg {
    min-width: ${({ size }) => `${size}px`};
  }

  ${(props) => props.closesModal && closesModalStyle}
  ${(props) => props.isDisabled && isDisabledStyle}
`

type CloseIconProps = {
  onClick?: (event: MouseEvent) => void
  closesModal?: boolean
  isDisabled?: boolean
  size?: number
  color?: string
}

export default function CloseIcon(props: CloseIconProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const size = String(typeof props.size === "number" ? props.size : 12)

  return (
    <Button
      type="button"
      title={t("core:components.ui.close_icon.title")}
      {...props}
      size={size}
    >
      <CrossIcon
        // alt={t("core:components.ui.close_icon.title")}
        width={size}
        height={size}
        color={props.color ?? theme.color.tint.default}
      />
    </Button>
  )
}
