import styled, { css } from "styled-components"

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    align-items: center;
  }
`

export const isFullscreenStyle = css`
  margin: 0;

  @media screen and ${({ theme }) => theme.breakpoints.device.xs} {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 0;
    flex: 1;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;
  }
`

export const isCustomStyle = css`
  padding: 0;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0;
  }
`

export const Content = styled.div<{ isCustom: boolean; isFullscreen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 50%;
  max-height: 90%;
  max-width: 100%;
  background-color: ${({ theme }) => theme.color.bg.light03};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.modal};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.modal};
  box-shadow: 0 0 1em 0.125em rgba(10, 10, 10, 0.1);
  overflow: hidden;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.modal};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.modal};
    min-height: 0;
  }

  ${(props) => props.isFullscreen && isFullscreenStyle}
  ${(props) => props.isCustom && isCustomStyle}
`

export const InnerContent = styled.div`
  flex: 1;
  overflow: auto;
  color: ${({ theme }) => theme.color.text.primary};
  display: flex;
  flex-direction: column;
  max-width: 100%;
`

export const ModalNavigation = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0 ${({ theme }) => theme.spacing.regular};
`

export const ModalContent = styled.div<{ isCustom: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;

  padding: 0 ${({ theme }) => theme.spacing.regular};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding-left: ${({ theme }) => theme.spacing.double};
    padding-right: ${({ theme }) => theme.spacing.double};
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: calc(
      ${({ theme, isCustom }) => (isCustom ? "0px" : theme.spacing.more)} +
        var(--safe-area-inset-bottom)
    );
  }

  ${(props) => props.isCustom && isCustomStyle}
`
