const HomeActiveIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 20 22"}
    width={20}
    height={22}
    {...props}
  >
    <path
      fill="#f75932"
      fillRule="evenodd"
      stroke="#f75932"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M10 1 1 9.945V21h5.897v-3.442c0-1.696 1.396-3.084 3.103-3.084 1.707 0 3.103 1.388 3.103 3.084V21H19V9.945L10 1Z"
    />
  </svg>
)
export default HomeActiveIcon
