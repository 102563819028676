import { TrackEventParams } from "./track-event"

export interface GoogleAnalyticsEventInterface {
  category: string
  action: string
  label: string
  value: string | number | null
  ga4: GoogleAnalyticsEventGa4Param | null
}

type GA4ParamsRecordType = { [param: string]: string | number }

export interface GoogleAnalyticsEventGa4Param {
  name: string
  params: GA4ParamsRecordType
}

class GoogleAnalyticsEvent implements GoogleAnalyticsEventInterface {
  category: string
  action: string
  label: string
  value: string | number | null
  ga4: GoogleAnalyticsEventGa4Param | null

  constructor(
    category: string,
    action: string,
    label: string,
    value: string | number | null = null,
    ga4: GoogleAnalyticsEventGa4Param | null = null
  ) {
    this.category = category.toLowerCase()
    this.action = action.toLowerCase()
    this.label = label.toLowerCase()
    this.value = value || null
    this.ga4 = ga4 || null
  }

  static createFromAnalyticsEvent(
    eventName: string,
    eventParams: TrackEventParams
  ): GoogleAnalyticsEvent {
    // category, action, label, value = all are deprecated (UA) in favour of GA4
    return new GoogleAnalyticsEvent("", "", "", null, {
      name: eventName,
      params: eventParams.reduce((acc, cur) => {
        const key = cur.key as string
        const value =
          typeof cur.int_value != "undefined" ? cur.int_value : cur.string_value

        if (value !== undefined) {
          acc[key] = value
        }

        return acc
      }, {} as GA4ParamsRecordType),
    })
  }
}

export default GoogleAnalyticsEvent
