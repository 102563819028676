import { useEffectOnce } from "react-use"
import { useFlashMessage } from "./use-flash-message"
import { useCallback } from "react"
import { useLoggerService } from "./use-logger-service"
import useTranslation from "next-translate/useTranslation"

export function useLazyLoaded<T>(
  importFn: () => Promise<{ default: T }>,
  preload = true
) {
  const { t } = useTranslation()
  const { showOopsWithRetry } = useFlashMessage()
  const { logExceptionWithSentry, createLoggingContext } = useLoggerService()

  useEffectOnce(() => {
    if (!preload) {
      return
    }

    // polyfills
    if (typeof window.requestIdleCallback === "undefined") {
      window.requestIdleCallback = (
        callback: IdleRequestCallback,
        options?: { timeout?: number }
      ): number => {
        const start = Date.now()

        return setTimeout(() => {
          const elapsedTime = Date.now() - start
          callback({
            didTimeout: false,
            timeRemaining: () => Math.max(0, 50 - elapsedTime), // Approximates `timeRemaining` to a 50ms frame
          })
        }, options?.timeout || 1) as unknown as number
      }
    }

    if (typeof window.cancelIdleCallback === "undefined") {
      window.cancelIdleCallback = (id: number) => {
        clearTimeout(id)
      }
    }

    window.requestIdleCallback(() =>
      importFn().catch((err) => {
        // oops, preload failed, oh well
        console.log("useLazyLoaded: preload failed", err)
      })
    )
  })

  const getFn = useCallback(
    async (callback: (component: T) => void) => {
      try {
        const importedComponent = await importFn()

        return callback(importedComponent.default)
      } catch (error) {
        void showOopsWithRetry(
          t("core:hooks.use_lazy_loaded.retry_button"),
          () => getFn(callback),
          {
            message: t("core:hooks.use_lazy_loaded.error_message"),
            heading: t("core:hooks.use_lazy_loaded.error_heading"),
          }
        )

        if (error instanceof Error) {
          logExceptionWithSentry(
            error,
            createLoggingContext({
              hook: "useLazyLoaded",
            })
          )
        }
      }
    },
    [
      createLoggingContext,
      importFn,
      logExceptionWithSentry,
      showOopsWithRetry,
      t,
    ]
  )

  return {
    get: getFn,
  }
}
