export function debug(value) {
  if (
    typeof process !== "undefined" &&
    process.env.NEXT_PUBLIC_APP_ENV === "production"
  ) {
    return
  }

  // console.log in NODE
  if (typeof window === "undefined") {
    console.log(value)
    return
  }

  let color = "#3E78CC"
  value = typeof value === "string" ? value : ""

  switch (true) {
    case value.startsWith("GoogleAnalyticsService"): {
      color = "#2ce0ec"
      break
    }
    case value.startsWith("EventTrackerService"): {
      color = "#2cec77"
      break
    }
    case value.startsWith("WebsocketService"): {
      color = "#b8916b"
      break
    }
    case value.startsWith("LocalStorageService"): {
      color = "#de642d"
      break
    }
    case value.startsWith("CommunityApiService"):
    case value.startsWith("ApiService"): {
      color = "#e94119"
      break
    }
    case value.startsWith("use"): {
      color = "#e82da9"
      break
    }
  }

  console.debug(`%c${value}`, `color: ${color}`)
}

export function assert(message, condition) {
  if (process.env.NEXT_PUBLIC_APP_ENV !== "production" && !condition) {
    throw new Error(message)
  }
}
