import { GpsCoords } from "@hornet-web-react/core/types/session"

export function calculateDistanceForGpsCoords(
  currentCoords: GpsCoords | null,
  targetCoords: GpsCoords | null
): number | null {
  if (currentCoords && targetCoords) {
    const distance = getDistanceFromLatLon(
      currentCoords.lat,
      currentCoords.lng,
      targetCoords.lat,
      targetCoords.lng
    )

    return distance
  }

  return null
}

function getDistanceFromLatLon(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  // TODO: maybe make this work, but it's very low priority
  // whether the user moved 5km or 5miles...
  const isMetricUnitOfMeasure = true
  const earthRadius = isMetricUnitOfMeasure ? 6371 : 3959

  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadius * c
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180)
}
