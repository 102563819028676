import useImmutableSWR from "swr"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { useCallback } from "react"
import { RealtimeSessionTotalsApiPayload } from "@hornet-web-react/core/models/realtime-session.model"
import { debug } from "@hornet-web-react/core/utils"
import { SessionTotalsApiPayload } from "@hornet-web-react/core/types/session"

type UseSessionTotalsResult = {
  data: SessionTotalsApiPayload | undefined
  isLoading: boolean
  error: Error | undefined
  increaseUnreadCount: () => void
  decreaseUnreadCount: (decreaseByCount: number) => void
  reduceUnreadCountToZero: () => void
  updateWithRealtimeData: (
    changedTotals: RealtimeSessionTotalsApiPayload
  ) => void
  reloadSessionTotals: () => void
}

export function useSessionTotals(): UseSessionTotalsResult {
  const user = useSessionUser()
  const { data, error, mutate } = useImmutableSWR<SessionTotalsApiPayload>(
    user.isAuthenticated ? "/session/totals" : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  )

  const increaseUnreadCount = useCallback(() => {
    debug("useSessionTotals.increaseUnreadCount")
    if (data) {
      const newData = {
        ...data,
        unread_messages: data.unread_messages + 1,
      }

      void mutate(newData, { revalidate: false })
    }
  }, [mutate, data])

  const decreaseUnreadCount = useCallback(
    (decreaseByCount: number) => {
      debug(`useSessionTotals.decreaseUnreadCount(${decreaseByCount})`)
      if (data) {
        const newCount = data.unread_messages - decreaseByCount
        const newData = {
          ...data,
          unread_messages: newCount >= 0 ? newCount : 0, // check just in case we don't show minus
        }

        void mutate(newData, { revalidate: false })
      }
    },
    [mutate, data]
  )

  const reduceUnreadCountToZero = useCallback(() => {
    debug(`useSessionTotals.reduceUnreadCountToZero()`)
    if (data) {
      const newData = {
        ...data,
        unread_messages: 0,
      }

      void mutate(newData, { revalidate: false })
    }
  }, [mutate, data])

  const updateWithRealtimeData = useCallback(
    (changedTotals: RealtimeSessionTotalsApiPayload) => {
      debug(`useSessionTotals.updateWithRealtimeData()`)
      if (data) {
        const newData = {
          ...data,
          ...changedTotals.data.totals,
        }

        void mutate(newData, { revalidate: false })
      }
    },
    [mutate, data]
  )

  return {
    data,
    isLoading: !error && !data,
    error,
    increaseUnreadCount,
    decreaseUnreadCount,
    reduceUnreadCountToZero,
    updateWithRealtimeData,
    reloadSessionTotals: () => {
      debug(`useSessionTotals.reloadSessionTotals()`)
      void mutate()
    },
  }
}
