import styled, { css, keyframes } from "styled-components"

type SpinnerProps = {
  size?: "default" | "auto"
  isFullscreen?: boolean
  className?: string
}

export default function Spinner({
  size = "default",
  isFullscreen,
  className,
}: SpinnerProps) {
  if (isFullscreen) {
    return (
      <FullscreenWrapper>
        <Wrapper className={className} size={size} />
      </FullscreenWrapper>
    )
  }

  return <Wrapper className={className} size={size} />
}

const defaultSizeStyle = css`
  font-size: 40px;
  width: 3em;
  height: 3em;
  padding: 0.5em;

  &:after {
    width: 1.8em;
    height: 1.8em;
  }
`

const autoSizeStyle = css`
  font-size: inherit;
  width: 1em;
  height: 1em;
  padding: 0;

  &:after {
    width: 1em;
    height: 1em;
  }
`

const animationRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div<SpinnerProps>`
  display: block;
  position: relative;
  margin: 0 auto;

  ${(props) => props.size === "auto" && autoSizeStyle}
  ${(props) => props.size === "default" && defaultSizeStyle}
  &:after {
    content: " ";
    display: block;
    margin: 0;
    border-radius: 50%;
    border: 0.1em solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: ${animationRing} 1.2s linear infinite;
  }
`

const FullscreenWrapper = styled.div`
  color: ${({ theme }) => theme.color.text.primary};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
