const ChatIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 18 20"}
    width={18}
    height={20}
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.color || "#5A5A5A"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.728}
    >
      <path d="M4.123 3.422c0-1.264 1.014-2.289 2.265-2.289h8.338c1.251 0 2.265 1.025 2.265 2.289V9.25c0 1.264-1.014 2.289-2.265 2.289h0" />
      <path d="M11.608 4.295H3.27c-1.25 0-2.265 1.025-2.265 2.29v5.827c0 1.264 1.014 2.289 2.265 2.289h.823v4.162L8.468 14.7h3.14c1.251 0 2.265-1.025 2.265-2.29V6.586c0-1.265-1.014-2.29-2.265-2.29Z" />
    </g>
  </svg>
)
export default ChatIcon
