const DiscoverActiveIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 24 24"}
    width={"22"}
    height={"22"}
    {...props}
  >
    <path
      fill="#F75932"
      fillRule="evenodd"
      stroke="#F75932"
      strokeWidth={0.2}
      d="m22.596 20.76-6.023-6.026a8.526 8.526 0 0 0 1.65-5.08A8.692 8.692 0 0 0 9.581 1 8.542 8.542 0 0 0 1 9.593a8.693 8.693 0 0 0 8.642 8.655 8.5 8.5 0 0 0 5.094-1.668l.006-.005 6.018 6.021a1.299 1.299 0 1 0 1.835-1.837Zm-12.96-4.237a6.954 6.954 0 0 1-6.914-6.924 6.836 6.836 0 0 1 6.864-6.874 6.954 6.954 0 0 1 6.915 6.923 6.836 6.836 0 0 1-6.864 6.875Z"
    />
  </svg>
)
export default DiscoverActiveIcon
