import { isClient } from "./run-on-client-only"

export default function getCurrentAppUrl(
  urlProtocol: string,
  fallbackUrl: string
): string {
  if (isClient) {
    return `${urlProtocol}://${window.location.host}`
  }

  return fallbackUrl
}
