export const WEBVIEW_QUERY_PARAMS = {
  APP_VERSION: "av", // when opening in WebView, native app must supply its version
  LANGUAGE: "lang", // when opening in WebView, native must supply its language
  SSO_TOKEN: "otp", // when opening in WebView, native app can supply SSO token
  // IS_IN_APP: 'inapp', // DEPRECATED
  PROFILE_ID: "pid", // when opening in WebView, native app must supply logged in profile ID
}

export function detectWebViewFromQueryParams(queryParams) {
  // different way of searching in URLSearchParams
  if (queryParams instanceof URLSearchParams) {
    return Object.values(WEBVIEW_QUERY_PARAMS).reduce(
      (isAnyKeyPresent, webviewKey) => {
        return isAnyKeyPresent || queryParams.has(webviewKey)
      },
      false
    )
  }

  // any of these keys indicates webview
  const predicate = (v) =>
    Object.values(WEBVIEW_QUERY_PARAMS).includes(v) && queryParams[v] !== null

  return Object.keys(queryParams).filter(predicate).length > 0
}
