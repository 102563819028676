import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import styled, { css } from "styled-components"
import Portal from "../Layout/Portal"
import CloseIcon from "../UI/CloseIcon"

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(51, 51, 51, 0.3);
  backdrop-filter: blur(1px);
  opacity: 0;
  transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 200ms;
  display: flex;
  align-items: center;
  justify-content: center;

  & .modal-content {
    transform: translateY(100px);
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  &.active {
    transition-duration: 250ms;
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: 150ms;
      transition-duration: 350ms;
    }
  }
`

const isFullscreenStyle = css`
  margin: 0;

  @media screen and ${({ theme }) => theme.breakpoints.device.xs} {
    border: 0;
    flex: 1;
    width: 100%;
    max-width: none;
    height: 100%;
    max-height: none;

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      --safe-area-inset-bottom: env(safe-area-inset-bottom);
      padding-bottom: calc(
        ${({ theme }) => theme.spacing.more} + var(--safe-area-inset-bottom)
      );
    }
  }
`

const isCustomStyle = css`
  padding: 0;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0;
  }
`

const Content = styled.div<{
  hasCloseButton: boolean
  isFullscreen: boolean
  isCustom: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 50px;
  min-width: 50px;
  margin: ${({ theme }) => theme.spacing.regular};
  padding: ${(props) =>
      props.hasCloseButton ? "40px" : props.theme.spacing.more}
    0 ${({ theme }) => theme.spacing.more};
  max-height: calc(100vh - 160px);
  max-width: calc(100vw - 40px);
  background-color: ${({ theme }) => theme.color.bg.light01};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  //border: 2px solid ${({ theme }) => theme.color.tint.default};
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    max-width: 600px;
    min-width: 500px;
  }

  ${(props) => props.isFullscreen && isFullscreenStyle}
  ${(props) => props.isCustom && isCustomStyle}
`

const InnerContent = styled.div<{ isCustom: boolean }>`
  flex: 1;
  overflow: auto;
  color: ${({ theme }) => theme.color.text.primary};
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing.regular};
  max-width: 100%;
  overflow-x: hidden;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0 ${({ theme }) => theme.spacing.double};
  }

  ${(props) => props.isCustom && isCustomStyle}
`

const ModalActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: ${({ theme }) => theme.spacing.regular}
    ${({ theme }) => theme.spacing.regular} 0;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    flex-direction: row;
  }

  button {
    margin-top: ${({ theme }) => theme.spacing.less};
  }

  button + button {
    margin-left: ${({ theme }) => theme.spacing.less};
  }
`

const ModalTitle = styled.h2`
  ${({ theme }) => theme.font.bold.title2}
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.half};
`

type ModalProps = PropsWithChildren & {
  open: boolean
  onClose: () => void
  title?: string
  actions?: ReactNode
  locked?: boolean
  isCustom?: boolean
  isFullscreen?: boolean
  hasCloseButton?: boolean
  className?: string
}

export default function Modal(props: ModalProps) {
  const [active, setActive] = useState(false)
  const { open, onClose, locked, isCustom, isFullscreen } = props
  const hasCloseButton =
    typeof props.hasCloseButton === "boolean" ? props.hasCloseButton : true
  const backdrop = useRef<HTMLDivElement | null>(null)

  // on mount
  useEffect(() => {
    // get dom element from backdrop
    const { current } = backdrop
    // when transition ends set active state to match open prop
    const transitionEnd = () => setActive(open)
    // when esc key press close modal unless locked
    const keyHandler = (e: KeyboardEvent) =>
      !locked && [27].indexOf(e.which) >= 0 && onClose()
    // when clicking the backdrop close modal unless locked
    const clickHandler = (e: MouseEvent) =>
      !locked && e.target === current && onClose()

    // if the backdrop exists set up listeners
    if (current) {
      current.addEventListener("transitionend", transitionEnd)
      current.addEventListener("click", clickHandler)
      window.addEventListener("keyup", keyHandler)
    }

    // if open props is true add inert to #__next
    // and set active state to true
    if (open && !active) {
      window.setTimeout(() => {
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur()
        }

        setActive(open)
        document.querySelector("#__next")?.setAttribute("inert", "true")
      }, 10)
    }

    // on unmount remove listeners
    return () => {
      if (current) {
        current.removeEventListener("transitionend", transitionEnd)
        current.removeEventListener("click", clickHandler)
      }

      document.querySelector("#__next")?.removeAttribute("inert")
      window.removeEventListener("keyup", keyHandler)
    }
  }, [open, active, locked, onClose])

  if (open || active) {
    return (
      <Portal className="modal-portal">
        <Backdrop ref={backdrop} className={(active && open && "active") || ""}>
          <Content
            isFullscreen={Boolean(isFullscreen)}
            isCustom={Boolean(isCustom)}
            hasCloseButton={hasCloseButton}
            className={`${props.className} modal-content`}
          >
            {hasCloseButton && (
              <CloseIcon onClick={onClose} closesModal={true} />
            )}

            <InnerContent isCustom={Boolean(isCustom)}>
              {props.title && <ModalTitle>{props.title}</ModalTitle>}
              {props.children}
            </InnerContent>

            {props.actions && <ModalActions>{props.actions}</ModalActions>}
          </Content>
        </Backdrop>
      </Portal>
    )
  }

  return null
}
