import { Component, ErrorInfo, ReactNode } from "react"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { ServicesContext } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"

type ErrorBoundaryProps = {
  children: ReactNode
  errorElement: ReactNode
  loggerService?: LoggerService
}
type ErrorBoundaryState = {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static override contextType = ServicesContext
  declare context: React.ContextType<typeof ServicesContext>

  override state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!this.context.container) {
      return
    }

    const loggerService = this.context.container.container[
      CORE_TYPES.LoggerService
    ] as LoggerService

    // You can also log the error to an error reporting service
    if (loggerService) {
      loggerService.logExceptionWithSentry(
        error,
        loggerService.createLoggingContext({
          component: "ErrorBoundary",
          error_info: errorInfo,
        })
      )
    }
  }

  override render() {
    if (this.state.hasError) {
      return this.props.errorElement
    }

    return this.props.children
  }
}

export default ErrorBoundary
