import { z } from "zod"

export function getCorrectValue<T extends z.ZodTypeAny>(
  value: unknown,
  schema: T,
  fallback?: unknown
): z.infer<T> | undefined {
  const result = schema.safeParse(value)

  return result.success ? result.data : fallback
}
