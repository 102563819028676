import styled from "styled-components"
import { BugAntIcon } from "@heroicons/react/20/solid"
import NiceModal from "@ebay/nice-modal-react"
import DebugViewModal from "./DebugViewModal"

const FloatingButton = styled.button`
  position: fixed;
  z-index: 1000;
  bottom: 25px;
  right: 5px;
  background: ${({ theme }) => theme.color.tint.default};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  color: #fff;
  border: 0;
  padding: 10px;
`

const DebugViewButton = () => {
  return (
    <FloatingButton
      onClick={() => {
        void NiceModal.show(DebugViewModal)
      }}
    >
      <BugAntIcon />
    </FloatingButton>
  )
}

export default DebugViewButton
