export interface CustomApiErrorInterface {
  status: number
  message: string
}

export default class CustomApiError
  extends Error
  implements CustomApiErrorInterface
{
  status = 0
}
