const ChatActiveIcon = (props: JSX.IntrinsicElements["svg"]) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox={"0 0 22 24"}
    width={20}
    height={22}
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={props.color || "#F75932"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.728}
        d="M18.116 13.187h-9.53c-1.43 0-2.588-1.171-2.588-2.616v-6.66c0-1.445 1.158-2.616 2.588-2.616h9.53c1.43 0 2.587 1.171 2.587 2.616v6.66c0 1.445-1.158 2.616-2.587 2.616Z"
      />
      <path
        fill="#FFF"
        d="M13.41 6.052H3.88c-1.43 0-2.588 1.171-2.588 2.616v6.66c0 1.444 1.159 2.616 2.589 2.616h.94V22.7l5-4.757h3.588c1.43 0 2.589-1.172 2.589-2.616v-6.66c0-1.445-1.159-2.616-2.589-2.616"
      />
      <path
        fill={props.color || "#F75932"}
        stroke={props.color || "#F75932"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.728}
        d="M13.41 6.052H3.88c-1.43 0-2.588 1.171-2.588 2.616v6.66c0 1.444 1.159 2.616 2.589 2.616h.94V22.7l5-4.757h3.588c1.43 0 2.589-1.172 2.589-2.616v-6.66c0-1.445-1.159-2.616-2.589-2.616Z"
      />
    </g>
  </svg>
)
export default ChatActiveIcon
