import { isClient } from "./run-on-client-only"

export const retrieveLocalStorageAnalytics = () => {
  // just in case, this shouldn't be called on the server
  if (!isClient) {
    return {
      utmSource: "",
      utmCampaign: "",
      utmMedium: "",
      utmTerm: "",
      utmKeyword: "",
      isUtmEmpty: true,
      referralId: "",
    }
  }

  // retrieve utm params from the URL
  const queryParams = new URLSearchParams(window.location.search)
  const utmSource = queryParams.get("utm_source") || ""
  const utmMedium = queryParams.get("utm_medium") || ""
  const utmCampaign = queryParams.get("utm_campaign") || ""
  const utmTerm = queryParams.get("utm_term") || ""
  const utmKeyword = queryParams.get("utm_keyword") || ""

  const referralId =
    (queryParams.get("redirect") || "").match(/^\/s\/(\w+)/)?.[1] || ""

  // also, some special logic
  //  * For incoming TikTok links: if `ttclid` is present in the url
  //    parameters: set `utm_source` to "tiktok_ttclid" and set `utm_campaign`
  //    to the value of "ad_id"
  const ttclid = queryParams.get("ttclid")
  if (ttclid) {
    return {
      utmSource: "tiktok_ttclid",
      utmCampaign: "ad_id",
      utmMedium: "",
      utmTerm: "",
      utmKeyword: "",
      isUtmEmpty: false,
      referralId,
    }
  }

  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmKeyword,
    isUtmEmpty:
      !utmSource && !utmMedium && !utmCampaign && !utmTerm && !utmKeyword,
    referralId,
  }
}
