import * as React from "react"
import { FC } from "react"
import { useTheme } from "styled-components"

type IconProps = {
  color?: string
}

const DropdownArrowIcon: FC<IconProps> = (props) => {
  const theme = useTheme()

  return (
    <svg
      width={19}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={18} height={18} rx={9} fill="#DADADA" />
      <path
        d="M6.45 7 9 9.475 11.55 7l.783.765L9 11 5.667 7.765 6.45 7Z"
        fill={props.color || theme.color.tint.default}
      />
    </svg>
  )
}

export default DropdownArrowIcon
