import { FC, ReactNode, useEffect, useRef, useState } from "react"
import Portal from "../Layout/Portal"
import { type NavigationAction } from "@hornet-web-react/core/types"
import { MobileNavigation, useMobileNavigation } from "../Layout"
import {
  Backdrop,
  Content,
  InnerContent,
  ModalNavigation,
  ModalContent,
} from "./SlideModal.styles"

type SlideModalProps = {
  isOpen: boolean
  children: ReactNode
  closeAction: NavigationAction
  leftAction?: NavigationAction
  rightAction?: NavigationAction
  hasNavigation?: boolean
  isLocked?: boolean
  isCustom?: boolean
  isFullscreen?: boolean
  title?: string
  className?: string
  afterClose?: () => void
}

const SlideModal: FC<SlideModalProps> = ({
  isOpen,
  children,
  closeAction,
  leftAction,
  rightAction,
  title,
  className,
  afterClose,
  hasNavigation = true,
  isLocked = false,
  isCustom = false,
  isFullscreen = false,
}) => {
  const [isActive, setIsActive] = useState(false)
  const [scrollbarWidth, setScrollbarWidth] = useState(0)
  const backdrop = useRef<HTMLDivElement>(null)
  const { createNavActionButton } = useMobileNavigation()

  // on mount
  useEffect(() => {
    // get dom element from backdrop
    const { current } = backdrop

    // when transition ends set active state to match open prop
    const transitionEnd = () => {
      setIsActive(isOpen)

      if (!isOpen) {
        if (typeof afterClose === "function") afterClose()
      }
    }

    // when esc key press close modal unless locked
    // HACK: turn this off because when there is Lightbox used inside
    //       that modal, it closes before the lightbox and thus the LB
    //       cleanup doesn't happen and there is still the `inert` attribute
    //       "true" somehow after that
    //  SOLUTION: maybe can be solved with a context, that has a flag
    //            that Lightbox is open and in that case the escape should
    //            be ignored
    const keyHandler = (e: KeyboardEvent) => !isLocked && e.key === "Escape" // && closeAction.onClick()

    // when clicking the backdrop close modal unless locked
    const clickHandler = (e: Event) =>
      !isLocked && e.target === current && closeAction.onClick()

    // if the backdrop exists set up listeners
    if (current) {
      current.addEventListener("transitionend", transitionEnd)
      current.addEventListener("click", clickHandler)
      window.addEventListener("keyup", keyHandler)
    }

    // if open props is true add inert to #__next
    // and set active state to true
    let activatingModalTimeout: number | undefined
    if (isOpen) {
      activatingModalTimeout = window.setTimeout(() => {
        // apparently does not exist, maybe can remove it then?
        // document.activeElement?.blur()
        setIsActive(isOpen)
        document.querySelector("#__next")?.setAttribute("inert", "true")
      }, 10)
    }

    if (!isOpen && !isActive) {
      setScrollbarWidth(
        window.innerWidth - document.documentElement.clientWidth
      )
    }

    // on unmount remove listeners
    return () => {
      if (typeof activatingModalTimeout === "number") {
        clearTimeout(activatingModalTimeout)
      }

      if (current) {
        current.removeEventListener("transitionend", transitionEnd)
        current.removeEventListener("click", clickHandler)
      }

      document.querySelector("#__next")?.removeAttribute("inert")
      window.removeEventListener("keyup", keyHandler)
    }
  }, [isOpen, isLocked, closeAction, isActive, afterClose])

  if (!isOpen && !isActive) {
    return null
  }

  return (
    <Portal>
      <style jsx global>{`
        body {
          overflow: ${isOpen ? "hidden" : "inherit"};
          padding-right: ${isOpen ? scrollbarWidth : 0}px;
        }
      `}</style>

      <Backdrop
        ref={backdrop}
        className={(isActive && isOpen && "active") || undefined}
      >
        <Content
          isFullscreen={isFullscreen}
          isCustom={isCustom}
          className={`${className} modal-content`}
        >
          <InnerContent>
            {hasNavigation && (
              <ModalNavigation>
                <MobileNavigation
                  title={title}
                  leftAction={leftAction && createNavActionButton(leftAction)}
                  rightAction={createNavActionButton(
                    rightAction || closeAction,
                    "right"
                  )}
                />
              </ModalNavigation>
            )}
            <ModalContent isCustom={isCustom}>{children}</ModalContent>
          </InnerContent>
        </Content>
      </Backdrop>
    </Portal>
  )
}

export default SlideModal
